export default function TextHeader({className, children}) {
  return (
    <div className={`
      p-3 sm:p-4 md:p-5
      bg-white bg-opacity-40
      rounded-lg
    ` + className}>
      <h1 className="text-center text-primary font-bold">{children}</h1>
    </div>
  )
}