import {localizedMoment} from "../../utils/dates";
import {useTranslation} from "react-i18next";

export default function Recap ({task, users, address, start, name}) {
  const {t, i18n} = useTranslation(['pages', 'fields'])

  return <dl>
    <dt>{t('appointment.task')}</dt>
    <dd>{task}</dd>
    <dt>{t('practitioners.practitioners')}</dt>
    <dd>{users}</dd>
    <dt>{t('fields:address')}</dt>
    <dd>{address}</dd>
    <dt>{t('appointment.date')}</dt>
    <dd className='capitalize'>{localizedMoment(start, i18n.language).format("LLLL")}</dd>
    {name && <>
      <dt>{t('fields:name')}</dt>
      <dd>{name}</dd>
    </>}
  </dl>
}