const resources = {
  user: 'users',
  address: 'addresses',
  order: 'orders',
  order_status: 'order-statuses',
  order_actions: {
    pay_admin: 'pay-admin',
    ready_send: 'ready-send',
    send: 'send',
    ready_pick_up: 'ready-pick-up',
    ready_picked_up: 'ready-picked-up',
    send_back_received: 'send-back-received',
  },
  my_order: 'my-orders',
  task_categories: 'task-categories',
  tasks: 'tasks',
  practitioners: 'practitioners',
  days_off: 'off-days',
  working_hours: 'working-hours',
  schedules: 'schedules',
  schedules_public: 'schedules-public',
  occuped_slots: 'occuped-slots',
  contents: 'contents',
  roles: 'roles',
  promo_contexts: 'promo-contexts',
  promo_types: 'promo-types',
  promo: 'promos',
  article_categories: 'article-categories',
  articles: 'articles',
  article_types: 'article-types',
  article_pictures: 'article-pictures',
  article_promos: 'article-promos',
  carts: 'carts',
  events: 'events',
  articles_sku: 'article-skus',
  exercices: 'exercices',
  questions: 'questions',
  answers: 'answers'
}

export default resources;