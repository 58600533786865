import {useEffect, useState} from "react";
import {getContents} from "../../../../../api/contents";
import BackOffice from "../../../../../components/common/Layout/BackOffice";
import ContentForm from "../../../../../forms/ContentForm";
import {useToast} from "../../../../../providers/ToastProvider";

export default function SimpleContentPage({title, id, withTitle}) {
  const [content, setContent] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const toast = useToast()

  useEffect(() => {
    getContents('contents', id)
      .then(setContent)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
    //eslint-disable-next-line
  }, [id])

  return <BackOffice role="editor" isLoading={isLoading}>
    <h1 className="mb-4">{title}</h1>
    <ContentForm
      content={content}
      withTitle={withTitle}
    />
  </BackOffice>
}