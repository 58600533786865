import api from "./api";
import {lang} from "../languages";
import resources from "./resources";
import {authUser} from "./user";

export function index() {
  return api().get(`/${lang()}/${resources.events}`)
}

export function adminIndex(year) {
  return api().get(`/${lang()}/admin/${resources.events}?year=${year}`)
}

export function show(id) {
  return api().get(`/${lang()}/${resources.events}/${id}`)
}

export function subscribe(id, paymentMethod) {
  return api().post(`/${lang()}/${resources.events}/${id}/subscribe`, {
    payment_method: paymentMethod
  })
}

export function store(data) {
  return api().post(`/${lang()}/${resources.events}`, data)
}

export function update(id, data) {
  return api().put(`/${lang()}/${resources.events}/${id}`, data)
}

export function destroy(id) {
  return api().delete(`/${lang()}/${resources.events}/${id}`)
}

export function removeUser(eventId, userId) {
  return api().delete(`/${lang()}/${resources.events}/${eventId}/${resources.user}/${userId}`)
}

export function changeStatus(eventId, userId, status) {
  return api().put(`/${lang()}/${resources.events}/${eventId}/${resources.user}/${userId}`, {new_status: status})
}

export function reimbourseUser(eventId, userId) {
  return api().delete(`/${lang()}/${resources.events}/${eventId}/${resources.user}/${userId}/reimbourse-user`)
}

export function getPaymentToken(eventId, userId) {
  return api().post(`/${lang()}/${resources.events}/${eventId}/${resources.user}/${userId}/get-payment-token`)
}

export function myEvents() {
  return authUser().then(user => api().get(`/${lang()}/${resources.user}/${user.id}/my-events`))
}

export function search(query) {
  return api().get(`/${lang()}/${resources.events}/search?q=${query}`)
}