import React from "react";
import { useField } from "formik";
import DatePicker, {registerLocale} from "react-datepicker";
import {frCH, de, enGB} from "date-fns/esm/locale"
import Label from "./Label";
import {useTranslation} from "react-i18next";
import Info from "./Info";

registerLocale("fr", frCH)
registerLocale("de", de)
registerLocale("en", enGB)

export const DatePickerField = ({id, label, className, info, required, async, error, ...props }) => {
  const [field, meta, { setValue }] = useField(props);
  const {i18n} = useTranslation()
  // const [success, setSuccess] = useState(false)

  const changedValue = () => meta.value !== meta.initialValue

  const handleBlur = e => {
    field.onBlur(e)

    if (async) {
      // The latest touched and errors aren't available until after the
      // formik's onBlur handler has been called. Setting a timeout allows us
      // to access the proper touched and errors after onBlur event has
      // occurred.
      setTimeout(() => {
        if (changedValue()) {
          async({[props.name]: meta.value})
            // .then(() => setSuccess(true))
        }
      }, 0);
    }
  };

  const handleOnChange = val => {
    setValue(val)
    if (async) {
      // The latest touched and errors aren't available until after the
      // formik's onBlur handler has been called. Setting a timeout allows us
      // to access the proper touched and errors after onBlur event has
      // occurred.
      setTimeout(() => {
        async({[props.name]: val})
        // .then(() => setSuccess(true))
      }, 0);
    }
  }

  return (
    <div className={`
      w-full
      relative
      ${className || ''} 
    `}>
      <Label id={id} required={required} error={(!async || changedValue()) && error}>{label}</Label>
      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={handleOnChange}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        locale={i18n.language}

        id={id}
        required={required}
        onBlur={handleBlur}
        // onFocus={() => setSuccess(false)}
      />
      <Info info={info} error={(!async || changedValue()) && error}/>
    </div>
  )
}