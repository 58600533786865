import BackOffice from "../../../components/common/Layout/BackOffice";
import * as UserAPI from "../../../api/user";
import {useEffect, useState} from "react";
import OrderSection from "../../../components/dashboard/sections/OrderSection";
import CalendarSection from "../../../components/dashboard/sections/CalendarSection";
import CalendarAdminSection from "../../../components/dashboard/sections/CalendarAdminSection";
import EditorSection from "../../../components/dashboard/sections/EditorSection";
import AdminSection from "../../../components/dashboard/sections/AdminSection";
import {useTranslation} from "react-i18next";
import {useToast} from "../../../providers/ToastProvider";

export default function Dashboard() {
  const [user, setUser] = useState({})
  const {t} = useTranslation('routes')
  const toast = useToast()

  useEffect(() => {
    UserAPI.authUser()
      .then(setUser)
      .catch(() => toast.pushUnKnown())
    //eslint-disable-next-line
  }, [])

  return <BackOffice role="any">
    <h1 className="mb-10">{t('dashboard.name')}</h1>
    <div className="flex flex-col space-y-8 divide-y divide-secondary">
      {user?.roles?.includes('order') && <OrderSection/>}
      {user?.roles?.includes('calendar') && <CalendarSection/>}
      {user?.roles?.includes('calendar-admin') && <CalendarAdminSection/>}
      {user?.roles?.includes('editor') && <EditorSection/>}
      {user?.roles?.includes('admin') && <AdminSection/>}
    </div>
  </BackOffice>
}