import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import * as UserAPI from "../api/user";
import Spinner from "../components/common/Spinner";

const defaultValues = {
  old_password: '',
  new_password: ''
}

export default function ChangePasswordForm(props) {
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [validated, setValidated] = useState(false)
  const {t} = useTranslation(['fields', 'errors', 'forms'])

  const handleSubmit = (data, {resetForm}) => {
    setShowGlobalError(false)
    setValidated(false)
    setErrors({})
    return UserAPI.changePassword(data)
      .then(() => {
        setValidated(true)
        resetForm(defaultValues)
      })
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  return <Formik
    initialValues={defaultValues}
    onSubmit={handleSubmit}
  >
    {({ isSubmitting }) => (
      <Form {...props}>
        <h2 className="mb-6">{t('pages:my-account.change-password')}</h2>
        {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
        {validated && <div className="text-green-500 text-sm">{t('pages:my-account.password-changed')}</div> }
        <div className="flex flex-col space-y-2 mt-2 mx-4">
          <Input name="old_password" type="password" label={t('password')} placeholder={t('password')} error={errors.old_password} required/>
          <Input name="new_password" type="password" label={t('new_password')} placeholder={t('new_password')} error={errors.new_password} required/>
          <PrimaryBtn className="mt-4 self-start flex flex-row" disabled={isSubmitting}>{isSubmitting && <Spinner className="h-5 w-5 mr-3"/>}{t('forms:save')}</PrimaryBtn>
        </div>
      </Form>
    )}
  </Formik>
}