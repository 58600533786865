import {useAppointment} from "../../providers/AppointmentProvider";
import ListWithClick from "./ListWithClick";

export default function ChoosePractitioner ({nextStep}) {
  const appointment = useAppointment()

  const choosePractitioner = practitioner => {
    appointment.setPractitioner(practitioner)
  }

  return <ListWithClick
    onClick={choosePractitioner}
    selected={appointment.practitioner}
    items={appointment.practitioners}
    nextStep={nextStep}
  />
}