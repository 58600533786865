import {useEffect, useState} from "react";
import BackOffice from "../../../../components/common/Layout/BackOffice";
import {useTranslation} from "react-i18next";
import TabsWrapper from "../../../../components/common/tabs/TabsWrapper";
import {Tab} from "@headlessui/react";
import PromoCheckForm from "../../../../forms/PromoCheckForm";

export default function PromoCheck() {

  const [isLoading, setIsLoading] = useState(false)
  const {t} = useTranslation(['routes', 'pages'])

  const update = () => {
    setTimeout(() => setIsLoading(false), 1000)
  }
  useEffect(update, [])

  return <BackOffice role="calendar" isLoading={isLoading}>
    <h1 className="mb-4">{t('promo-code-check.name')}</h1>
    <div>
      <TabsWrapper titles={[t('pages:promo-code-check.use'), t('pages:promo-code-check.check')]}>
        <Tab.Panel className="focus:outline-none"><PromoCheckForm useForm/> </Tab.Panel>
        <Tab.Panel className="focus:outline-none"><PromoCheckForm /></Tab.Panel>
      </TabsWrapper>
    </div>
  </BackOffice>
}