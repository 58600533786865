import TemplateModal from "./TemplateModal";
import TransparentBtn from "../btns/TransparentBtn";
import {XCircleIcon} from "@heroicons/react/outline";

export default function Modal({title, children, closeModal, ...props}) {
  return <TemplateModal closeModal={closeModal} {...props}>
    <div className="absolute top-2 right-2">
      <TransparentBtn className="flex flex-row justify-end text-red-600" onClick={closeModal}><XCircleIcon className="h-5 w-5"/></TransparentBtn>
    </div>
    <h3 className="text-lg font-medium leading-6">
      {title}
    </h3>
    {children}
  </TemplateModal>
}