import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as WorkingHourAPI from "../api/workinHours";
import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import FormSelect from "../components/common/inputs/FormSelect";
import * as AddressAPI from "../api/addresses";
import Spinner from "../components/common/Spinner";

export default function WorkingHourForm({workingHour, isCreating, day, closeModal}) {
  const defaultValues = {
    start: "",
    end: "",
    address_id: "",
  }
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [addresses, setAddresses] = useState([])
  const {t} = useTranslation(['fields', 'forms'])

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
  }

  const create = (data, {resetForm}) => {
    reset()
    return WorkingHourAPI.store({...data, day})
      .then(() => {
        resetForm(defaultValues)
        closeModal()
      })
      .catch(handleError)
  }

  const update = data => {
    reset()
    return WorkingHourAPI.update(workingHour.id, data)
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  useEffect(() => {
    AddressAPI.index().then(setAddresses)
  }, [])

  return <Formik
    initialValues={workingHour || defaultValues}
    enableReinitialize
    onSubmit={create}
  >
    {({isSubmitting}) => (
    <Form>
      {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
      <div className="flex flex-col space-y-4">
        <Input
          type="time"
          name="start"
          label={t('start')}
          placeholder={t('start')}
          async={!isCreating ? update : false}
          error={errors.start}
          required
        />
        <Input
          type="time"
          name="end"
          label={t('end')}
          placeholder={t('end')}
          async={!isCreating ? update : false}
          error={errors.end}
          required
        />
        <FormSelect
          name="address_id"
          label={t('address')}
          placeholder={t('address')}
          async={!isCreating ? update : false}
          error={errors.address_id}
          required
        >
          <option>---</option>
          {addresses.map(address => <option key={address.id} value={address.id}>{address.street}, {address.city}</option>)}
        </FormSelect>

        <div className="sm:col-start-2 sm:text-center">
          {isCreating && <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : t('forms:save')}</PrimaryBtn>}
        </div>
      </div>
    </Form>
    )}
  </Formik>
}