import BackOffice from "../../../../components/common/Layout/BackOffice";
import FCalendar from "../../../../components/calendar/FCalendar";
import {useEffect, useState} from "react";
import * as workingHoursAPI from "../../../../api/workinHours";
import * as schedulesAPI from "../../../../api/schedules";
import * as offDaysAPI from "../../../../api/daysOff";
import Select from "../../../../components/common/inputs/Select";
import {useTranslation} from "react-i18next";
import {myPratitioners} from "../../../../api/practitioners";
import {useToast} from "../../../../providers/ToastProvider";

export default function Calendar(){
  const [businessHours, setBusinessHours] = useState([])
  const [practitioners, setPractitioners] = useState([])
  const [practitioner, setPractitioner] = useState(null)
  const {t} = useTranslation('pages')
  const toast = useToast()

  const getEvents = async ({start, end}, success, fail) => {
    const schedules = await schedulesAPI.index(start, end)
      .catch(fail)
    const offDays = await offDaysAPI.indexBetween(start, end)
      .then(off_days => off_days.map(day => ({
        id: day.id,
        start: day.start,
        end: day.end,
        title: day.description,
        color: 'green',
        display: 'background'
      })))
      .catch(fail)
    let occupedSlots = []
    if (practitioner) {
      await schedulesAPI.occupedSlots(start, end, practitioner)
        .then(slots => slots.map(slot => ({
          ...slot,
          color: 'red'
        })))
        .then(slots => occupedSlots = slots)
        .catch(fail)
    }

    let events = await schedulesAPI.calendarEvents(start, end)
      .then(data => data.map(event => ({
        ...event,
        color: 'gray'
      })))
      .catch(fail)
    success([...schedules, ...offDays, ...occupedSlots, ...events])
  }

  useEffect(() => {
    workingHoursAPI.index()
      .then(workingHours => workingHours.map(hour => ({
        daysOfWeek: [hour.day],
        startTime: hour.start,
        endTime: hour.end
      })))
      .then(setBusinessHours)
      .catch(() => toast.pushUnKnown())

    myPratitioners()
      .then(setPractitioners)
      .catch(() => toast.pushUnKnown())
    //eslint-disable-next-line
  }, [])
  return <BackOffice role="calendar">
    <div className="min-h-screen flex flex-col">
      <div className="my-4 flex flex-row space-x-4">
        <div className="flex flex-col justify-center">
          <p>{t('calendar.disponibilities')}</p>
        </div>
        {/*todo: voir pour pas que ça sorte de l'écran*/}
        <Select
          defaultValue={{id: null, name: "---"}}
          items={[
            {id: null, name: "---"},
            ...practitioners.map(practitioner => ({id: practitioner.id, name: practitioner.name}))
          ]}
          onChange={practitioner => {
            setPractitioner(practitioner.id)
          }}
        />
      </div>
      <div className="my-4">
        <p>{t("calendar.legends.title")}</p>
        <div className="ml-4 mt-2">
          <div className="flex space-x-2"><div className="w-5 h-5 bg-red-300"/><p>{t("calendar.legends.unavailable")}</p></div>
          <div className="flex space-x-2"><div className="w-5 h-5 bg-green-300"/><p>{t("calendar.legends.off")}</p></div>
          <div className="flex space-x-2"><div className="w-5 h-5 bg-gray-400"/><p>{t("calendar.legends.events")}</p></div>
        </div>
      </div>
      <div className="flex-grow">
        <FCalendar businessHours={businessHours} getEvents={getEvents}/>
      </div>
    </div>
  </BackOffice>
}