import Label from "./Label";
import Info from "./Info";
import {useField} from "formik";
import {CheckIcon, XIcon} from "@heroicons/react/outline";

export default function Switch({id, label, className, info, required, async, error, ...props}) {
  const [field, meta, {setValue}] = useField({...props, type: 'checkbox'})

  const changedValue = () => meta.value !== meta.initialValue

  const handleBlur = e => {
    if (async) {
      field.onBlur(e)
      // The latest touched and errors aren't available until after the
      // formik's onBlur handler has been called. Setting a timeout allows us
      // to access the proper touched and errors after onBlur event has
      // occurred.
      setTimeout(() => {
          async({[props.name]: field.checked})
      }, 0);
    }
  };

  const handleClick = () => {
    const new_val = !field.checked
    setValue(new_val)
    if (!!async) async({[props.name]: new_val})
  }

  return <div className="h-full">
    <Label id={id} required={required} error={(!async || changedValue()) && error}>{label}</Label>
    <input
      className="hidden"
      type="checkbox"
      id={id}
      {...field}
      {...props}
      onBlur={handleBlur}
    />
    <div className="flex flex-col mt-4">
      <div className="relative w-14 inline-block mr-2 align-middle select-none transition duration-500 ease-in" onClick={handleClick}>
        <div className={`absolute block w-8 h-8 p-1 rounded-full bg-white border-4 appearance-none cursor-pointer ${field.checked ? 'right-0' : 'left-0'}`}>
          {field.checked ? <CheckIcon/> : <XIcon/>}
        </div>
        <div
          className={`overflow-hidden h-8 rounded-full appearance-none cursor-pointer ${field.checked ? 'bg-green-500': 'bg-red-500'}`}
        />
      </div>
    </div>
    <Info info={info} error={(!async || changedValue()) && error}/>
  </div>
}