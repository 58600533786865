import api from "./api";
import {lang} from "../languages";
import resources from "./resources";

export function index() {
  return api().get(`/${lang()}/${resources.working_hours}`)
}

export function store(data) {
  return api().post(`/${lang()}/${resources.working_hours}`, data)
}

export function update(id, data) {
  return api().put(`/${lang()}/${resources.working_hours}/${id}`, data)
}

export function destroy(id) {
  return api().delete(`/${lang()}/${resources.working_hours}/${id}`)
}