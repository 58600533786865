import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as SchedulesAPI from "../../api/schedules"
import Modal from "../common/modals/Modal";
import EditAppointement from "../../forms/EditAppointement";
import {useToast} from "../../providers/ToastProvider";
import ModulCalendar from "./ModulCalendar";

export default function Calendar({appointmentId, update}) {
  const [selectedDispo, setSelectedDispo] = useState("")
  const [showModal, setShowModal] = useState(false)

  const toast = useToast()
  const {t} = useTranslation("pages")

  const getDisponibilities = useCallback((start, end) => {
    if (start && end) {
      return SchedulesAPI.getDisponibilities(start, end, appointmentId)
    } else {
      return new Promise(() => [])
    }
  }, [appointmentId])

  const handleDispoClick = dispo => {
    setSelectedDispo(dispo)
    setShowModal(true)
  }

  useEffect(() =>{
    getDisponibilities()
  }, [getDisponibilities])

  return <>
    <ModulCalendar getDisponibilities={getDisponibilities} onSelect={handleDispoClick} selected={selectedDispo}/>
    <div>
      <Modal
        isOpen={showModal}
        closeModal={() => {
          setShowModal(false)
          setSelectedDispo("")
        }}
        title={t('pages:appointment.edit.title')}
        className="w-full max-w-lg"
      >
          <EditAppointement
            time={selectedDispo}
            closeModal={() => {
              setShowModal(false)
              toast.pushSuccess(t('pages:appointment.edit.success'))
              update()
            }}
            appointmentId={appointmentId}
          />
      </Modal>
    </div>
  </>
}