import {useAuth} from "react-oauth2-pkce";
import Error401 from "../errors/Error401";
import Error403 from "../errors/Error403";
import {authUser} from "../../api/user";
import {useEffect, useState} from "react";

export default function SecuredPage({role, children}) {
  const {authService} = useAuth()
  const [user, setUser] = useState({})

  useEffect(() => {
    authUser()
      .then(setUser)
  }, [])

  if (!authService.isAuthenticated()) {
    authService.authorize()
    return <Error401/>
  }

  if (role &&
    ((role !== "any" && !user.roles?.includes(role)) || (role === "any" && user.roles?.length === 0))
  ){
    return <Error403/>
  }
  return children
}