import {useState} from "react";
import {useTranslation} from "react-i18next";
import * as SchedulesAPI from "../api/schedules";
import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import DangerBtn from "../components/common/btns/solid/DangerBtn";

const defaultValues = {
  email: '',
}

export default function CancelAppointement({appointmentId, closeModal}) {
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)

  const {t} = useTranslation(['fields', 'errors', 'pages'])

  const handleSubmit = (data) => {
    setShowGlobalError(false)
    setErrors({})
    return SchedulesAPI.destroyPublic(appointmentId, data)
      .then(closeModal)
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  return <Formik
    initialValues={defaultValues}
    onSubmit={handleSubmit}
  >
    {({ isSubmitting }) => (
      <Form>
        {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
        <Input
          name="email"
          label={t('email')}
          placeholder={t('email')}
          required
          error={errors.email}
        />
        <div className="mt-4">
          <DangerBtn disabled={isSubmitting}>{t('pages:appointment.cancel.title')}</DangerBtn>
        </div>
      </Form>
    )}
  </Formik>
}