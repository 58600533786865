import {SearchIcon} from "@heroicons/react/outline";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useToast} from "../../../providers/ToastProvider";

export default function SearchField({fetchData, className, searchRow, update}) {
  const [results, setResults] = useState([])
  const [showResults, setShowResults] = useState(false)
  const {t} = useTranslation('translation')
  const toast = useToast()
  const search = data => {
    const val = data.target.value

    if (val.length < 0) {
      setResults([])
      setShowResults(false)
    } else {
      fetchData(val)
        .then(results => {
          setResults(results)
          setShowResults(true)
        })
        .catch(() => toast.pushUnKnown())
    }
  }

  return <div className="relative" onBlur={() => setTimeout(() => setShowResults(false), 200)}>
    <input
      className={`
        block mt-2 p-3
        border
        focus:border-secondary focus:outline-none
        focus:ring-1 focus:ring-secondary 
        w-full shadow-sm sm:text-sm border-gray-300 rounded-md
        ${className || ''}
      `}
      type="text"
      onChange={search}
      onFocus={() => results.length > 0 && setShowResults(true)}
    />
    <button type="submit" className="absolute right-0 top-0 mt-3 mr-2">
      <SearchIcon className="w-5 h-5"/>
    </button>
    <div className={`
      mt-2 ${showResults ? 'absolute' : 'hidden'}
      border border-secondary rounded-lg
      divide-y divide-secondary
      shadow-lg
      bg-white
      w-full overflow-hidden
    `}>
      {results.length > 0 ?
        results.map((result, id) => React.cloneElement(searchRow, {
          key: id,
          ...result,
          className: 'hover:bg-primary hover:text-white',
          onClick: () => update(result)
        }))
        :
        t('no-results')}
    </div>
  </div>
}