import LoadingWrapper from "../common/placeholders/LoadingWrapper";
import {useAppointment} from "../../providers/AppointmentProvider";
import ModulCalendar from "../schedules/ModulCalendar";
import {getAvailableTimes} from "../../api/appointment";
import {useCallback} from "react";

export default function ChooseDate({nextStep}) {
  const appointment = useAppointment()
  const getDisponibilities = useCallback((start, end) => {
    if (appointment.location) {
      return getAvailableTimes(appointment.task?.id, appointment.practitioner?.id, appointment.location?.id, {
        start: start,
        end: end
      })
    } else {
      return new Promise(() => [])
    }
  }, [appointment.location, appointment.task?.id, appointment.practitioner?.id])

  const selectDispo = (dispo) => {
    appointment.setSelectedDateTime(dispo)
    nextStep()
  }
  return <LoadingWrapper isLoading={appointment.isLoading}>
    <div className="w-full flex flex-col items-center justify-center">
      <ModulCalendar
        getDisponibilities={getDisponibilities}
        selected={appointment.selectedDateTime}
        onSelect={selectDispo}
        className="max-w-2xl mb-4"
      />
    </div>
  </LoadingWrapper>
}