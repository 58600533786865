import React, {useCallback, useContext, useEffect, useState} from "react";
import * as CartAPI from "../api/cart"

export const CartContext = React.createContext(undefined)
export const useCart = () => useContext(CartContext)

const LOCALSTORAGE_KEY = "cartId";

export const initialCartState = {
  id: -1,
  articles: [],
  nbArticles: 0,
  reduction: "",
  delivery: "",
  total: "",
};

export default function CartProvider({children}) {
  const [cartId, setCartId] = useState(localStorage.getItem(LOCALSTORAGE_KEY) || -1)
  const [cartData, setCartData] = useState({})
  const [nbArticles, setNbArticles] = useState(0)

  const countArticles = articles => {
    setNbArticles(articles.reduce((a, b) => a + b.quantity, 0))
  }

  const createCart = useCallback(() => {
    if (cartId === -1) {
      return CartAPI.store()
        .then(response => {
          setCartId(response.id)
          localStorage.setItem(LOCALSTORAGE_KEY, response.id)
          return response.id
        })
    }
    return Promise.resolve(cartId)
  }, [cartId])

  const AddItem = useCallback((itemId, quantity) => {
    return createCart()
      .then(id => CartAPI.addItem(id, itemId, {quantity: quantity}))
      .then(response => {
        setCartData(response)
        countArticles(response.articles)
      })
  }, [createCart])

  const RemoveItem = useCallback((itemId, quantity) => {
    return CartAPI.removeItem(cartId, itemId, {quantity: quantity})
      .then(response => {
        setCartData(response)
        countArticles(response.articles)
      })
  }, [cartId])

  const hasItem = useCallback(itemId => {
    return !!cartData.articles?.find(d => d.id === itemId)
  }, [cartData])

  const SetAddress = useCallback(addressId => {
    return CartAPI.update(cartId, {address_id: addressId})
      .then(response => {
        setCartData(response)
        return response.address_id
      })
  }, [cartId])

  const AddCoupon = useCallback(couponId => {
    return CartAPI.update(cartId, {promo_id: couponId})
      .then(setCartData)
  }, [cartId])

  const RemoveCoupon = useCallback(() => {
    return CartAPI.update(cartId, {promo_id: null})
      .then(setCartData)
  }, [cartId])

  const Store = useCallback(type => {
    return CartAPI.save({
      cart_id: cartId,
      payment_method: type
    })
  }, [cartId])

  const Unlink = useCallback(() => {
    setCartId(-1)
    setCartData(initialCartState)
    setNbArticles(0)
    localStorage.removeItem(LOCALSTORAGE_KEY)
    return Promise.resolve()
  }, [])

  const Destroy = useCallback(() => {
    return CartAPI.destroy(cartId)
      .then(Unlink)
  }, [cartId, Unlink])

  const CartContextd = useCallback(() => {
    return {
      addItem: AddItem,
      removeItem: RemoveItem,
      hasItem: hasItem,
      nbArticles: nbArticles,
      setAddress: SetAddress,
      addCoupon: AddCoupon,
      removeCoupon: RemoveCoupon,
      store: Store,
      destroy: Destroy,
      unlink: Unlink,
      ...cartData
    }
  }, [AddItem, RemoveItem, nbArticles, hasItem, cartData, SetAddress, AddCoupon, RemoveCoupon, Store, Destroy, Unlink])

  useEffect(() => {
    if (cartId !== -1) {
      CartAPI.show(cartId)
        .then(response => {
          setCartData(response)
          countArticles(response.articles)
        })
        .catch(error => {
          if (error?.response?.status === 404) {
            localStorage.removeItem(LOCALSTORAGE_KEY)
          }
        })
    }
  }, [cartId])

  return <CartContext.Provider value={CartContextd()}>
    {children}
  </CartContext.Provider>
}