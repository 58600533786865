import Price from "../common/Price";

export default function RowArticleWithPrice({article, ...props}) {
  return <div className="grid grid-cols-6 items-center gap-x-4 py-2">
    <div className="col-span-2 max-h-24 w-24 sm:col-span-1 flex justify-center">
      <img className="object-contain" src={article?.pictures[0].picture} alt="article"/>
    </div>
    <div className="text-sm col-span-3 sm:col-span-4 pr-2">{article.formatted_name}</div>
    <div className="justify-self-end"><b>{article.quantity}x</b> <Price>{article.price}</Price></div>
  </div>
}