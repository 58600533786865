import {useState} from "react";
import {useTranslation} from "react-i18next";
import * as UserAPI from "../api/user";
import {Form, Formik} from "formik";
import CheckBoxGroup from "../components/common/inputs/CheckBoxGroup";
import CheckBox from "../components/common/inputs/CheckBox";

function RoleInput({role, ...props}) {
  return <CheckBox info={<span dangerouslySetInnerHTML={{__html: role.description}}/>} value={role.id} {...props}>
    {role.role}
  </CheckBox>
}

export default function UserRightsForm({user, rights}) {
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const {t} = useTranslation(['fields', 'errors'])

  const update = data => {
    setShowGlobalError(false)
    setErrors({})
    return UserAPI.updateRoles(user.id, data)
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }


  return <Formik
    initialValues={{
      roles: user.roles || []
    }}
    enableReinitialize
    onSubmit={update}
  >
    <Form>
      {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
      <div className="flex flex-col space-y-2 mt-2 mx-4">
        <CheckBoxGroup
          error={errors.roles}
          name="roles"
          className="lg:grid lg:grid-cols-2 lg:gap-x-4 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5"
        >
          {rights.map((right, id) => <RoleInput key={id} role={right} />)}
        </CheckBoxGroup>
      </div>
    </Form>
  </Formik>
}