import BackOffice from "../../../../components/common/Layout/BackOffice";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import PrimaryBtn from "../../../../components/common/btns/solid/PrimaryBtn";
import * as PromosAPI from "../../../../api/promos"
import PromoCode from "../../../../components/PromoCode/PromoCode";
import Modal from "../../../../components/common/modals/Modal";
import PromoForm from "../../../../forms/PromoForm";
import {useToast} from "../../../../providers/ToastProvider";

export default function PromoCodes() {
  const [isLoading, setIsLoading] = useState(true)
  const [createModal, setCreateModal] = useState(false)
  const [promos, setPromos] = useState([])
  const [contextes, setContextes] = useState([])
  const {t, i18n} = useTranslation(['routes', 'pages'])
  const toast = useToast()

  const update = () => {
    PromosAPI.index()
      .then(setPromos)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  }

  const closeModal = () => {
    setCreateModal(false)
    update()
  }

  useEffect(() => {
    PromosAPI.getContextes()
      .then(setContextes)
      .catch(() => toast.pushUnKnown())
    update()
    //eslint-disable-next-line
  }, [i18n.language])

  return <BackOffice role="admin" isLoading={isLoading}>
    <h1 className="mb-4">{t('promotion-codes.name')}</h1>
    <div className="mb-4">
      <PrimaryBtn onClick={() => setCreateModal(true)}>{t('pages:promo-code-check.create')} +</PrimaryBtn>
      <Modal
        isOpen={createModal}
        closeModal={closeModal}
        title={t('pages:promo-code-check.create')}
        className="w-full max-w-6xl"
      >
        <PromoForm closeModal={closeModal} promoContexts={contextes}/>
      </Modal>
    </div>
    <div className="space-y-2">
      {promos.map((promo, id) => <PromoCode key={id} promo={promo} contexts={contextes} update={update}/>)}
    </div>
  </BackOffice>
}