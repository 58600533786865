import api from "./api";
import {lang} from "../languages";
import resources from "./resources";

export function getContents(resource, id) {
  let uri = `/${lang()}`
  if (resource) uri += `/${resource}`
  if (id) uri += `/${id}`

  return api().get(uri)
}

export function store(resource, data) {
  return api().post(`/${lang()}/${resource}`, data)
}

export function update(id, data) {
  return api().put(`/${lang()}/${resources.contents}/${id}`, data)
}

export function destroy(id) {
  return api().delete(`/${lang()}/${resources.contents}/${id}`)
}