import { Tab } from "@headlessui/react";

export default function TabsWrapper({titles = [], children}) {
  return <Tab.Group>
    <Tab.List className="flex flex-row space-x-4 py px-2 border-b">
      {titles.map((title, id) => <Tab
        key={id}
        className={({selected}) => `focus:outline-none rounded-t-lg p-2 hover:bg-secondary hover:text-white ${selected ? 'border-b border-secondary text-secondary': ''}`}
      >
        {title}
      </Tab> )}
    </Tab.List>
    <Tab.Panels className="px-4 py-4">
      {children}
    </Tab.Panels>
  </Tab.Group>
}