import {useState} from "react";
import {Draggable, Droppable} from "react-beautiful-dnd";
import Card from "../common/Card";
import {PencilIcon, SelectorIcon, XCircleIcon} from "@heroicons/react/outline";
import TransparentBtn from "../common/btns/TransparentBtn";
import DeleteDialogModal from "../common/modals/DeleteDialogModal";
import {useTranslation} from "react-i18next";
import Modal from "../common/modals/Modal";
import TaskCategoryForm from "../../forms/TaskCategoryForm";
import * as TaskCategoriesAPI from "../../api/taskCategories";

export default function Category({category, index, children, refresh}) {
  const [deleteModal, setDeleteModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const {t} = useTranslation('pages')
  const deleteCategory = () => {
    TaskCategoriesAPI.destroy(category.id)
      .then(refresh)
  }

  const closeModal = () => {
    setEditModal(false)
    refresh()
  }

  return <Draggable draggableId={`cat-${category.id}`} index={index}>
    {provided => (
      <Card {...provided.draggableProps} innerRef={provided.innerRef} className="bg-white max-w-xl">
        <div className="flex flex-row items-center justify-start space-x-2">
          <div {...provided.dragHandleProps}><SelectorIcon className="w-5 h-5 text-secondary"/></div>
          <div><h3 className="text-2xl mb-0">{category.name}</h3></div>
          <div className="flex-grow flex flex-row justify-end">
            <TransparentBtn onClick={() => setEditModal(true)}><PencilIcon className="w-5 h-5"/></TransparentBtn>
            <Modal
              title={t('task-categories.edit-category')}
              isOpen={editModal}
              closeModal={closeModal}
              className="w-full max-w-xl"
            >
              <TaskCategoryForm category={category} closeModal={closeModal}/>
            </Modal>
            <TransparentBtn className="flex flex-row justify-end text-red-600" onClick={() => setDeleteModal(true)}><XCircleIcon className="h-5 w-5"/></TransparentBtn>
            <DeleteDialogModal
              isOpen={deleteModal}
              closeModal={() => setDeleteModal(false)}
              title={t('task-categories.delete-category.title', {name: category.name})}
              description={t('task-categories.delete-category.description')}
              action={deleteCategory}
            />
          </div>
        </div>
        <Droppable droppableId={`cat-${category.id}`} type="tasks">
          {provided => (
            <div
              className="flex flex-col ml-4 mt-6 divide-y space-y-2 text-sm"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {children}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Card>
    )}
  </Draggable>
}