export default function LeftCard (props) {
  return (
    <div className="container mx-auto my-5">
      <div className="rounded-lg flex flex-col md:flex-row items-center md:shadow-xl mx-2 items-stretch md:border md:border-secondary">
        <div className="z-0 relative w-full md:w-2/5 h-80 md:h-auto md:inset-0 overflow-hidden rounded-lg md:rounded-none md:rounded-l-lg">
          <div
            className="
              absolute inset-0 w-full h-full
              object-cover object-center
              bg-yellow-300 bg-cover bg-bottom bg-blend-multiply
              "
          >
            {props.media}
          </div>
          <svg className="hidden absolute right-0 md:block h-full w-24 fill-current text-white"
               viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon points="101,0 99,0 50,101 101,101"/>
          </svg>
        </div>

        <div className="z-10 w-full h-full md:w-3/5 flex items-center -mt-6 md:mt-0 md:bg-white md:rounded-r-lg">
          <article className="p-8 md:pr-18 md:pl-14 md:py-12 mx-2 md:mx-0 md:min-h-72 h-full bg-white border border-secondary md:border-none rounded-lg md:rounded-none md:rounded-l-lg shadow-xl md:shadow-none">
            <h2>{props.title}</h2>
            {props.children}
          </article>
        </div>
      </div>
    </div>
  )
}