import {useAppointment} from "../../providers/AppointmentProvider";
import ListWithClick from "./ListWithClick";

export default function ChooseLocation ({nextStep}) {
  const appointment = useAppointment()

  const chooseLocation = location => {
    appointment.setLocation(location)
  }

  return <ListWithClick
    onClick={chooseLocation}
    selected={appointment.location}
    items={appointment.locations}
    nextStep={nextStep}
  />
}