import api from './api'
import {lang} from "../languages";
import resources from "./resources";

export function adminIndex() {
  return api().get(`${lang()}/admin/${resources.exercices}`)
}

export function store(data) {
  return api().post(`${lang()}/${resources.exercices}`, data)
}

export function update(id, data) {
  return api().put(`${lang()}/${resources.exercices}/${id}`, data)
}

export function destroy(id) {
  return api().delete(`${lang()}/${resources.exercices}/${id}`)
}

export function myExercices() {
  return api().get(`${lang()}/${resources.exercices}`)
}

export function show(id) {
  return api().get(`${lang()}/${resources.exercices}/${id}`)
}

export function addQuestion(exerciceId, data) {
  return api().post(`${lang()}/${resources.exercices}/${exerciceId}/${resources.questions}`, data)
}

export function updateQuestion(id, data) {
  return api().put(`${lang()}/${resources.questions}/${id}`, data)
}

export function destroyQuestion(id) {
  return api().delete(`${lang()}/${resources.questions}/${id}`)
}

export function storeAnswer(questionId, data) {
  return api().post(`${lang()}/${resources.questions}/${questionId}/${resources.answers}`, data)
}

export function updateAnswer(answerId, data) {
  return api().put(`${lang()}/${resources.answers}/${answerId}`, data)
}

export function addUser(id, email) {
  return api().post(`${lang()}/${resources.exercices}/${id}/add-user`, {email})
}

export function addEventUsers(id, event) {
  return api().post(`${lang()}/${resources.exercices}/${id}/add-event-users`, {event})
}

export function resetExercice(id) {
  return api().post(`${lang()}/${resources.exercices}/${id}/reset`)
}

export function removeUser(id, email) {
  return api().delete(`${lang()}/${resources.exercices}/${id}/remove-user`, {data: {email}})
}

export function sendExercice(id) {
  return api().post(`${lang()}/${resources.exercices}/${id}/send`)
}
