import Label from "./Label";
import {useField} from "formik";

export default function CheckBox({id, children, error, info, async, className, ...props}) {
  const [field] = useField({...props, type: 'checkbox'})

  const handleBlur = e => {
    field.onBlur(e)
    if (async) {
      // The latest touched and errors aren't available until after the
      // formik's onBlur handler has been called. Setting a timeout allows us
      // to access the proper touched and errors after onBlur event has
      // occurred.
      setTimeout(() => {
        async(e)
      }, 0);
    }
  };

  return <div className={`flex items-start ${className || ''}`}>
    <div className="flex items-center h-5">
      <input
        id={id}
        type="checkbox"
        className="focus:ring-secondary h-4 w-4 text-secondary border-primary rounded"
        {...props}
        {...field}
        onBlur={handleBlur}
      />
    </div>
    <div className="ml-3 text-sm">
      <Label id={id} error={error}>
        {children}
        {info && <p className="text-gray-500 font-normal sm:text-xs">{info}</p>}
      </Label>
    </div>
  </div>
}