import {Link, useRouteMatch} from "react-router-dom";

export default function NavLink({logo, to, children}) {
  const selected = useRouteMatch({
    path: to
  })
  return <li className={`
      rounded-lg p-1 md:pr-2 hover:text-white
      ${selected ? 'bg-primary shadow-lg text-white' : 'hover:bg-primary hover:shadow-lg'}
      `}>
    <Link to={to} className={`
      hover:text-current text-current font-bold text-xs hover:no-underline
      flex flex-row items-center divide-x divide-current space-x-4
      `}>
    <div className="w-8 h-8">{logo}</div>
    <div className="pl-2 hidden md:block">{children}</div>
    </Link>
  </li>
}