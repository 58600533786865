import api from "./api";
import {lang} from "../languages";
import resources from "./resources";
import {authUser} from "./user";

export function index() {
  return api().get(`/${lang()}/${resources.practitioners}`)
}

export function store(data) {
  return api().post(`/${lang()}/${resources.practitioners}`, data)
}

export function update(id, data) {
  return api().put(`/${lang()}/${resources.practitioners}/${id}`, data)
}

export function destroy(id) {
  return api().delete(`/${lang()}/${resources.practitioners}/${id}`)
}

export function myPratitioners() {
  return authUser()
    .then(user => api().get(`/${lang()}/${resources.user}/${user.id}/${resources.practitioners}`))}