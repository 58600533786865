import Card from "../common/Card";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
export default function DashboardCard({title, children, link, ...props}) {
  const {t} = useTranslation('forms')
  return <Card {...props}>
    <h3 className="text-2xl text-center">{title}</h3>
    <div className="text-center text-sm">
      {children}
    </div>
    <div className="text-center mt-4"><Link to={link}>{t('go')}</Link></div>
  </Card>
}