import logo from "../resources/images/logo-geo.png"
import {useEffect, useState} from "react";
import {getContents} from "../api/contents";
import {useTranslation} from "react-i18next";
import PageWithPictureHeader from "../components/common/Layout/PageWithPictureHeader";
import {useToast} from "../providers/ToastProvider";
import HelmetSEO from "../utils/HelmetSEO";

export default function GeobiologieMemoires() {
  const {i18n} = useTranslation('routes')
  const [content, setContent] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const toast = useToast()

  useEffect(() => {
    getContents( 'contents', 'geobiologie-memoires')
      .then(setContent)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
    //eslint-disable-next-line
  }, [])
  return <>
    <HelmetSEO page="geobiologie"/>
    <PageWithPictureHeader
      headerStyle="bg-geobiologie-sm md:bg-geobiologie"
      headerContent={<div className="
        p-3 sm:p-4 md:p-5
        bg-white bg-opacity-20
        md:bg-opacity-50
        rounded-md
      ">
        <img src={logo} alt="Logo géobiologie & mémoires"/>
      </div>
      }
      isLoading={isLoading}
    >
      <div className="h-24 self-center mb-10 md:mb-20">
        <img className="h-auto max-h-full max-w-full" src={logo} alt="Logo géobiologie & mémoires"/>
      </div>
      <article dangerouslySetInnerHTML={{
        __html: content.text_translations && content.text_translations[i18n.language],
      }}/>
    </PageWithPictureHeader>
  </>
}