import {Form, Formik} from "formik";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import FormSelect from "../components/common/inputs/FormSelect";
import Input from "../components/common/inputs/Input";
import DangerBtn from "../components/common/btns/solid/DangerBtn";
import {PlusIcon, TrashIcon} from "@heroicons/react/outline";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import * as articleAPI from "../api/articles";
import Spinner from "../components/common/Spinner";

const defaultPromoAction = {
  amount: "",
  uses: "",
  promo_type_id: "",
  promo_context_id: ""
}

export default function ArticlePromoActionForm({articleId, promoAction, isCreating, remove, store, promoContexts, promoTypes}) {
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [sending, setSending] = useState(false)

  const {t} = useTranslation(['fields', 'errors'])

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  const update = data => {
    articleAPI.updatePromoAction(promoAction.id)
      .catch(handleError)
  }

  const create = (data, {resetForm}) => {
    setSending(true)
    articleAPI.addPromoAction(articleId, data)
      .then(response => {
        resetForm(defaultPromoAction)
        store(response)
      })
      .catch(handleError)
      .then(() => setSending(false))
  }

  const handleRemove = async () => {
    setSending(true)
    await remove()
    setSending(false)
  }

  return <Formik
    initialValues={promoAction || defaultPromoAction}
    enableReinitialize
    onSubmit={create}
  >
    {({values}) => <Form>
      {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
      <div className="py-2">
        <div className="grid gap-2 sm:grid-cols-2 lg:grid-cols-4">
          <FormSelect
            label={t('promo-context')}
            name="promo_context_id"
            error={errors.promo_context_id}
            required
            info={promoContexts.find(context => context.id === values.promo_context_id)?.description}
            async={!isCreating ? update : null}
          >
            <option>---</option>
            {promoContexts.map((context, id) => <option key={id} value={context.id}>{context.name}</option> )}
          </FormSelect>
          <FormSelect
            label={t('promo-type')}
            name="promo_type_id"
            error={errors.promo_type_id}
            required
            async={!isCreating ? update : null}
          >
            <option>---</option>
            {promoTypes.map((type, id) => <option key={id} value={type.id}>{type.name}</option>)}
          </FormSelect>
          <Input
            label={t('amount')}
            placeholder={t('amount')}
            name="amount"
            error={errors.amount}
            required
            async={!isCreating ? update : null}
          />
          <Input
            label={t('uses')}
            placeholder={t('uses')}
            name="uses"
            error={errors.uses}
            async={!isCreating ? update : null}
          />
        </div>
        <div className="mt-2">
          {isCreating ?
            <PrimaryBtn type="submit" disabled={sending}>
              {sending ? <Spinner className="w-5 h-5"/> : <PlusIcon className="w-4 h-4"/>}
            </PrimaryBtn>
            : <DangerBtn type="button" onClick={handleRemove} disabled={sending}>
              {sending ? <Spinner className="w-5 h-5"/> : <TrashIcon className="w-4 h-4"/>}
            </DangerBtn>
          }
        </div>
      </div>
    </Form>}
  </Formik>
}