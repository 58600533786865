import {useCallback, useEffect, useState} from "react";
import * as UserAddresses from "../../../api/userAddresses";
import * as AddressesAPI from "../../../api/addresses"
import {useTranslation} from "react-i18next";
import Address from "../../user/Address";
import TransparentBtn from "../../common/btns/TransparentBtn";
import UserAddressForm from "../../../forms/UserAddressForm";
import Modal from "../../common/modals/Modal";
import {useToast} from "../../../providers/ToastProvider";
import {useCart} from "../../../providers/CartProvider";
import TabsWrapper from "../../common/tabs/TabsWrapper";
import {Tab} from "@headlessui/react";
import Card from "../../common/Card";

function MyAddresses({handleClick, selected}) {
  const [addresses, setAddresses] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const {t} = useTranslation(['pages', 'errors'])
  const toast = useToast()

  const updateAddresses = useCallback(() => {
    UserAddresses.get()
      .then(setAddresses)
      .catch(() => toast.pushError(t('errors:unknown')))
    //eslint-disable-next-line
  }, [t])

  useEffect(() => {
    updateAddresses()
  }, [updateAddresses])

  return <Tab.Panel className="focus:outline-none">
    <div className="flex flex-col space-y-2">
      {addresses.map(address => <Address
        key={address.id}
        className={`${selected === address.id ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'} cursor-pointer`}
        address={address}
        updateAddresses={updateAddresses}
        onClick={() => handleClick(address.id)}/>
      )}
    </div>
    <div className="mt-4 text-center">
      <TransparentBtn className="text-secondary font-bold hover:underline" onClick={() => setOpenModal(true)}>{t('pages:my-addresses.create-modal.title')} +</TransparentBtn>
      <Modal
        title={t('pages:my-addresses.create-modal.title')}
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
        className="w-full max-w-xl"
      >
        <UserAddressForm isCreating closeModal={() => {setOpenModal(false); updateAddresses()}}/>
      </Modal>
    </div>
  </Tab.Panel>
}

function CollectAddresses({handleClick, selected}) {
  const [addresses, setAddresses] = useState([])
  const {t} = useTranslation(['pages', 'errors'])
  const toast = useToast()

  useEffect(() => {
    AddressesAPI.index()
      .then(setAddresses)
      .catch(() => toast.pushError(t('errors:unknown')))
    //eslint-disable-next-line
  }, [t])
  return <Tab.Panel className="focus:outline-none">
    <div className="flex flex-col space-y-2">
      {addresses.map(address => <Card
        key={address.id}
        className={`${selected === address.id ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'} cursor-pointer`}
        onClick={() => handleClick(address.id)}
      >
        <div className="flex flex-col space-y-2">
          <p>{address.street}</p>
          <p>{address.npa} {address.city}</p>
        </div>
      </Card>)}
    </div>
  </Tab.Panel>
}

export default function Delivery({nextStep}) {
  const [selected, setSelected] = useState(-1)
  const {t} = useTranslation(['pages', 'errors'])
  const toast = useToast()
  const cart = useCart()

  const handleClick = id => {
    setSelected(id)
    cart.setAddress(id)
      .then(setSelected)
      .then(nextStep)
      .catch(() => toast.pushError(t('errors:unknown')))
  }

  useEffect(() => {
    setSelected(cart.address_id)
  }, [cart.address_id])

  return <div className="max-w-lg mx-auto">
      <h2 className="text-center">{t('checkout.delivery.title')}</h2>
      <TabsWrapper titles={[t('checkout.delivery.my-addresses'), t('checkout.delivery.collect-point')]}>
        <MyAddresses selected={selected} handleClick={handleClick}/>
        <CollectAddresses selected={selected} handleClick={handleClick} />
      </TabsWrapper>
  </div>
}