import BackOffice from "../../../components/common/Layout/BackOffice";
import {useEffect, useState} from "react";
import * as ExercicesAPI from "../../../api/exercices";
import {useTranslation} from "react-i18next";
import {useToast} from "../../../providers/ToastProvider";
import Card from "../../../components/common/Card";
import PrimaryBtn from "../../../components/common/btns/solid/PrimaryBtn";
import Modal from "../../../components/common/modals/Modal";
import ExerciceAnswerForm from "../../../forms/ExerciceAnswerForm";

function AnswerQuestions({exercice}) {
  return <div>
    {exercice.questions.map(question => <div>
      <div className="content text-2xl font-semibold" dangerouslySetInnerHTML={{__html: question.translated_text}}/>
      <div>
        <ExerciceAnswerForm
          answer={question.answers.length > 0 && question.answers[0]}
          question={question}
        />
      </div>
    </div>)}
  </div>
}

function MyExercice ({exercice, update}) {
  const [openModal, setOpenModal] = useState(false)
  const {t} = useTranslation(['pages'])

  const closeAndUpdate = () => {
    setOpenModal(false)
    update()
  }
  return <Card>
    <h4>{exercice.translated_title}</h4>
    <div className="content" dangerouslySetInnerHTML={{__html: exercice.translated_description}}/>
    <div className="mt-2">
      <PrimaryBtn onClick={() => setOpenModal(true)}>{t('exercices.answer-questions')}</PrimaryBtn>
      <Modal isOpen={openModal} closeModal={closeAndUpdate} title={exercice.translated_title} className="w-full max-w-screen-lg">
        <AnswerQuestions exercice={exercice}/>
      </Modal>
    </div>
  </Card>
}

export default function MyExercices() {
  const [isLoading, setIsLoading] = useState(true)
  const [exercices, setExercices] = useState([])
  const {t, i18n} = useTranslation(['routes', 'pages'])
  const toast = useToast()

  const update = () => {
    ExercicesAPI.myExercices()
      .then(setExercices)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  }

  useEffect(() => {
    update()
    // eslint-disable-next-line
  }, [i18n.language])

  return <BackOffice isLoading={isLoading}>
    <h1>{t('my-exercices.name')}</h1>
    <div className="mt-10 flex flex-col space-y-6">
      {exercices.length > 0 ? exercices.map(exercice => <MyExercice key={exercice.id} exercice={exercice} update={update}/>) : <div>{t('pages:exercices.no-exercice')}</div>}
    </div>
  </BackOffice>
}