import Card from "../common/Card";
import {useTranslation} from "react-i18next";
import {getFormattedDate} from "../../utils/dates";
import Price from "../common/Price";
import DangerBtn from "../common/btns/solid/DangerBtn";
import {PrinterIcon, TrashIcon} from "@heroicons/react/outline";
import AuthenticatedLink from "../common/links/AuthenticatedLink";
import Spinner from "../common/Spinner";
import PrimaryBtn from "../common/btns/solid/PrimaryBtn";
import {useState} from "react";
import DeleteDialogModal from "../common/modals/DeleteDialogModal";
import * as PromoAPI from "../../api/promos"

function Cell({title, children}) {
  return <div className="flex flex-col">
    <div><b>{title}</b></div>
    <div className="ml-2">{children}</div>
  </div>
}

export default function PromoCode({promo, contexts, update}) {
  const {t, i18n} = useTranslation(['pages', 'fields'])
  const [deleteModal, setDeleteModal] = useState(false)
  const handleDelete = () => {
    return PromoAPI.destroy(promo.id)
      .then(() => {
        setDeleteModal(false)
        update()
      })
  }

  return <Card>
    <div className="grid gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      <Cell title={t('fields:promo-code')}>{promo.id}</Cell>
      <Cell title={t('fields:email')}>{promo.email || '-'}</Cell>
      <Cell title={t('promo-code-check.remaining-uses')}>{promo.uses || promo.uses === 0 ? promo.uses : '-' }</Cell>
      <Cell title={t('promo-code-check.until')}>{promo.until ? getFormattedDate(promo.until, i18n.language) : '-'}</Cell>
      <Cell title={t('fields:promo-context')}>
        <ul>
          {promo.promo_uses?.map((promo_use, id) => <li key={id}>
            {contexts.find(context => context.id === promo_use.promo_context_id)?.name}: {(promo_use.uses || promo_use.uses === 0) && `${promo_use.uses}x`} {promo_use.promo_type_id === 'direct' ? <Price>{promo_use.amount}</Price> : `${promo_use.amount}%`}
          </li>)}
        </ul>
      </Cell>
    </div>
    <div className="flex mt-4 space-x-2">
      <DangerBtn onClick={() => setDeleteModal(true)}><TrashIcon className="w-5 h-5"/></DangerBtn>
      <AuthenticatedLink url={promo.pdf_url} filename={`${promo.id}.pdf`} className="text-current hover:text-current hover:no-underline">
        {({isLoading}) => <>
          <PrimaryBtn className="flex" disabled={isLoading}>
            {isLoading && <Spinner className="w-5 h-5 text-primary mr-2"/>}
            <PrinterIcon className="w-5 h-5"/>
          </PrimaryBtn>
        </>}
      </AuthenticatedLink>
      <DeleteDialogModal
        isOpen={deleteModal}
        title={t('promo-code-check.delete.title')}
        description={t('promo-code-check.delete.description')}
        closeModal={() => setDeleteModal(false)}
        action={handleDelete}
      />
    </div>
  </Card>
}