import PictureHeader from "../components/common/headers/PictureHeader";
import TextHeader from "../components/common/headers/TextHeader";
import {useTranslation} from "react-i18next";
import Routes from "../components/common/footer/Routes";
import Card from "../components/shop/Card";
import {useCallback, useEffect, useState} from "react";
import * as ArticleCategoriesAPI from "../api/articleCategories";
import LoadingWrapper from "../components/common/placeholders/LoadingWrapper";
import SimpleSelect from "../components/common/inputs/SimpleSelect";
import {useParams} from "react-router-dom"
import {useCart} from "../providers/CartProvider";
import {useToast} from "../providers/ToastProvider";
import HelmetSEO from "../utils/HelmetSEO";

export default function Shop() {
  const {t} = useTranslation('routes')
  const [categories, setCategories] = useState([])
  const [curCat, setCurCat] = useState("")
  const [articles, setArticles] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { categoryId } = useParams()
  const cart = useCart()
  const toast = useToast()

  const handleCategoryChange = id => {
    setIsLoading(true)
    setCurCat(id)
    setArticles(categories.find(cat => cat.id.toString() === id.toString())?.articles || categories.map(cat => cat.articles).flat())
    setIsLoading(false)
  }

  const update = useCallback(() => {
    ArticleCategoriesAPI.index()
      .then(response => {
        setCategories(response.filter(cat => cat.articles.length > 0))
        let tmp = response.map(cat => cat.articles).flat()
        if (categoryId) tmp = tmp.filter(art => art.article_category_id.toString() === categoryId.toString())
        setArticles(tmp)
      })
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
    // eslint-disable-next-line
  }, [categoryId, cart.articles])

  useEffect(() => {
    update()
    setCurCat(categoryId || "")
  }, [categoryId, update])

  return (
    <>
      <HelmetSEO page="shop"/>
      <PictureHeader
        className="
        bg-shop-sm
        md:bg-shop
        "
      >
        <TextHeader>{t('shop.name')}</TextHeader>
      </PictureHeader>
      <main className="
        px-10 py-20
        flex flex-col lg:flex-row
      ">
        <LoadingWrapper
          isLoading={isLoading}
        >
          <aside
            className="
            sticky top-32
            lg:relative lg:top-0
            w-screen lg:w-1/5
            -ml-10 lg:ml-0 px-10 lg:px-0 pt-2
            border-b-2 lg:border-b-0 lg:border-r-2 border-double border-secondary
            bg-white
          "
          >
            <Routes
              title={t('translation:categories')}
              className="hidden lg:block lg:sticky lg:top-36"
            >
              <li onClick={() => handleCategoryChange("")} className={`cursor-pointer hover:text-secondary`}>
                <p><span className={curCat === "" ? 'border-b border-secondary font-semibold' : ''}>{t('translation:all')}</span></p>
              </li>
              {categories.map((cat, id) => <li key={id} onClick={() => handleCategoryChange(cat.id)} className="mt-1 cursor-pointer hover:text-secondary">
                <p><span className={curCat.toString() === cat.id.toString() ? 'border-b border-secondary font-semibold' : ''}>{cat.name}</span></p>
              </li>)}
            </Routes>
            <div className="lg:hidden pb-4">
              <h4>{t('translation:categories')}</h4>
              <div className="max-w-sm">
                <SimpleSelect value={curCat} onChange={e => handleCategoryChange(e.target.value)}>
                  <option>{t('translation:all')}</option>
                  {categories.map((cat, id) => <option key={id} value={cat.id}>{cat.name}</option>)}
                </SimpleSelect>
              </div>
            </div>
          </aside>
          <div className="
            w-full mt-10 lg:w-4/5 lg:mt-0
            flex flex-col justify-center
            md:grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4
            space-y-6 md:gap-6 md:space-y-0
            lg:pl-10
          ">
            {articles.map((article, id) =>
              <Card
                key={id}
                article={article}
                link={t('shop-article.link', {itemId: article.id_sku})}
              />
            )}
          </div>
        </LoadingWrapper>
      </main>
    </>
  )
}