import ContactCard from "../components/contact/ContactCard";
import logoGeo from '../resources/images/logo-geo.png'
import logoPow from '../resources/images/Logo_Power_Healing.png'
import TextHeader from "../components/common/headers/TextHeader";
import {useTranslation} from "react-i18next";
import HelmetSEO from "../utils/HelmetSEO";
export default function Contact() {
  const {t} = useTranslation('routes')
  return (<>
    <HelmetSEO page="contact"/>
    <div className="
      min-h-screen
      mt-32 pt-20
      bg-cover bg-center bg-contact-sm md:bg-contact
      flex flex-col
    ">
      <TextHeader className="self-center mb-20">{t('contact.name')}</TextHeader>
      <main className="
        py-20 px-5 gap-y-28 lg:gap-x-4 lg:gap-y-0
        grid grid-rows-2 lg:grid-rows-none lg:grid-cols-2
        justify-items-center lg:items-center
      ">
        <ContactCard
          logo={logoGeo}
          link="https://geobiologie-memoires.com/contact"
        >
          <p>Dominique Goy</p>
          <p><a href="mailto:dominique@geobiologie-memoires.com">dominique@geobiologie-memoires.com</a></p>
        </ContactCard>
        <ContactCard
          logo={logoPow}
          link="https://power-healing.ch/kontakt/"
        >
          <p>Fredy Anthamatten</p>
          <p><a href="mailto:fredy@power-healing.ch">fredy@power-healing.ch</a></p>
        </ContactCard>
      </main>
    </div>
  </>)
}