import {useCallback, useEffect, useState} from "react";
import * as ContentAPI from "../../../../../api/contents";
import BackOffice from "../../../../../components/common/Layout/BackOffice";
import ContentForm from "../../../../../forms/ContentForm";
import Card from "../../../../../components/common/Card";
import PrimaryBtn from "../../../../../components/common/btns/solid/PrimaryBtn";
import {useTranslation} from "react-i18next";
import Modal from "../../../../../components/common/modals/Modal";
import {useToast} from "../../../../../providers/ToastProvider";

function Content({content, withMedia, onChange}) {
  return <Card>
    <ContentForm
      content={content}
      withMedia={withMedia}
      withTitle
      canDelete
      onChange={onChange}
    />
  </Card>
}

export default function MultipleContentPage({title, resource, withMedia}) {
  const [contents, setContents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [openModal, setOpenModal] = useState(false)

  const {t} = useTranslation('pages')
  const toast = useToast()

  const closeModal = () => {
    setOpenModal(false)
    update()
  }

  const update = useCallback(() => {
    ContentAPI.getContents(resource)
      .then(setContents)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
    //eslint-disable-next-line
  }, [resource])

  useEffect(() => {
    update()
  }, [update])

  return <BackOffice role="editor" isLoading={isLoading}>
    <h1 className="mb-4">{title}</h1>
    <div className="flex flex-col space-y-6">
      <div>
        <PrimaryBtn onClick={() => setOpenModal(true)}>{t('contents.create')} +</PrimaryBtn>
        <Modal
          title={t('contents.create')}
          isOpen={openModal}
          closeModal={closeModal}
          className="w-full max-w-xl"
        >
          <ContentForm
            withMedia={withMedia}
            withTitle
            isCreating
            resource={resource}
            closeModal={closeModal}
          />
        </Modal>
      </div>
      {contents.map((content, id) => <Content
        key={id}
        content={content}
        withMedia={withMedia}
        onChange={update}
      />)}
    </div>
  </BackOffice>
}