import SecondaryBtn from "../common/btns/solid/SecondaryBtn";
import {useTranslation} from "react-i18next";
import {useToast} from "../../providers/ToastProvider";
import {useAuth} from "react-oauth2-pkce";
import {useState} from "react";
import Modal from "../common/modals/Modal";
import Spinner from "../common/Spinner";
import Payment from "./Payment";
import * as EventAPI from "../../api/events";
import Alert from "../common/Alert";

export default function SubscribeBtn({event, update}) {
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const {t} = useTranslation(['forms', 'pages'])
  const toast = useToast()
  const {authService} = useAuth()

  const handleClick = () => {
    if (authService.isAuthenticated()) {
      if (event.price > 0) {
        setOpenModal(true)
      }
      else {
        setLoading(true)
        EventAPI.subscribe(event.id)
          .then(() => {
            toast.pushSuccess(t('pages:events.payment.success'))
            update()
          })
          .catch(() => toast.pushUnKnown())
          .then(() => setLoading(false))
      }
    } else {
      toast.pushError(t('pages:events.please-login'))
    }
  }

  const closeModal = () => {
    setOpenModal(false)
    update()
  }

  if (event.registered) {
    return <Alert color="green-600">
      {t('pages:events.already-subscribed')}
    </Alert>
  }

  return <>
    <SecondaryBtn onClick={handleClick} disabled={loading}>{loading ? <Spinner className="w-5 h-5"/> : t('pages:events.subscribe')}</SecondaryBtn>
    <Modal
      isOpen={openModal}
      closeModal={closeModal}
      className="w-full max-w-xl"
    >
      <Payment price={event.price} eventId={event.id} closeModal={closeModal}/>
    </Modal>
  </>
}