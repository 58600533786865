import TextHeader from "../components/common/headers/TextHeader";
import SwissVedaDisclosure from "../components/common/SwissVedaDisclosure";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {getContents} from "../api/contents";
import PageWithPictureHeader from "../components/common/Layout/PageWithPictureHeader";
import {useToast} from "../providers/ToastProvider";
import HelmetSEO from "../utils/HelmetSEO";

export default function Faq() {
  const {t, i18n} = useTranslation('routes')
  const [questions, setQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const toast = useToast()

  useEffect(() => {
    getContents( 'faq')
      .then(setQuestions)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
    //eslint-disable-next-line
  }, [])

  return <>
    <HelmetSEO page="faq"/>
    <PageWithPictureHeader
      headerStyle="bg-faq-sm md:bg-faq"
      headerContent={<TextHeader>{t('faq.name')}</TextHeader>}
      isLoading={isLoading}
    >
      <article>
        {questions.map((data) => (
          <SwissVedaDisclosure key={data.id} title={data.title_translations && data.title_translations[i18n.language]} className="mt-3">
            {data.text_translations[i18n.language]}
          </SwissVedaDisclosure>
        )) }
      </article>
    </PageWithPictureHeader>
  </>
}