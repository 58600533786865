import TransparentBtn from "./btns/TransparentBtn";
import {XIcon} from "@heroicons/react/solid";
import {useState} from "react";

export default function Alert({color, children, closeBtn}) {
  const [open, setOpen] = useState(true)
  return open && <div className={`w-full flex space-x-2 border-2 border-${color} bg-${color} bg-opacity-5 rounded-lg py-2 px-4 my-4`}>
    <div className="flex-grow">
      {children}
    </div>
    {closeBtn && <div>
      <TransparentBtn className="-mr-2" onClick={() => setOpen(false)}><XIcon className="w-4 h-4"/></TransparentBtn>
    </div>}
  </div>
}