export default function RightCard ({media, title, children, ...props}) {
  return (
    <div className={`container mx-auto my-5 ${"className" in props ? props.className : ''}`}>
      <div className="rounded-lg flex flex-col md:flex-row items-center md:shadow-xl mx-2 items-stretch md:border md:border-secondary">
        <div className="z-0 order-1 md:order-2 relative w-full md:w-2/5 h-80 md:h-auto md:inset-0 overflow-hidden rounded-lg md:rounded-none md:rounded-r-lg">
          <div
            className="
              absolute inset-0 w-full h-full
              object-cover object-center
              bg-yellow-300 bg-cover bg-bottom bg-blend-multiply
              "
          >
            {media}
          </div>
          <svg className="hidden absolute md:block h-full w-24 fill-current text-white"
               viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon points="0,0 50,0 0,100"/>
          </svg>
        </div>

        <div className="z-10 order-2 md:order-1 w-full h-full md:w-3/5 flex items-center -mt-6 md:mt-0 md:bg-white md:rounded-l-lg">
          <article className="p-8 md:pr-18 md:pl-14 md:py-12 mx-2 md:mx-0 md:min-h-72 h-full bg-white border border-secondary md:border-none rounded-lg md:rounded-none md:rounded-l-lg shadow-xl md:shadow-none">
            <h2>{title}</h2>
            {children}
          </article>
        </div>
      </div>
    </div>
  )
}