import NavLink from "./NavLink";
import {useTranslation} from "react-i18next";

export default function Routes({onClick}) {
  const {t} = useTranslation('routes')
  return (
    <ul className="
        flex flex-col lg:flex-row lg:flex-grow
        justify-center lg:items-center
        space-y-4 lg:space-x-4 lg:space-y-0 pt-4 lg:pt-0
        text-xl
        lg:text-sm
        xl:text-xl
        ">
      <NavLink href={t('home.link')} onClick={onClick}>{t('home.name')}</NavLink>
      <NavLink href={t('power-healing.link')} onClick={onClick}>{t('power-healing.name')}</NavLink>
      <NavLink href={t('geobiologie.link')} onClick={onClick}>{t('geobiologie.name')}</NavLink>
      <NavLink href={t('appointment.link')} onClick={onClick}>{t('appointment.name')}</NavLink>
      <li><a className="text-primary" href="//etickets.infomaniak.com/shop/T4YgrGYzCf/" onClick={onClick}>{t('events.name')}</a></li>
      <NavLink href={t('shop.link')} onClick={onClick}>{t('shop.name')}</NavLink>
    </ul>
  )
}