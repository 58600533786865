import Media from "../components/partners/Media";
import PictureHeader from "../components/common/headers/PictureHeader";
import TextHeader from "../components/common/headers/TextHeader";
import Card from "../components/partners/Card";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {getContents} from "../api/contents";
import LoadingWrapper from "../components/common/placeholders/LoadingWrapper";
import {useToast} from "../providers/ToastProvider";
import HelmetSEO from "../utils/HelmetSEO";

export default function Partners() {
  const {t, i18n} = useTranslation('routes')
  const [partners, setPartners] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const toast = useToast()

  useEffect(() => {
    getContents( 'partners')
      .then(setPartners)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
    //eslint-disable-next-line
  }, [])

  return (
    <div>
      <HelmetSEO page="partners"/>
      <PictureHeader
        className="
        bg-partners
        "
      >
        <TextHeader>{t('partners.name')}</TextHeader>
      </PictureHeader>
      <div className="flex flex-col space-y-12 py-12">
        <LoadingWrapper
          isLoading={isLoading}
        >
          {partners.map((partner, id) => (
            <Card
              key={partner.id}
              direction={id % 2 ? "left" : "right"}
              title={partner.title_translations && partner.title_translations[i18n.language]}
              media={<Media
                link={partner.video_url}
                />
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: partner.text_translations && partner.text_translations[i18n.language],
                }}
              />
            </Card>
          ))}
        </LoadingWrapper>
      </div>
    </div>
  )
}