import {Disclosure} from "@headlessui/react";
import {ChevronRightIcon} from "@heroicons/react/solid";

export default function SwissVedaDisclosure({title, className, children}) {
  return (
    <Disclosure as="div" className={className}>
      {({open}) => (
        <>
          <Disclosure.Button className="flex flex-row justify-between w-full px-4 py-2 text-left text-primary rounded-lg focus:outline-none border-secondary border">
            <span className="flex-grow">{title}</span>
            <div>
              <ChevronRightIcon
                className={`${
                  open ? 'transform rotate-90' : ''
                } w-5 h-5 text-primary`}
              />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm" dangerouslySetInnerHTML={{
            __html: children,
          }}>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}