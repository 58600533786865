export function lang() {
  return localStorage.getItem('i18nextLng') || 'fr'
}

const languages = [
  {id: 'fr', name: 'Français'},
  {id: 'de', name: 'Deutsch'},
  {id: 'en', name: 'English'}
]

export default languages