import {PencilIcon, XCircleIcon} from "@heroicons/react/outline";
import TransparentBtn from "../common/btns/TransparentBtn";
import UserAddressForm from "../../forms/UserAddressForm";
import {useState} from "react";
import Modal from "../common/modals/Modal";
import DeleteDialogModal from "../common/modals/DeleteDialogModal";
import {useTranslation} from "react-i18next";
import * as UserAddressAPI from "../../api/userAddresses"
import {useToast} from "../../providers/ToastProvider";

export default function Address({address, className, updateAddresses, onClick}) {
  const [openModal, setOpenModal] = useState(false)
  const [dialogModal, setDialogModal] = useState(false)
  const {t} = useTranslation('pages')
  const toast = useToast()

  const closeModal = () => {
    setOpenModal(false)
    updateAddresses()
  }
  const deleteAddress = () => {
    UserAddressAPI.destroy(address.id)
      .catch(() => toast.pushUnKnown())
      .then(() => {
        setDialogModal(false)
        updateAddresses()
      })
  }
  return <div className={`
    flex flex-row justify-between
    p-4
    border rounded-lg shadow-md
    ${className || ''}
  `}>
    <div className="flex flex-col flex-grow" onClick={onClick}>
      <p>{address?.firstname} {address?.lastname}</p>
      <p>{address?.street}</p>
      <p>{address?.npa} {address?.city}</p>
    </div>
    <div className="flex flex-col justify-between">
      <TransparentBtn className="flex flex-row justify-end text-red-600" onClick={() => setDialogModal(true)}><XCircleIcon className="h-5 w-5"/></TransparentBtn>
      <DeleteDialogModal
        isOpen={dialogModal}
        closeModal={() => setDialogModal(false)}
        title={t('my-addresses.delete-modal.title')}
        description={t('my-addresses.delete-modal.description')}
        action={deleteAddress}
      />
      <TransparentBtn className="flex flex-row justify-end" onClick={() => setOpenModal(true)}><PencilIcon className="h-5 w-5"/></TransparentBtn>
      <Modal
        title={t('my-addresses.edit-modal.title')}
        isOpen={openModal}
        closeModal={closeModal}
        className="w-full max-w-xl"
      >
        <UserAddressForm values={address} closeModal={closeModal}/>
      </Modal>
    </div>
  </div>
}