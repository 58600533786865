import api from "./api";
import resources from "./resources";
import {lang} from "../languages";

export function index(categoryId) {
  return api().get(`/${lang()}/${resources.article_categories}/${categoryId}/${resources.articles}`)
}

export function show(articleId) {
  return api().get(`/${lang()}/${resources.articles}/${articleId}`)
}

export function store(categoryId, data) {
  return api().post(`/${lang()}/${resources.article_categories}/${categoryId}/${resources.articles}`, data)
}

export function update(articleId, data) {
  return api().put(`/${lang()}/${resources.articles}/${articleId}`, data)
}

export function destroy(articleId) {
  return api().delete(`/${lang()}/${resources.articles}/${articleId}`)
}

export function getTypes() {
  return api().get(`/${lang()}/${resources.article_types}`)
}

export function addPictures(articleId, data) {
  return api().post(`/${lang()}/${resources.articles}/${articleId}/${resources.article_pictures}`, data)
}

export function removePicture(pictureId) {
  return api().delete(`/${lang()}/${resources.article_pictures}/${pictureId}`)
}

export function addPromoAction(articleId, data) {
  return api().post(`/${lang()}/${resources.articles}/${articleId}/${resources.article_promos}`, data)
}

export function updatePromoAction(promoActionId, data) {
  return api().put(`/${lang()}/${resources.article_promos}/${promoActionId}`, data)
}

export function removePromoAction(promoActionId) {
  return api().delete(`/${lang()}/${resources.article_promos}/${promoActionId}`)
}
export function addArticleSku(articleId, data) {
  return api().post(`/${lang()}/${resources.articles_sku}/${articleId}/${resources.articles}`, data)
}

export function updateArticleSku(articleId, skuId, data) {
  return api().put(`/${lang()}/${resources.articles_sku}/${articleId}/${resources.articles}/${skuId}`, data)
}

export function removeArticleSku(articleId, skuId) {
  return api().delete(`/${lang()}/${resources.articles_sku}/${articleId}/${resources.articles}/${skuId}`)
}