import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as PromosAPI from "../api/promos";
import {Form, Formik} from "formik";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import Input from "../components/common/inputs/Input";
import FormSelect from "../components/common/inputs/FormSelect";
import Price from "../components/common/Price";
import {getFormattedDate} from "../utils/dates";
import QrScanInput from "../components/common/inputs/QrScanInput";
import Spinner from "../components/common/Spinner";

function PromoInfo({data}) {
  const {t, i18n} = useTranslation('pages')
  return <dl>
    <dt>{t('promo-code-check.until')}</dt>
    <dd>{getFormattedDate(data.until, i18n.language)}</dd>
    <dt>{t('promo-code-check.remaining-uses')}</dt>
    <dd>{data.remaining_uses}</dd>
    <dt>{t('promo-code-check.amount')}</dt>
    <dd>{data.amount}</dd>
    {data.price && <>
      <dt>{t('promo-code-check.price')}</dt>
      <dd><Price>{data.price}</Price></dd>
      <dt>{t('promo-code-check.total-price')}</dt>
      <dd><Price>{data.total_price}</Price></dd>
    </>}
  </dl>
}

export default function PromoCheckForm({useForm}) {
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [otherError, setOtherError] = useState("")
  const [showResponse, setShowResponse] = useState(false)
  const [response, setResponse] = useState({})
  const {t} = useTranslation(['fields', 'forms', 'pages'])

  const [promoContexts, setPromoContexts] = useState([])
  const defaultValues = {
    id: "",
    price: "",
    promo_context_id: "",
    email: ""
  }
  const reset = () => {
    setShowGlobalError(false)
    setShowResponse(false)
    setErrors({})
    setOtherError("")
  }

  const create = (data, {resetForm}) => {
    reset()
    return (useForm ? PromosAPI.usePromo : PromosAPI.checkPromo)(data.id, {
        ...data,
        price: data.price || undefined,
        email: data.email || undefined
      })
      .then(data => {
        setResponse(data)
        setShowResponse(true)
        resetForm(defaultValues)
      })
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      if (error.response.status === 404) {
        setOtherError(t('pages:promo-code-check.not-exist'))
      } else {
        setShowGlobalError(true)
      }
    } else {
      if (error.response.data.errors)
        setErrors(error.response.data.errors)
      else
        setOtherError(error.response.data.error)
    }
  }

  useEffect(() => {
    PromosAPI.getContextes()
      .then(setPromoContexts)
  }, [])

  return <Formik
    initialValues={defaultValues}
    enableReinitialize
    onSubmit={create}
  >
    {({values, isSubmitting}) => (
      <Form>
        <div className="flex flex-col space-y-4">
          {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
          {(otherError || errors.context) && <div className="text-red-600 text-sm">{otherError || errors.context}</div> }
          {errors.uses && <div className="text-red-600">{errors.uses}</div>}
          <div className="grid gap-y-4 md:gap-x-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
            <QrScanInput
              label={t('promo-code')}
              placeholder={t('promo-code')}
              name="id"
              required
            />
            <FormSelect
              label={t('promo-context')}
              name="promo_context_id"
              error={errors.promo_context_id}
              required
              info={promoContexts.find(context => context.id === values.promo_context_id)?.description}
            >
              <option>---</option>
              {promoContexts.map((context, id) => <option key={id} value={context.id}>{context.name}</option> )}
            </FormSelect>
            <Input
              label={t('email')}
              placeholder={t('email')}
              name="email"
              error={errors.email}
            />
            {useForm && <Input
              label={t('price')}
              placeholder={t('price')}
              name="price"
              error={errors.price}
            />}
          </div>
          {showResponse && <PromoInfo data={response}/>}
          <div className="sm:text-center">
            <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : t('forms:save')}</PrimaryBtn>
          </div>
        </div>
      </Form>
    )}
  </Formik>
}