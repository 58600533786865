import Section from "../Section";
import {useTranslation} from "react-i18next";
const cards = [
  'task-categories',
  'practitioners',
  'addresses',
]
export default function CalendarAdminSection() {
  const {t} = useTranslation('auth')
  return <Section title={t('roles.calendar-admin')} cards={cards}/>
}