import {useState} from "react";

export default function ImgSlider({pictures}) {
  const [currentPic, setCurrentPic] = useState(0)

  const handleChangePic = newId => {
    setCurrentPic(newId)
  }
  return <div className="w-full h-full">
    <div className="rounded-lg relative w-full h-96 overflow-hidden">
      {pictures.map((pic, id) => <div key={id} className={`
        absolute inset-0
        flex items-center justify-center
        transition-all ease-in-out duration-1000 transform ${currentPic === id ? 'translate-x-0' : id > currentPic ? 'translate-x-full' : '-translate-x-full'} slide
      `}>
        <img className="max-w-full max-h-full" src={pic} alt=""/>
      </div>)}
    </div>
    <div className="mt-2 flex space-x-2 overflow-auto h-20 border-t border-secondary pt-2">
      {pictures.map((pic, id) => <img key={id} className="cursor-pointer max-h-full max-w-xs w-auto" src={pic} alt="" onClick={() => handleChangePic(id)}/>)}
    </div>
  </div>
}