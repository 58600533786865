import Card from "../common/Card";
import {momentWithLocales} from "../../utils/dates";
import {useTranslation} from "react-i18next";
import TransparentBtn from "../common/btns/TransparentBtn";
import {PencilIcon, XCircleIcon} from "@heroicons/react/outline";
import DeleteDialogModal from "../common/modals/DeleteDialogModal";
import Modal from "../common/modals/Modal";
import {useState} from "react";
import DayOffForm from "../../forms/DayOffForm";
import {destroy} from "../../api/daysOff";

export default function DayOff({date, onChange}) {
  const [openModal, setOpenModal] = useState(false)
  const [dialogModal, setDialogModal] = useState(false)
  const {i18n, t} = useTranslation(['pages'])

  const closeModal = () => {
    setOpenModal(false)
    setDialogModal(false)
    onChange()
  }
  const deleteAbsence = () => {
    destroy(date.id)
      .then(closeModal)
  }

  return <Card className="max-w-3xl flex flex-row space-x-2">
    <div className="flex flex-col flex-grow">
      <div className="flex flex-col sm:flex-row sm:space-x-2 font-bold mb-2">
        <p>{t('days-off.from')} {momentWithLocales(date.start).locale(i18n.language).format('LLL')}</p>
        <p>{t('days-off.to')} {momentWithLocales(date.end).locale(i18n.language).format('LLL')}</p>
      </div>
      <p>{date.description}</p>
    </div>
    <div className="flex flex-row space-x-2 justify-between">
      <TransparentBtn className="flex flex-row justify-end" onClick={() => setOpenModal(true)}><PencilIcon className="h-5 w-5"/></TransparentBtn>
      <TransparentBtn className="flex flex-row justify-end text-red-600" onClick={() => setDialogModal(true)}><XCircleIcon className="h-5 w-5"/></TransparentBtn>
    </div>
    <DeleteDialogModal
      isOpen={dialogModal}
      closeModal={() => setDialogModal(false)}
      title={t('days-off.delete.title')}
      description={t('days-off.delete.description')}
      action={deleteAbsence}
    />
    <Modal
      title={t('days-off.edit')}
      isOpen={openModal}
      closeModal={closeModal}
      className="w-full max-w-xl"
    >
      <DayOffForm day={{
        ...date,
        start: momentWithLocales(date.start),
        end: momentWithLocales(date.end)
      }} closeModal={closeModal}/>
    </Modal>
  </Card>
}