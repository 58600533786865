import api from "./api";
import {lang} from "../languages";
import resources from "./resources";

export function getContextes() {
  return api().get(`/${lang()}/${resources.promo_contexts}`)
}

export function getTypes() {
  return api().get(`/${lang()}/${resources.promo_types}`)
}

export function usePromo(id, data) {
  return api().post(`/${lang()}/${resources.promo}/${id}/use-promo`, data)
}

export function checkPromo(id, data) {
  return api().post(`/${lang()}/${resources.promo}/${id}/check-validity`, data)
}

export function index() {
  return api().get(`/${lang()}/${resources.promo}`)
}

export function destroy(id) {
  return api().delete(`/${lang()}/${resources.promo}/${id}`)
}

export function store(data) {
  return api().post(`/${lang()}/${resources.promo}`, data)
}