import Card from "../../common/Card";
import TransparentBtn from "../../common/btns/TransparentBtn";
import {PencilIcon, TrashIcon} from "@heroicons/react/outline";
import {useState} from "react";
import Modal from "../../common/modals/Modal";
import {useTranslation} from "react-i18next";
import DeleteDialogModal from "../../common/modals/DeleteDialogModal";
import * as CategoryAPI from "../../../api/articleCategories"
import ArticleCategoryForm from "../../../forms/ArticleCategoryForm";
import {useToast} from "../../../providers/ToastProvider";

export default function ArticleCategory({name, id, children, update, ...props}) {
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const {t} = useTranslation('translation')
  const toast = useToast()

  const closeModal = () => {
    setEditModal(false)
    setDeleteModal(false)
    update()
  }
  const handleDelete = () => {
    return CategoryAPI.destroy(id)
      .then(closeModal)
      .catch(() => toast.pushUnKnown())
  }
  return <Card className="max-w-4xl">
    <b className="text-2xl border-b-2 border-secondary">{name} <TransparentBtn onClick={() => setEditModal(true)}><PencilIcon className="w-4 h-4"/></TransparentBtn> <TransparentBtn onClick={() => setDeleteModal(true)} className="text-red-600"><TrashIcon className="w-4 h-4"/> </TransparentBtn></b>
    <div className="mt-4 flex flex-col divide-y divide-secondary">
      {children}
    </div>
    <DeleteDialogModal
      title={t('pages:articles.delete-category.title')}
      description={t('pages:articles.delete-category.description', {category: name})}
      isOpen={deleteModal}
      closeModal={() => setDeleteModal(false)}
      action={handleDelete}
    />
    <Modal
      isOpen={editModal}
      closeModal={closeModal}
      title={t('edit')}
    >
      <ArticleCategoryForm
        category={{
          id: id,
          name: props.name_translations
        }}
        closeModal={closeModal}
      />
    </Modal>
  </Card>
}