export default function Answers({exercice}) {
  return <div className="flex flex-col space-y-6 mt-4">
    {exercice.questions.map(question => <div key={question.id}>
      <div className="content text-2xl font-semibold" dangerouslySetInnerHTML={{__html: question.translated_text}}/>
      <div className="mt-2 ml-3 divide-y divide-secondary">
        {question.answers.map(answer => <div key={answer.id}>
          <div className="my-1">
            <b>{answer.user.name} {answer.user.lastname}:</b>&nbsp;{answer.text}
          </div>
        </div>)}
      </div>
    </div>)}
  </div>
}