import PrimaryBtn from "../common/btns/solid/PrimaryBtn";
import {useState} from "react";
import OrderModal from "../user/OrderModal";
import ActionBtn from "./ActionBtn";
import {useTranslation} from "react-i18next";
import {orderStatus} from "../../utils/orders";
import OutlinePrimaryBtn from "../common/btns/outline/OutlinePrimaryBtn";
import {PrinterIcon} from "@heroicons/react/outline";

function Article({article}) {
  return <div key={article.id} className="flex space-x-1">
    <div><b>{article.quantity}x</b></div>
    <div>{article.formatted_name}</div>
  </div>
}

export default function Order({order, update}) {
  const [orderModal, setOrderModal] = useState(false)
  const {t} = useTranslation(['translation', 'pages'])

  return <div className="pt-4 pb-2 px-2 flex space-x-2">
    <div className="min-w-max">N° {order.id}</div>
    <div className="flex flex-col flex-grow">
      {[orderStatus.send_back, orderStatus.send_back_received].includes(order.order_status?.id) ?
        order.returned_articles?.map(article => <Article key={article.id} article={article}/>)
        :
        order.articles?.map(article => <Article key={article.id} article={article}/>)
      }
    </div>
    <div className="flex flex-col space-y-1">
      <PrimaryBtn onClick={() => setOrderModal(true)}>{t('see')}</PrimaryBtn>
      {[orderStatus.delivered, orderStatus.ready_to_deliver].includes(order.order_status?.id) && order.label && <a href={order.label} target="_blank" rel="noreferrer" className="text-current hover:no-underline"><OutlinePrimaryBtn className="flex"><PrinterIcon className="w-5 h-5 mr-1"/>{t('pages:orders.poste-label')}</OutlinePrimaryBtn></a>}
      {order.available_actions?.editor?.map((action, id) => <ActionBtn key={id} action={action} order={order} update={update}/>)}
    </div>
    <OrderModal
      order={order}
      closeModal={() => setOrderModal(false)}
      isOpen={orderModal}
    />
  </div>
}