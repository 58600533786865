import {useTranslation} from "react-i18next";

export default function ContactCard({logo, link, linkName, children}) {
  const {t} = useTranslation()
  return (
    <div className="
      w-full max-w-md
      bg-white
      flex flex-col
      rounded-xl
      border border-secondary
      shadow-xl
    ">
      <div className="flex flex-col items-center pb-4 px-4">
        <div className="
          max-w-xs h-28
          -mt-12 p-4
          flex items-center
          rounded-full border border-primary
          bg-white
        ">
          <img src={logo} alt="Logo"/>
        </div>
        <div className="text-center mt-5">
          {children}
        </div>
      </div>
      <div className="w-full h-10 bg-primary rounded-b-xl flex justify-center items-center">
        <a href={link} className="text-white w-full h-full hover:text-white hover:no-underline flex items-center justify-center">{t('contact')}</a>
      </div>
    </div>
  )
}