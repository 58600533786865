import {useState} from "react";
import {useTranslation} from "react-i18next";
import * as CategoryAPI from "../api/articleCategories";
import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import languages from "../languages";
import {convertDotDataToJson} from "../utils/formConverter";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import Spinner from "../components/common/Spinner";

const defaultValues = {
  name: {
    fr: "",
    en: "",
    de: "",
  }
}
export default function ArticleCategoryForm({isCreating, category, closeModal}) {
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [creating, setCreating] = useState(false)
  const {t} = useTranslation(['fields', 'errors'])

  const create = data => {
    setShowGlobalError(false)
    setErrors({})
    setCreating(true)
    return CategoryAPI.store(convertDotDataToJson(data))
      .then(() => closeModal())
      .catch(handleError)
      .then(() => setCreating(false))
  }
  const update = data => {
    setShowGlobalError(false)
    setErrors({})
    return CategoryAPI.update(category.id, convertDotDataToJson(data))
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }


  return <Formik
    initialValues={category || defaultValues}
    enableReinitialize
    onSubmit={create}
  >
    <Form>
      {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-2 mt-2 mx-4">
        {languages.map((lang, id) => <Input
          key={id}
          name={`name.${lang.id}`}
          label={t('lang_name', {lang: lang.id})}
          placeholder={t('lang_name', {lang: lang.id})}
          async={!isCreating ? update : false}
          error={errors[`name.${lang.id}`]}
          required
        />)}
      </div>
      <div className="mt-4 flex justify-center">
        {isCreating && <PrimaryBtn
          type="submit"
          className="flex flex-row"
          disabled={creating}
        >
          {creating && <Spinner className="w-5 h-5 mr-2"/>} {t('forms:save')}
        </PrimaryBtn>}
      </div>
    </Form>
  </Formik>
}