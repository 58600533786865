import {momentWithLocales as moment} from "../../utils/dates";
import {useTranslation} from "react-i18next";
import Card from "../common/Card";
import RowArticle from "./RowArticle";
import {useState} from "react";
import OrderModal from "./OrderModal";
import Price from "../common/Price";
import PrimaryBtn from "../common/btns/solid/PrimaryBtn";
import ActionBtn from "../order/ActionBtn";

export default function Order({order, update, ...props}) {
  const {t, i18n} = useTranslation(['pages', 'translation'])
  const [openModal, setOpenModal] = useState(false)
  return <>
      <Card className="max-w-2xl">
      <h3>{t('my-orders.order-title')} {order.id}</h3>
      <div className="text-sm text-primary">{moment(order.date).locale(i18n.language).format('LL')}</div>
      <div className="flex flex-col md:flex-row justify-start md:space-x-8 text-sm">
        <div>{t('orders.status')}: <span className="text-secondary">{order.order_status?.name}</span></div>
        <div>{t('orders.total')}: <b className="text-primary"><Price>{order.total}</Price></b></div>
      </div>
      <hr className="my-3 border-secondary"/>
      <div className="flex flex-col space-y-2">
        {order.articles?.map(article => <RowArticle key={article.id} article={article}/>)}
      </div>
      <div className="flex space-x-2 mt-4">
        <PrimaryBtn onClick={() => setOpenModal(true)}>{t('translation:see')}</PrimaryBtn>
        {order.available_actions?.client?.map((action, id) => <ActionBtn key={id} action={action} order={order} update={update}/>)}
      </div>
    </Card>
    <OrderModal
      order={order}
      closeModal={() => setOpenModal(false)}
      isOpen={openModal}
    />
  </>
}