import TransparentBtn from "../common/btns/TransparentBtn";
import {PencilIcon, XCircleIcon} from "@heroicons/react/outline";
import Modal from "../common/modals/Modal";
import DeleteDialogModal from "../common/modals/DeleteDialogModal";
import Card from "../common/Card";
import AddressForm from "../../forms/AddressForm";
import {useState} from "react";
import {destroy} from "../../api/addresses";
import {useTranslation} from "react-i18next";

export default function Address({address, update}){
  const {t} = useTranslation('pages')
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const closeModal = () => {
    setEditModal(false)
    setDeleteModal(false)
    update()
  }

  const deletePractitioners = () => {
    destroy(address.id)
      .then(update)
  }
  return <Card className="w-full max-w-xl flex flex-col">
    <div className="flex flex-row items-center justify-start space-x-2">
      <div className="flex flex-col space-y-2">
        <p>{address.street}</p>
        <p>{address.npa} {address.city}</p>
      </div>
      <div className="flex-grow flex flex-row justify-end self-start">
        <TransparentBtn onClick={() => setEditModal(true)}><PencilIcon className="w-5 h-5"/></TransparentBtn>
        <Modal
          title={t('addresses.edit')}
          isOpen={editModal}
          closeModal={closeModal}
          className="w-full max-w-xl"
        >
          <AddressForm address={address} closeModal={closeModal}/>
        </Modal>
        <TransparentBtn className="flex flex-row justify-end text-red-600" onClick={() => setDeleteModal(true)}><XCircleIcon className="h-5 w-5"/></TransparentBtn>
        <DeleteDialogModal
          isOpen={deleteModal}
          closeModal={() => setDeleteModal(false)}
          title={t('addresses.delete.title', {name: address.city})}
          description={t('addresses.delete.description')}
          action={deletePractitioners}
        />
      </div>
    </div>
  </Card>
}