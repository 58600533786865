import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as ExerciceAPI from "../api/exercices";
import {Form, Formik, Field} from "formik";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import Spinner from "../components/common/Spinner";
import Info from "../components/common/inputs/Info";

export default function ExerciceAnswerForm({answer, question}) {
  const defaultValues = {
    text: ""
  }

  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [localAnswer, setLocalAnswer] = useState({})
  const {t} = useTranslation(['fields', 'forms'])

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
  }

  const create = (data) => {
    reset()
    return (localAnswer ? ExerciceAPI.updateAnswer(localAnswer.id, data) : ExerciceAPI.storeAnswer(question.id, data))
      .then(setLocalAnswer)
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  useEffect(() => {
    setLocalAnswer(answer)
  }, [answer])

  return <Formik
    initialValues={answer || defaultValues}
    enableReinitialize
    onSubmit={create}
  >
    {({isSubmitting}) => (
      <Form>
        {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
        <div className="flex flex-col space-y-4">
          <div className="w-full flex flex-col mt-2">
            <Field as="textarea" name="text" className="rounded-lg border-primary focus:ring-secondary focus:border-secondary min-h-48" required/>
            <Info error={errors.text}/>
          </div>
          <div className="sm:col-start-2 sm:text-center">
            <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : t('forms:save')}</PrimaryBtn>
          </div>
        </div>
      </Form>
    )}
  </Formik>
}