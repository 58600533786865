import api from "./api";
import {lang} from "../languages";
import resources from "./resources";
import {formatDbDate} from "../utils/dates";

export function index(start, end) {
  return api().get(`/${lang()}/${resources.schedules}?start=${formatDbDate(start)}&end=${formatDbDate(end)}`)
}

export function show(id) {
  return api().get(`/${lang()}/${resources.schedules}/${id}`)
}

export function store(data) {
  return api().post(`/${lang()}/${resources.schedules}`, data)
}

export function update(id, data) {
  return api().put(`/${lang()}/${resources.schedules}/${id}`, data)
}

export function destroy(id) {
  return api().delete(`/${lang()}/${resources.schedules}/${id}`)
}

export function occupedSlots(start, end, practitionerId) {
  return api().get(`/${lang()}/${resources.schedules}/${resources.occuped_slots}?start=${formatDbDate(start)}&end=${formatDbDate(end)}&practitioner_id=${practitionerId}`)
}

export function calendarEvents(start, end) {
  return api().get(`/${lang()}/admin/calendar-events?start=${formatDbDate(start)}&end=${formatDbDate(end)}`)
}

export function showPublic(id) {
  return api().get(`/${lang()}/${resources.schedules_public}/${id}`)
}

export function destroyPublic(id, data) {
  return api().delete(`/${lang()}/${resources.schedules_public}/${id}`, {data})
}

export function updatePublic(id, data) {
  return api().put(`/${lang()}/${resources.schedules_public}/${id}`, data)
}

export function getDisponibilities(start, end, appointmentId){
  return api().get(`/${lang()}/${resources.schedules}/${appointmentId}/available-times?start=${start}&end=${end}`)
}