import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useToast} from "../../providers/ToastProvider";
import Card from "../common/Card";
import PrimaryBtn from "../common/btns/solid/PrimaryBtn";
import OutlinePrimaryBtn from "../common/btns/outline/OutlinePrimaryBtn";
import SecondaryBtn from "../common/btns/solid/SecondaryBtn";
import TransparentBtn from "../common/btns/TransparentBtn";
import {PencilIcon, XCircleIcon} from "@heroicons/react/outline";
import DeleteDialogModal from "../common/modals/DeleteDialogModal";
import * as ExerciceAPI from "../../api/exercices";
import Modal from "../common/modals/Modal";
import ExerciceForm from "../../forms/ExerciceForm";
import Answers from "./Answers";
import Participants from "./Participants";
import Questions from "./Questions";
import DangerBtn from "../common/btns/solid/DangerBtn";
import OutlineSecondaryBtn from "../common/btns/outline/OutlineSecondaryBtn";

export default function Exercice ({exercice, update}) {
  const [updateExercice, setUpdateExercice] = useState(false)
  const [deleteExercice, setDeleteExercice] = useState(false)
  const [resetExercice, setResetExercice] = useState(false)
  const [sendExercice, setSendExercice] = useState(false)
  const [showAnswers, setShowAnswers] = useState(false)
  const [showParticipants, setShowParticipants] = useState(false)
  const [showQuestions, setShowQuestions] = useState(false)
  const {t} = useTranslation(['pages'])
  const toast = useToast()

  const closeAndUpdate = () => {
    setUpdateExercice(false)
    setDeleteExercice(false)
    setShowQuestions(false)
    setShowParticipants(false)
    setResetExercice(false)
    setSendExercice(false)
    setShowAnswers(false)
    update()
  }
  return <Card>
    <div className="flex justify-between">
      <div>
        <h4>{exercice.translated_title}</h4>
        <div className="content" dangerouslySetInnerHTML={{__html: exercice.translated_description}}/>
        <div className="mt-3">
          <PrimaryBtn className="mr-2 mb-2" onClick={() => setShowQuestions(true)}>{t('exercices.manage-questions')}</PrimaryBtn>
          <Modal isOpen={showQuestions} closeModal={closeAndUpdate} title={t('exercices.manage-questions')} className="w-full max-w-2xl">
            <Questions exercice={exercice}/>
          </Modal>
          <OutlinePrimaryBtn className="mr-2 mb-2" onClick={() => setShowParticipants(true)}>{t('exercices.manage-participants')}</OutlinePrimaryBtn>
          <Modal isOpen={showParticipants} closeModal={closeAndUpdate} title={t('exercices.manage-participants')} className="w-full max-w-2xl">
            <Participants exercice={exercice}/>
          </Modal>
          <OutlineSecondaryBtn className="mr-2 mb-2" onClick={() => setShowAnswers(true)}>{t('exercices.show-answers')}</OutlineSecondaryBtn>
          <Modal isOpen={showAnswers} closeModal={() => setShowAnswers(false)} title={t('exercices.show-answers')} className="w-full max-w-2xl">
            <Answers exercice={exercice}/>
          </Modal>
          {exercice.is_send ? <>
            <DangerBtn onClick={() => setResetExercice(true)}>{t('exercices.reset.title')}</DangerBtn>
            <DeleteDialogModal
              isOpen={resetExercice}
              closeModal={() => setResetExercice(false)}
              title={t('exercices.reset.title')}
              description={t('exercices.reset.description')}
              action={() => {
                return ExerciceAPI.resetExercice(exercice.id)
                  .then(closeAndUpdate)
                  .catch(() => toast.pushUnKnown())
              }}
            />
          </> : <>
            <SecondaryBtn onClick={() => setSendExercice(true)}>{t('exercices.send.title')}</SecondaryBtn>
            <DeleteDialogModal
              isOpen={sendExercice}
              closeModal={() => setSendExercice(false)}
              title={t('exercices.send.title')}
              description={t('exercices.send.description')}
              action={() => {
                return ExerciceAPI.sendExercice(exercice.id)
                  .then(closeAndUpdate)
                  .catch(() => toast.pushUnKnown())
              }}
            />
          </>}
        </div>
      </div>
      <div className="flex justify-between">
        <TransparentBtn className="flex flex-row mr-3" onClick={() => setUpdateExercice(true)}><PencilIcon className="h-5 w-5"/></TransparentBtn>
        <TransparentBtn className="flex flex-row text-red-600" onClick={() => setDeleteExercice(true)}><XCircleIcon className="h-5 w-5"/></TransparentBtn>
        <DeleteDialogModal
          isOpen={deleteExercice}
          closeModal={() => setDeleteExercice(false)}
          title={t('exercices.delete.title')}
          description={t('exercices.delete.description')}
          action={() => {
            ExerciceAPI.destroy(exercice.id)
              .then(closeAndUpdate)
              .catch(() => toast.pushUnKnown())
          }}
        />
        <Modal isOpen={updateExercice} closeModal={closeAndUpdate}>
          <ExerciceForm exercice={exercice}/>
        </Modal>
      </div>
    </div>
  </Card>
}