import BackOffice from "../../../../components/common/Layout/BackOffice";
import {useCallback, useEffect, useState} from "react";
import {index} from "../../../../api/workinHours";
import {useTranslation} from "react-i18next";
import WeekDay from "../../../../components/workingHours/WeekDay";
import WorkingDay from "../../../../components/workingHours/WorkingDay";
import {getFormattedTime} from "../../../../utils/dates";
import {useToast} from "../../../../providers/ToastProvider";

export default function WorkingHours() {
  const [isLoading, setIsLoading] = useState(true)
  const [workingHours, setWorkingHours] = useState([])
  const days = [1, 2, 3, 4, 5, 6, 0]

  const {t, i18n} = useTranslation('routes')
  const toast = useToast()

  const update = useCallback(() => {
    index()
      .then(whs => whs.map(wh => ({
        ...wh,
        start: getFormattedTime(wh.start, i18n.language),
        end: getFormattedTime(wh.end, i18n.language),
        address_id: wh.address.id
      })))
      .then(setWorkingHours)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
    //eslint-disable-next-line
  }, [i18n.language])

  useEffect(() => {
    update()
  }, [update])

  return <BackOffice role="calendar" isLoading={isLoading}>
    <h1 className="mb-4">{t('working-hours.name')}</h1>
    <div className="flex flex-col space-y-4">
      {days.map(i => <WeekDay key={i} isoDay={i}>
        <WorkingDay day={i} onChange={update} workingHours={workingHours.filter(wh => wh.day === i.toString())}/>
      </WeekDay>)}
    </div>
  </BackOffice>
}