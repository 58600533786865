export function convertDotDataToJson(data) {
  var newData = {}
  for (const key in data) {
    const [newKey, subKey] = key.split('.')
    if (newKey in newData) {
      newData[newKey][subKey] = data[key]
    } else {
      if (subKey) {
        newData[newKey] = {
          [subKey]: data[key]
        }
      } else {
        newData[newKey] = data[key]
      }
    }
  }
  return newData
}