import SolidBtn from "./SolidBtn";

export default function SecondaryBtn({className, children, ...props}) {
  return <SolidBtn className={`
    bg-secondary
    ${className || ''}
  `}
    {...props}
  >
    {children}
  </SolidBtn>
}