import Calendar from "../components/schedules/Calendar";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import * as ScheduleAPI from "../api/schedules"
import {momentWithLocales} from "../utils/dates";
import DangerBtn from "../components/common/btns/solid/DangerBtn";
import Modal from "../components/common/modals/Modal";
import CancelAppointement from "../forms/CancelAppointement";
import Error404 from "./errors/Error404";
import LoadingWrapper from "../components/common/placeholders/LoadingWrapper";
import Alert from "../components/common/Alert";
import {useToast} from "../providers/ToastProvider";
import {useHistory} from "react-router-dom";
import Recap from "../components/schedules/Recap";

export default function EditSchedule() {
  const [appointment, setAppointment] = useState({})
  const [showModal, setShowModal] = useState(false)
  const {appointmentId} = useParams()
  const [isLoading, setIsLoading] = useState(true)

  const toast = useToast()
  const history = useHistory()
  const {t} = useTranslation(['routes', 'pages', 'fields'])

  const handleClose = () => {
    setShowModal(false)
    toast.pushSuccess(t("pages:appointment.cancel.success"))
    history.push("/")
  }

  const more_than_24h = () => momentWithLocales(appointment.start).diff(momentWithLocales(), 'h') > 24
  const can_delete = () => appointment.can_delete && more_than_24h()
  const can_edit = () => appointment.can_edit && more_than_24h()

  const update = useCallback(() => {
    ScheduleAPI.showPublic(appointmentId)
      .then(setAppointment)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
    //eslint-disable-next-line
  }, [appointmentId])

  useEffect(() => {
    update()
  }, [update])

  return <LoadingWrapper isLoading={isLoading}>
    {Object.keys(appointment).length === 0 ? <Error404/> :
      <div className="pt-32">
        <div className="p-8 mt-8">
          <h1 className="mb-8 sm:text-center">{t('edit-appointment.name')}</h1>
          <div className={`grid ${can_edit() ? 'md:grid-cols-2' : ''} md:grid-row-2 gap-4 mt-14`}>
            <div className="flex flex-col justify-end md:items-center">
              <div className="md:max-w-lg">
                <Recap
                  users={appointment.users?.map((user) => `${user.name} `)}
                  start={appointment.start}
                  task={appointment.task}
                  address={appointment.address}
                  name={`${appointment.firstname} ${appointment.lastname}`}
                />
                {!can_edit() && <Alert color="secondary">
                  {!more_than_24h() ? t('pages:appointment.cannot-edit.time') : t('pages:appointment.cannot-edit.default')}
                </Alert>}
              </div>
            </div>
            {can_edit() && <div className="max-w-xl row-span-2">
              <Calendar appointmentId={appointmentId} update={update}/>
            </div>}
            {can_delete() && <div className="flex justify-center col-start-1">
              <div>
                <DangerBtn onClick={() => setShowModal(true)}>{t('pages:appointment.cancel.title')}</DangerBtn>
              </div>
              <Modal
                isOpen={showModal}
                closeModal={() => {
                  setShowModal(false)
                }}
                title={t('pages:appointment.cancel.title')}
                className="w-full max-w-lg"
              >
                <CancelAppointement appointmentId={appointmentId} closeModal={handleClose}/>
              </Modal>
            </div>}
          </div>
        </div>
      </div>
    }
  </LoadingWrapper>
}