import FullCalendar from "@fullcalendar/react";
import timeGrid from "@fullcalendar/timegrid"
import listView from "@fullcalendar/list"
import interactionPlugin from "@fullcalendar/interaction"
import languages from "../../languages";
import {useTranslation} from "react-i18next";
import {Suspense, useRef, useState} from "react";
import * as scheduleAPI from "../../api/schedules"
import Modal from "../common/modals/Modal";
import DeleteDialogModal from "../common/modals/DeleteDialogModal";
import {confirmAlert} from "react-confirm-alert";
import {formatDbDateTime} from "../../utils/dates";
import LoadingPlaceholder from "../common/placeholders/LoadingPlaceholder";
import ScheduleForm from "../../forms/ScheduleForm";
import ScheduleEditForm from "../../forms/ScheduleEditForm";
import {useToast} from "../../providers/ToastProvider";

export default function FCalendar({businessHours, getEvents, ...props}){
  const {i18n, t} =  useTranslation(['pages'])
  const [createModal, setCreateModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [schedule, setSchedule] = useState({})
  const fc = useRef(null)
  const toast = useToast()

  const closeModals = () => {
    setCreateModal(false)
    setEditModal(false)
    fc.current.getApi().refetchEvents()
  }

  const handleSelect = ({start, end}) => {
    setSchedule({start: formatDbDateTime(start), end: formatDbDateTime(end), id: -1})
    setCreateModal(true)
  }
  const handleClick = ({event}) => {
    scheduleAPI.show(event.id)
      .then(sched => {
        setSchedule({
          ...sched,
          start: formatDbDateTime(sched.start),
          end: formatDbDateTime(sched.end)
        })
        setEditModal(true)
      })
      .catch(() => toast.pushUnKnown())
  }

  const handleDrop = ({event, revert}) => {
    confirmAlert({
      customUI: ({ onClose }) => <Suspense fallback={<LoadingPlaceholder/>}><DeleteDialogModal
        isOpen={true}
        closeModal={() => {
          revert()
          onClose()
        }}
        title={t('calendar.move.title')}
        description={t('calendar.move.description')}
        action={() => {
          scheduleAPI.update(event.id, {
            start: formatDbDateTime(event.start),
            end: formatDbDateTime(event.end)
          })
            .catch(revert)
            .then(onClose)
        }}
      /></Suspense>
    })
  }

  return <div className="min-h-screen">
    <FullCalendar
      ref={fc}
      plugins={[
        timeGrid,
        listView,
        interactionPlugin
      ]}
      locales={languages.map(lang => lang.id)}
      locale={i18n.language}
      initialView='timeGridWeek'
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'listWeek,timeGridDay,timeGridWeek'
      }}
      nowIndicator={true}
      businessHours={businessHours}
      height='100%'
      expandRows={true}
      scrollTime="08:00:00"
      firstDay={1}
      selectable={true}
      selectMirror={true}
      select={handleSelect}
      eventClick={handleClick}
      editable={true}
      eventDurationEditable={false}
      eventDrop={handleDrop}
      events={getEvents}
      {...props}
    />
    <Modal
      isOpen={createModal}
      closeModal={closeModals}
      title={t('calendar.create')}
      className="w-full max-w-3xl"
    >
      <ScheduleForm
        isCreating
        schedule={schedule}
        closeModal={closeModals}
      />
    </Modal>
    <Modal
      isOpen={editModal}
      closeModal={closeModals}
      title={t('calendar.edit')}
      className="w-full max-w-3xl"
    >
      <ScheduleEditForm
        schedule={schedule}
        closeModal={closeModals}
      />
    </Modal>
  </div>
}