import {ChevronDoubleDownIcon} from "@heroicons/react/outline";

export default function PictureHeader(props) {
  return (
    <div className={`
      relative flex items-center justify-center
      h-screen
      overflow-hidden
      bg-cover bg-center
      ` + props.className}>
      <div
        className="
          relative z-10
          w-3/4 sm:w-1/2 md:w-2/5 lg:w-1/3
          "
      >
        {props.children}
      </div>
      <div
        className="
          justify-self-center
          absolute z-10
          w-10
          h-10
          bottom-16
          text-white
        "
      >
        <ChevronDoubleDownIcon
          className="animate-bounce"
        />
      </div>
    </div>
  )
}