export function groupBy(array, key, subKey) {
  return array.reduce((acc, obj) => {
    const property = subKey ? obj[key][subKey] : obj[key];
    acc[property] = acc[property] || [];
    acc[property].push(obj);
    return acc;
  }, {});
}

/**
 *
 * @param array
 * @param key nom de la propriété a grouper
 * @param fnc fonction qui prend en entrée la valeur de key et qui retourne la valeur a utiliser pour grouper
 * @returns {*}
 */
export function groupByFnc(array, key, fnc) {
  return array.reduce((acc, obj) => {
    const property = fnc(obj[key])
    if (!(property in acc)) acc[property] = []
    acc[property].push(obj)
    return acc
  }, {})
}