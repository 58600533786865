import CheckoutTabsNav from "./CheckoutTabsNav";
import {useState} from "react";
import React from "react";
import OutlinePrimaryBtn from "../btns/outline/OutlinePrimaryBtn";
import {useTranslation} from "react-i18next";
import {ArrowLeftIcon} from "@heroicons/react/outline";

function Tab ({isCurrent, children}) {
  return <div className={isCurrent ? '' : 'hidden'}>
    {children}
  </div>
}

export default function CheckoutTabs({elements = [{name: '', icon: null}], children, ...props}) {
  const [minIndex, setMinIndex] = useState(0)
  const [selected, setSelected] = useState(0)
  const {t} = useTranslation(['translation'])

  const nextStep = () => {
    let tmp = selected + 1
    setSelected(tmp)
    setMinIndex(prev => tmp > prev ? prev + 1 : prev)
  }

  const previousStep = () => {
    setSelected(prev => prev - 1)
  }

  return <div className="mt-32 p-2 sm:py-10">
    <div className="p-5">
      <div className="-ml-2 sm:ml-0 p-4 w-full">
        <CheckoutTabsNav elements={elements} selected={selected} minIndex={minIndex}/>
      </div>
      <div className="mt-8 p-4">
        {Array.isArray(children) ?
          children.map((child, id) => <Tab key={id} isCurrent={id === selected}>
            {id > 0 && <OutlinePrimaryBtn className="mb-4 flex flex-row" onClick={previousStep}><ArrowLeftIcon className="w-5 h-5 mr-2"/>{t('back')}</OutlinePrimaryBtn>}
            {React.cloneElement(child, {previousStep, nextStep})}
          </Tab>)
          :
          <Tab isCurrent={true}>{React.cloneElement(children, {previousStep, nextStep})}</Tab>
        }
      </div>
    </div>
  </div>
}