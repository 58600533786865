import {useState} from "react";
import {useTranslation} from "react-i18next";
import * as SchedulesAPI from "../api/schedules";
import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import Label from "../components/common/inputs/Label";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import {momentWithLocales} from "../utils/dates";
import Spinner from "../components/common/Spinner";

const defaultValues = {
  email: '',
}

export default function EditAppointement({appointmentId, time, closeModal}) {
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)

  const {t, i18n} = useTranslation(['fields', 'errors', 'pages'])

  const handleSubmit = (data, {resetForm}) => {
    setShowGlobalError(false)
    setErrors({})
    return SchedulesAPI.updatePublic(appointmentId, {
        start: time,
        email: data.email
      })
      .then(closeModal)
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors || {})
      if (error.response.data.error) {
        setShowGlobalError(true)
      }
    }
  }

  return <Formik
    initialValues={defaultValues}
    onSubmit={handleSubmit}
  >
    {({ isSubmitting }) => (
      <Form>
        {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
        <div className="mb-4 mt-6">
          <Label required>{t('pages:appointment.new-date')}</Label>
          {momentWithLocales(time).locale(i18n.language).format("LLL")}
        </div>
        <Input
          name="email"
          label={t('email')}
          placeholder={t('email')}
          required
          error={errors.email}
        />
        <div className="mt-4">
          <PrimaryBtn disabled={isSubmitting} className="flex flex-row">{isSubmitting && <Spinner className="h-5 w-5 mr-3"/>}{t('pages:appointment.edit.title')}</PrimaryBtn>
        </div>
      </Form>
    )}
  </Formik>
}