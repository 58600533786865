import TextHeader from "../components/common/headers/TextHeader";
import {useTranslation} from "react-i18next";
import PageWithPictureHeader from "../components/common/Layout/PageWithPictureHeader";
import {useEffect, useState} from "react";
import {getContents} from "../api/contents";
import {useToast} from "../providers/ToastProvider";
import HelmetSEO from "../utils/HelmetSEO";

export default function Terms() {
  const {t, i18n} = useTranslation('routes')
  const [content, setContent] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const toast = useToast()

  useEffect(() => {
    getContents( 'contents', 'general-conditions')
      .then(setContent)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
    //eslint-disable-next-line
  }, [])

  return <>
    <HelmetSEO page="terms"/>
    <PageWithPictureHeader
      headerStyle="bg-terms-sm md:bg-terms"
      headerContent={<TextHeader>{t('terms.name')}</TextHeader>}
      isLoading={isLoading}
    >
      <article dangerouslySetInnerHTML={{
        __html: content.text_translations && content.text_translations[i18n.language],
      }}/>
    </PageWithPictureHeader>
  </>
}