import {useState} from "react";
import Card from "../common/Card";
import TransparentBtn from "../common/btns/TransparentBtn";
import {ChevronDownIcon} from "@heroicons/react/outline";
import {Transition} from "@headlessui/react";

export default function OrderStatus({status, nb = 0, children}) {
  const [open, setOpen] = useState(false)
  return <Card>
    <div className="flex justify-between" onClick={() => setOpen(!open)}>
      <h3>{status.name} <span className="px-2 bg-secondary text-white rounded-full text-sm">{nb}</span></h3>
      <div className="flex flex-col justify-center">
        <TransparentBtn>
          <ChevronDownIcon className={`w-6 h-6 transform duration-150 ${open ? 'rotate-180' : ''}`}/>
        </TransparentBtn>
      </div>
    </div>
    <Transition
      show={open}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="p-2 flex flex-col divide-y divide-secondary space-y-2">
        {children}
      </div>
    </Transition>
  </Card>
}