import VideoHeader from "../components/common/headers/VideoHeader";
import {getContents} from "../api/contents";
import {useTranslation} from "react-i18next";
import LoadingWrapper from "../components/common/placeholders/LoadingWrapper";
import {useEffect, useState} from "react";
import {useToast} from "../providers/ToastProvider";
import HelmetSEO from "../utils/HelmetSEO";

export default function Home() {
  const {i18n} = useTranslation('routes')
  const [content, setContent] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const toast = useToast()

  useEffect(() => {
    getContents('contents', 'home')
      .then(setContent)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
    //eslint-disable-next-line
  }, [i18n.language])

  return (
    <>
      <HelmetSEO page="home"/>
      <VideoHeader/>
      <main className="px-10 py-20 md:px-20 xl:px-60">
        <LoadingWrapper
          isLoading={isLoading}
        >
          <h1 className="mb-20">{content.title_translations && content.title_translations[i18n.language]}</h1>

          <article dangerouslySetInnerHTML={{
            __html: content.text_translations && content.text_translations[i18n.language],
          }}/>
        </LoadingWrapper>
      </main>
    </>
  )
}