import WorkingHour from "./WorkingHour";
import TransparentBtn from "../common/btns/TransparentBtn";
import {useTranslation} from "react-i18next";
import WorkingHourForm from "../../forms/WorkingHourForm";
import Modal from "../common/modals/Modal";
import {useState} from "react";

export default function WorkingDay({workingHours, day, onChange}) {
  const [openModal, setOpenModal] = useState(false)
  const {t} = useTranslation('pages')

  const closeModal = () => {
    setOpenModal(false)
    onChange()
  }

  return <div className="flex flex-col space-y-2">
    {workingHours.length > 0 ?
      workingHours.map(wh => <WorkingHour key={wh.id} workingHour={wh} onChange={onChange}/> ):
      <p className="font-bold">{t('working-hours.day-off')}</p>
    }
    <div>
      <TransparentBtn className="text-lg text-secondary" onClick={() => setOpenModal(true)}>{t('working-hours.create')} +</TransparentBtn>
    </div>
    <Modal
      title={t('working-hours.create')}
      isOpen={openModal}
      closeModal={closeModal}
      className="w-full max-w-xl"
    >
      <WorkingHourForm day={day} isCreating closeModal={closeModal}/>
    </Modal>
  </div>
}