import BackOffice from "../../../components/common/Layout/BackOffice";
import {useEffect, useState} from "react";
import * as OrderAPI from "../../../api/orders";
import Order from "../../../components/user/Order";
import {useTranslation} from "react-i18next";
import {useToast} from "../../../providers/ToastProvider";

export default function MyOrders() {
  const [isLoading, setIsLoading] = useState(true)
  const [orders, setOrders] = useState([])
  const {t, i18n} = useTranslation('routes')
  const toast = useToast()

  const update = () => {
    OrderAPI.myOrders()
      .then(orders => orders.sort((a, b) => b.id - a.id))
      .then(setOrders)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  }

  useEffect(() => {
    update()
    //eslint-disable-next-line
  }, [i18n.language])

  return <BackOffice isLoading={isLoading}>
    <h1>{t('my-orders.name')}</h1>
    <div className="mt-10 flex flex-col space-y-6">
      {orders.map(order => <Order key={order.id} order={order} update={update}/>)}
    </div>
  </BackOffice>
}