import { Transition } from '@headlessui/react'
import { Fragment } from 'react'

export default function TemplateModal({isOpen, closeModal, className, children}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <div
        className="fixed inset-0 z-30 overflow-y-auto"
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={closeModal}/>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className={`
              inline-block my-10 p-6 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl overflow-visible
              ${className || ''}
            `}>
              {children}
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  )
}
