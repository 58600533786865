import Label from "./Label";
import Info from "./Info";
import React from "react";
import {useFormikContext} from "formik";

export default function CheckBoxGroup({label, name, required, error, info, children, className, async, ...props}) {
  const  {submitForm} = useFormikContext()

  const handleChange = () => {
    submitForm()
  }
  return <fieldset {...props}>
    <Label required={required} error={error}>{label}</Label>
    <Info className="text-sm sm:text-xs" error={error} info={info}/>
    <div className={`mt-2 space-y-2 ${className || ''}`}>
      {Array.isArray(children) ?
        children.map((child, id) => React.cloneElement(child, {error, name, id: `${name}_${id}`, async: handleChange}))
        :
        React.cloneElement(children, {error, name, id: name, async: handleChange})
      }
    </div>
  </fieldset>
}