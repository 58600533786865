import LoadingWrapper from "../placeholders/LoadingWrapper";

export default function SimplePage({isLoading, children}) {
  return <>
    <main className="px-10 py-20 md:px-20 xl:px-60 flex flex-col">
      <LoadingWrapper
        isLoading={isLoading}
      >
        {children}
      </LoadingWrapper>
    </main>
  </>
}