import ToastMessage from "./ToastMessage";
import {ToastContext} from "../../../providers/ToastProvider";
import {useContext} from "react";

export default function ToastContainer() {
  const context = useContext(ToastContext)
  const handleRemove = (id) => {
    context?.remove(id);
  }
  return <div className="top-32 right-0 fixed z-50 w-full md:max-w-lg p-4 md:p-4 max-h-screen overflow-hidden pointer-events-none">
    <div className="flex-1 flex-col fade w-full mr-8 justify-end pointer-events-none">
      {context?.data.map(toast =>
      <div
        key={toast.id}
        className="flex py-1 w-full transform transition-all duration-300 pointer-events-auto"
      >
        <ToastMessage
          id={toast.id}
          message={toast.message}
          color={toast.color}
          header={toast.header}
          onRemove={handleRemove}
          lifetime={toast.lifetime}
        />
      </div>)}
    </div>
  </div>
}