import Card from "../common/Card";
import {useTranslation} from "react-i18next";
import TransparentBtn from "../common/btns/TransparentBtn";
import {PencilIcon, XCircleIcon} from "@heroicons/react/outline";
import Modal from "../common/modals/Modal";
import DeleteDialogModal from "../common/modals/DeleteDialogModal";
import {useState} from "react";
import PractitionerGroupForm from "../../forms/PractitionerGroupForm";
import {destroy} from "../../api/practitioners";

export default function Practitioner({practitioner, update}){
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const closeModal = () => {
    setEditModal(false)
    setDeleteModal(false)
    update()
  }

  const deletePractitioners = () => {
    destroy(practitioner.id)
      .then(update)
  }

  const {t} = useTranslation('pages')
  return <Card className="w-full max-w-xl flex flex-col">
    <div className="flex flex-row items-center justify-start space-x-2">
      <h3 className="text-2xl">{practitioner.name}</h3>
      <div className="flex-grow flex flex-row justify-end">
        <TransparentBtn onClick={() => setEditModal(true)}><PencilIcon className="w-5 h-5"/></TransparentBtn>
        <Modal
          title={t('practitioners.edit')}
          isOpen={editModal}
          closeModal={closeModal}
          className="w-full max-w-xl"
        >
          <PractitionerGroupForm practitioner={practitioner} closeModal={closeModal}/>
        </Modal>
        <TransparentBtn className="flex flex-row justify-end text-red-600" onClick={() => setDeleteModal(true)}><XCircleIcon className="h-5 w-5"/></TransparentBtn>
        <DeleteDialogModal
          isOpen={deleteModal}
          closeModal={() => setDeleteModal(false)}
          title={t('practitioners.delete.title', {name: practitioner.name})}
          description={t('practitioners.delete.description')}
          action={deletePractitioners}
        />
      </div>
    </div>
    <dl>
      <dt>{t('practitioners.public-name')}</dt>
      <dd>{practitioner.public_name}</dd>
      <dt>{t('practitioners.practitioners')}</dt>
      <dd>{practitioner.users?.map(user => <div key={user.id}>{user.name}</div>)}</dd>
    </dl>
  </Card>
}