import api from "./api";
import {lang} from "../languages";
import resources from "./resources";

const userResource = resources.user

export function getUsersWithRights() {
  return api().get(`/${lang()}/${resources.user}/with-rights`)
}

export function getRoles() {
  return api().get(`/${lang()}/${resources.roles}`)
}

export function search(query) {
  return api().get(`/${lang()}/${resources.user}/search?q=${query}`)
}

export function updateRoles(id, data) {
  return api().put(`/${lang()}/${resources.user}/${id}/${resources.roles}`, data)
}

export function getPractitioners() {
  return getUsersWithRights()
    .then(users => users.filter(user => user.roles?.includes('calendar')))
}

export function authUser() {
  let user = localStorage.getItem('user')
  if (user) {
    return Promise.resolve(JSON.parse(user))
  }
  else
    return refreshAuthUser()
}

export function refreshAuthUser() {
  return api().get('/fr/user')
    .then(setUser)
}

export function changePassword(data) {
  return authUser()
    .then(user => api().put(`/${lang()}/${userResource}/${user.id}/change-password`, data))
}

export function store(data) {
  return api().post(`/${lang()}/${userResource}`, data)
}

export function update(data) {
  return authUser()
    .then(user => api().put(`/${lang()}/${userResource}/${user.id}`, data))
    .then(setUser)
}

function setUser(data) {
  localStorage.setItem('user', JSON.stringify(data))
  return data
}