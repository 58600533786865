import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import * as ExerciceAPI from "../api/exercices";
import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import Editor from "../components/common/inputs/Editor";
import TranslatedInputs from "../components/common/inputs/TranslatedInputs";
import {convertDotDataToJson} from "../utils/formConverter";
import Spinner from "../components/common/Spinner";

export default function ExerciceForm({isCreating, closeModal, exercice}) {
  const defaultValues = {
    title: {
      fr: "",
      de: "",
      en: ""
    },
    description: {
      fr: "",
      de: "",
      en: ""
    },
  }

  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const {t} = useTranslation(['fields', 'forms'])

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
  }

  const create = (data, {resetForm}) => {
    reset()
    return ExerciceAPI.store(convertDotDataToJson(data))
      .then(() => {
        resetForm(defaultValues)
        closeModal()
      })
      .catch(handleError)
  }

  const update = data => {
    reset()
    return ExerciceAPI.update(exercice.id, convertDotDataToJson(data))
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  return <Formik
    initialValues={exercice || defaultValues}
    enableReinitialize
    onSubmit={create}
  >
    {({isSubmitting}) => (
    <Form>
      {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
      <div className="flex flex-col space-y-4">
        <TranslatedInputs
          errors={errors}
        >
          <Input
            label={t('title')}
            placeholder={t('title')}
            name="title"
            async={!isCreating ? update : false}
            required

          />
          <Editor
            label={t('description')}
            name="description"
            async={!isCreating ? update : false}
            required
          />
        </TranslatedInputs>
        <div className="sm:col-start-2 sm:text-center">
          {isCreating && <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : t('forms:save')}</PrimaryBtn>}
        </div>
      </div>
    </Form>
    )}
  </Formik>
}