import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import * as DaysOffAPI from "../api/daysOff";
import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import {DatePickerField} from "../components/common/inputs/DatePickerField";
import {formatDbDateTime} from "../utils/dates";
import Spinner from "../components/common/Spinner";

export default function DayOffForm({day, isCreating, closeModal}) {
  const defaultValues = {
    start: "",
    end: "",
    description: "",
  }
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const {t} = useTranslation(['fields', 'forms'])

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
  }

  const create = (data, {resetForm}) => {
    reset()
    return DaysOffAPI.store({
        ...data,
        start: formatDbDateTime(data.start),
        end: formatDbDateTime(data.end)
      })
      .then(() => {
        resetForm(defaultValues)
        closeModal()
      })
      .catch(handleError)
  }

  const update = data => {
    reset()
    if ("start" in data) data.start = formatDbDateTime(data.start)
    if ("end" in data) data.end = formatDbDateTime(data.end)
    return DaysOffAPI.update(day.id, data)
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  return <Formik
    initialValues={day || defaultValues}
    enableReinitialize
    onSubmit={create}
  >
    {({isSubmitting}) => (
    <Form>
      {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
      <div className="flex flex-col space-y-4">
        <DatePickerField
          label={t('start')}
          name="start"
          async={!isCreating ? update : false}
          error={errors.start}
          required

          showTimeSelect
          timeIntervals={15}
          dateFormat="Pp"
          minDate={new Date()}
        />
        <DatePickerField
          label={t('end')}
          name="end"
          async={!isCreating ? update : false}
          error={errors.end}
          required

          showTimeSelect
          timeIntervals={15}
          dateFormat="Pp"
          minDate={new Date()}
        />
        <Input
          name="description"
          label={t('description')}
          placeholder={t('description')}
          async={!isCreating ? update : false}
          error={errors.description}
          required
        />
        <div className="sm:col-start-2 sm:text-center">
          {isCreating && <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> :t('forms:save')}</PrimaryBtn>}
        </div>
      </div>
    </Form>
    )}
  </Formik>
}