import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FieldArray, Form, Formik} from "formik";
import FormSelect from "../components/common/inputs/FormSelect";
import Input from "../components/common/inputs/Input";
import {DatePickerField} from "../components/common/inputs/DatePickerField";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import * as PromosAPI from "../api/promos";
import {TrashIcon} from "@heroicons/react/outline";
import TransparentBtn from "../components/common/btns/TransparentBtn";
import Card from "../components/common/Card";
import DangerBtn from "../components/common/btns/solid/DangerBtn";
import {formatDbDate} from "../utils/dates";
import Spinner from "../components/common/Spinner";
import {useToast} from "../providers/ToastProvider";

export function PromoUse({errors, remove, id, promoContexts, promoTypes, values, name}) {
  const {t} = useTranslation('fields')
  return <div className="py-2">
    <div className="grid gap-2 sm:grid-cols-2 lg:grid-cols-4">
      <FormSelect
        label={t('promo-context')}
        name={`${name || 'promo_uses'}.${id}.promo_context_id`}
        error={errors[`${name || 'promo_uses'}.${id}.promo_context_id`]}
        required
        info={promoContexts.find(context => context.id === values[name || 'promo_uses'][id].promo_context_id)?.description}
      >
        <option>---</option>
        {promoContexts.map((context, id) => <option key={id} value={context.id}>{context.name}</option> )}
      </FormSelect>
      <FormSelect
        label={t('promo-type')}
        name={`${name || 'promo_uses'}.${id}.promo_type_id`}
        error={errors[`${name || 'promo_uses'}.${id}.promo_type_id`]}
        required
      >
        <option>---</option>
        {promoTypes.map((type, id) => <option key={id} value={type.id}>{type.name}</option>)}
      </FormSelect>
      <Input
        label={t('amount')}
        placeholder={t('amount')}
        name={`${name || 'promo_uses'}.${id}.amount`}
        error={errors[`${name || 'promo_uses'}.${id}.amount`]}
        required
      />
      <Input
        label={t('uses')}
        placeholder={t('uses')}
        name={`${name || 'promo_uses'}.${id}.uses`}
        error={errors[`${name || 'promo_uses'}.${id}.uses`]}
      />
    </div>
    <div className="mt-2">
      <DangerBtn type="button" onClick={remove}><TrashIcon className="w-4 h-5"/></DangerBtn>
    </div>
  </div>
}

const emptyPromoUse = {
  amount: "",
  uses: "",
  promo_type_id: "",
  promo_context_id: ""
}

const defaultValues = {
  id: "",
  uses: "",
  until: "",
  email: "",
  promo_uses: []
}

export default function PromoForm({promoContexts, closeModal}) {
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [otherError, setOtherError] = useState("")
  const [promoTypes, setPromoTypes] = useState([])

  const {t} = useTranslation(['fields', 'forms', 'pages'])
  const toast = useToast()

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
    setOtherError("")
  }

  const creates = (data, {resetForm}) => {
    reset()
    PromosAPI.store({
      ...data,
      until: data.until ? formatDbDate(data.until) : null
    })
      .then(() => {
        resetForm(defaultValues)
        closeModal()
      })
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      if (error.response.data.errors)
        setErrors(error.response.data.errors)
      else
        setOtherError(error.response.data.error)
    }
  }

  useEffect(() => {
    PromosAPI.getTypes()
      .then(setPromoTypes)
      .catch(() => toast.pushUnKnown())
    //eslint-disable-next-line
  }, [])

  return <Formik
    initialValues={defaultValues}
    enableReinitialize
    onSubmit={creates}
  >
    {({values, isSubmitting}) => (
      <Form>
        <div className="flex flex-col space-y-4">
          {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
          {otherError && <div className="text-red-600 text-sm">{otherError}</div> }
          <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-3">
            <div>
              <Input
                label={t('promo-code')}
                placeholder={t('promo-code')}
                name="id"
                error={errors.id}
                required
              />
            </div>
            <div className="md:col-span-2">
              <Input
                label={t('email')}
                placeholder={t('email')}
                name="email"
                error={errors.email}
              />
            </div>
            <div>
              <Input
                label={t('uses')}
                placeholder={t('uses')}
                name="uses"
                error={errors.uses}
              />
            </div>
            <div>
              <DatePickerField
                label={t('until')}
                error={errors.until}
                name="until"
                dateFormat="P"
                minDate={new Date()}
              />
            </div>
          </div>
          <FieldArray name="promo_uses">
            {arrayHelpers => <Card>
              <b>{t('pages:promo-code-check.promo-uses')}</b>
              {errors.promo_uses && <p className="text-red-600">{errors.promo_uses}</p>}
              <div className="flex flex-col space-y-2 divide-y divide-secondary">
                {values.promo_uses?.map((promo_use, id) => <PromoUse
                  key={id}
                  promoUse={promo_use}
                  errors={errors}
                  remove={() => arrayHelpers.remove(id)}
                  id={id}
                  promoContexts={promoContexts}
                  promoTypes={promoTypes}
                  values={values}
                />)}
              </div>
              {values.promo_uses.length < 4 && <TransparentBtn type="button" onClick={() => arrayHelpers.push(emptyPromoUse)} className="text-secondary">
                {t('add-promo-uses')} +
              </TransparentBtn>}
            </Card>}
          </FieldArray>
          <div className="sm:text-center">
            <PrimaryBtn type="submit" disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : t('forms:save')}</PrimaryBtn>
          </div>
        </div>
      </Form>
    )}
  </Formik>
}