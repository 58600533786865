import {Fragment, useEffect, useState} from "react"
import {Menu, Transition} from '@headlessui/react'
import OutlineSecondaryBtn from "../btns/outline/OutlineSecondaryBtn";
import DangerBtn from "../btns/solid/DangerBtn";
import {useTranslation} from "react-i18next";
import {useAuth} from "react-oauth2-pkce";
import OutlinePrimaryBtn from "../btns/outline/OutlinePrimaryBtn";
import * as UserAPI from "../../../api/user";
import UnstyledLink from "../links/UnstyledLink";
import api from "../../../api/api";
import {useToast} from "../../../providers/ToastProvider";

export default function UserBadge({onRedirect}) {
  const {authService} = useAuth()
  const {t} = useTranslation(['routes', 'auth'])
  const [user, setUser] = useState({})
  const toast = useToast()

  useEffect(() => {
    UserAPI.authUser()
      .then(setUser)
      .catch(() => toast.pushUnKnown())
    //eslint-disable-next-line
  }, [])

  const logout = () => {
    api().post("/logout-api")
      .catch(() => toast.pushUnKnown())
      .then(() => authService.logout())
      .then(() => localStorage.removeItem('user'))
  }

  const closeMenu = () => {
    onRedirect()
  }

  return (
    <Menu as="div" className="relative lg:pl-3">
      <Menu.Button className="
        rounded-full
        h-10 w-10
        bg-secondary
        font-bold text-white
        flex items-center justify-center
        focus:outline-none
        ">
        {user?.name?.substr(0,1)}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items as="div"
          className="
            absolute right-0
            w-72 mt-2 origin-top-right
            bg-white
            border
            rounded-md
            shadow-xl
            focus:outline-none overflow-hidden
            text-center
            px-5 py-7
            "
        >
          <div className="pb-2">
            <p><b>{user?.name} {user?.lastname}</b></p>
            <p>{user?.email}</p>
          </div>
          <div className="
            flex flex-col
            justify-center
            space-y-2
            pt-6
          ">
            <UnstyledLink to={t('my-account.link')} onClick={closeMenu}><OutlinePrimaryBtn className="w-full">{t('my-account.name')}</OutlinePrimaryBtn></UnstyledLink>
            {user?.roles?.length > 0 && <UnstyledLink to={t('dashboard.link')} onClick={closeMenu}><OutlineSecondaryBtn className="w-full">{t('dashboard.name')}</OutlineSecondaryBtn></UnstyledLink>}
            <DangerBtn onClick={logout}>{t('auth:logout')}</DangerBtn>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}