import Input from "../components/common/inputs/Input";
import {useTranslation} from "react-i18next";
import * as TaskCategoriesAPI from "../api/taskCategories"
import {Form, Formik} from "formik";
import {useState} from "react";
import languages from "../languages";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import {convertDotDataToJson} from "../utils/formConverter";
import Spinner from "../components/common/Spinner";


export default function TaskCategoryForm({category, isCreating, closeModal}) {
  const defaultValues = {
    fr: "",
    en:"",
    de:""
  }
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const {t} = useTranslation(['fields','forms'])

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
  }

  const create = (data, {resetForm}) => {
    reset()
    return TaskCategoriesAPI.post(data)
      .then(() => {
        resetForm(defaultValues)
        closeModal()
      })
      .catch(handleError)
  }

  const update = data => {
    reset()
    return TaskCategoriesAPI.put(category.id, convertDotDataToJson(data))
      .catch(handleError)

  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  return <Formik
    initialValues={{name: category?.name_translations || defaultValues}}
    enableReinitialize
    onSubmit={create}
  >
    {({isSubmitting}) => (
    <Form>
      {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
      <div className="flex flex-col space-y-2 mt-2 mx-4">
        {languages.map(lang => <div key={lang.id} className=""><Input
          name={`name.${lang.id}`}
          label={t('lang_name', {lang: lang.id})}
          placeholder={t('lang_name', {lang: lang.id})}
          async={!isCreating ? update : false}
          error={errors[`name.${lang.id}`]}
          required
        /></div>)}
        <div className="self-center">
          {isCreating && <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : t('forms:save')}</PrimaryBtn>}
        </div>
      </div>
    </Form>
    )}
  </Formik>
}