import {useTranslation} from "react-i18next";
import Card from "../../common/Card";
import Price from "../../common/Price";

export default function PromoCodeInfos({article}) {
  const {t} = useTranslation(['fields', 'translation'])
  return <Card className="max-w-2xl">
    <dl>
      <dt>{t('nominative')}</dt>
      <dd>{article.promo_nominative ? t('translation:yes') : t('translation:no')}</dd>
      <dt>{t('uses')}</dt>
      <dd>{(article.promo_uses || article.promo_uses === 0) ? article.promo_uses : '-'}</dd>
      <dt>{t('duration', {time: t('translation:months')})}</dt>
      <dd>{article.promo_duration ? t('translation:n-months', {count: article.promo_duration}) : '-'}</dd>
      <dt>{t('promo-context')}</dt>
      <dd>
        <ul>
          {article.promo_actions?.map((action, id) => <li key={id}>
            <div className="flex flex-col sm:flex-row justify-between max-w-sm">
              <div>
                {action.promo_context?.name}:
              </div>
              <div className="ml-1 min-w-max text-xs font-bold md:text-base md:font-medium flex flex-col justify-center">
                <div>{(action.uses || action.uses === 0) && `${action.uses} x`} {action.promo_type_id === 'percent' ? action.amount + '%' : <Price>{action.amount}</Price>}</div>
              </div>
            </div>
            <div><small className="text-gray-500">{action.promo_context?.description}</small></div>
          </li>)}
        </ul>
      </dd>
    </dl>
  </Card>
}