import api from "./api";
import {lang} from "../languages";
import resources from "./resources";

export function getAvailableAddresses(task, practitioner) {
  return api().get(`/${lang()}/${resources.tasks}/${task}/${resources.practitioners}/${practitioner}/${resources.address}`)
}

export function getAvailableTimes(task, practitioner, address, data) {
  return api().get(`/${lang()}/${resources.tasks}/${task}/${resources.practitioners}/${practitioner}/${resources.address}/${address}/available-times?start=${data.start}&end=${data.end}`)
}

export function createPublicSchedule(data) {
  return api().post(`/${lang()}/${resources.schedules_public}`, data)
}