import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as scheduleAPI from "../api/schedules";
import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import {DatePickerField} from "../components/common/inputs/DatePickerField";
import {formatDbDateTime} from "../utils/dates";
import DangerBtn from "../components/common/btns/solid/DangerBtn";
import DeleteDialogModal from "../components/common/modals/DeleteDialogModal";

export default function ScheduleEditForm({schedule, closeModal}) {
  const [deleteModal, setDeleteModal] = useState(false)
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [otherError, setOtherError] = useState("")
  const {t} = useTranslation(['fields', 'forms', 'pages'])
  const [formVals, setFormVals] = useState({
    start: "",
    end: "",
    lastname: "",
    firstname: "",
    email: "",
    tel: "",
    description: "",
  })

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
    setOtherError("")
  }

  const update = data => {
    if ("start" in data) data.start = formatDbDateTime(data.start)
    if ("end" in data) data.end = formatDbDateTime(data.end)

    reset()
    return scheduleAPI.update(schedule.id, data)
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      if (error.response.data.errors)
        setErrors(error.response.data.errors)
      else
        setOtherError(error.response.data.error)
    }
  }

  useEffect(() => {
    setFormVals({
      start: schedule.start || "",
      end: schedule.end || "",
      lastname: schedule.lastname || "",
      firstname: schedule.firstname || "",
      email: schedule.email || "",
      tel: schedule.tel || "",
      description: schedule.description || "",
    })
  }, [schedule])

  return (<>
    <Formik
    initialValues={formVals}
    enableReinitialize
    onSubmit={() => {}}
  >
    <Form>
      <div className="flex flex-col space-y-4">
        {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
        {otherError && <div className="text-red-600 text-sm">{otherError}</div> }
        <dl>
          <dt>{t('task')}</dt>
          <dd>{schedule.task}</dd>
          <dt>{t('practitioner')}</dt>
          <dd>{schedule.users?.map((user,id) => <p key={id}>{user.name}</p>)}</dd>
          <dt>{t('address')}</dt>
          <dd>{schedule.address}</dd>
          <dt>{t('language')}</dt>
          <dd className="uppercase">{schedule.language}</dd>
        </dl>
        <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-2 sm:space-y-0">
          <DatePickerField
            label={t('start')}
            name="start"
            async={update}
            error={errors.start}
            required

            showTimeSelect
            timeIntervals={15}
            dateFormat="Pp"
            minDate={new Date()}
          />
          <DatePickerField
            label={t('end')}
            name="end"
            async={update}
            error={errors.end}
            required

            showTimeSelect
            timeIntervals={15}
            dateFormat="Pp"
            minDate={new Date()}
          />
        </div>
        <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-2 sm:space-y-0">
          <Input
            name="lastname"
            label={t('lastname')}
            placeholder={t('lastname')}
            async={update}
            error={errors.lastname}
            required
          />
          <Input
            name="firstname"
            label={t('firstname')}
            placeholder={t('firstname')}
            async={update}
            error={errors.firstname}
            required
          />
        </div>
        <div className="flex flex-col space-y-4 md:flex-row md:space-x-2 md:space-y-0">
          <Input
            name="email"
            label={t('email')}
            placeholder={t('email')}
            async={update}
            error={errors.email}
          />
          <Input
            name="tel"
            label={t('tel')}
            placeholder={t('tel')}
            async={update}
            error={errors.tel}
          />
        </div>
        <Input
          name="description"
          label={t('details')}
          placeholder={t('details')}
          async={update}
          error={errors.description}
        />
        <div className="sm:text-center">
          <DangerBtn onClick={() => setDeleteModal(true)}>{t('forms:delete')}</DangerBtn>
        </div>
      </div>
    </Form>
  </Formik>
    <DeleteDialogModal
      isOpen={deleteModal}
      closeModal={() => setDeleteModal(false)}
      title={t('pages:calendar.delete.title')}
      description={t('pages:calendar.delete.description')}
      action={() => {
        scheduleAPI.destroy(schedule.id)
          .catch(handleError)
          .then(() => {
            setDeleteModal(false)
            closeModal()
          })
      }}
    />
  </>)
}