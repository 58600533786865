import SolidBtn from "./SolidBtn";

export default function SuccessBtn({className, children, ...props}) {
  return <SolidBtn className={`
    bg-green-600
    ${className || ''}
  `}
                          {...props}
  >
    {children}
  </SolidBtn>
}