import BackOffice from "../../../../components/common/Layout/BackOffice";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import * as UserAPI from "../../../../api/user";
import Card from "../../../../components/common/Card";
import UserRightsForm from "../../../../forms/UserRightsForm";
import SearchField from "../../../../components/common/inputs/SearchField";
import {useToast} from "../../../../providers/ToastProvider";

function User({user, rights}) {
  return <div className="py-2 px-4 flex flex-col md:flex-row md:space-x-4">
    <div className="flex flex-col">
      <p>{user.name} {user.lastname}</p>
      <small className="text-gray-500">{user.email}</small>
    </div>
    <div>
      <UserRightsForm user={user} rights={rights}/>
    </div>
  </div>
}

function UserSearchRow({id, name, lastname, email, className, ...props}) {
  return <div className={`py-2 px-4 ${className || ''}`} {...props}>
    <div className="flex flex-col">
      <p>{name} {lastname}</p>
      <small className="text-gray-500">{email}</small>
    </div>
  </div>
}

export default function UserRights() {
  const [isLoading, setIsLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [rights, setRights] = useState([])
  const {t} = useTranslation('routes')
  const toast = useToast()

  const update = () => {
    UserAPI.getRoles()
      .then(setRights)

    UserAPI.getUsersWithRights()
      .then(setUsers)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  }
  //eslint-disable-next-line
  useEffect(update, [])
  return <BackOffice role="admin" isLoading={isLoading}>
    <h1 className="mb-4">{t('user-rights.name')}</h1>

    <div className="mb-4 max-w-lg">
      <SearchField fetchData={UserAPI.search} searchRow={<UserSearchRow/>} update={(result) => setUsers([...users, result])}/>
    </div>
    <Card className="flex flex-col divide-y">
      {users.map((user, id) => <User key={id} user={user} rights={rights}/> )}
    </Card>
  </BackOffice>
}