import {useAppointment} from "../../providers/AppointmentProvider";
import ListWithClick from "./ListWithClick";

export default function ChooseCategory({nextStep}) {
  const appointment = useAppointment()

  const chooseCategory = category => {
    appointment.setCategory(category)
  }

  return <ListWithClick
    onClick={chooseCategory}
    selected={appointment.category}
    items={appointment.categories}
    nextStep={nextStep}
  />
}