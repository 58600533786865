import BackOffice from "../../../components/common/Layout/BackOffice";
import {useTranslation} from "react-i18next";
import UserInfoForm from "../../../forms/UserInfoForm";
import ChangePasswordForm from "../../../forms/ChangePasswordForm";

export default function MyAccount(props) {
  const {t} = useTranslation(['routes', 'pages'])

  return <BackOffice>
    <h1>{t('my-account.name')}</h1>

    <div className="
      mt-10 flex flex-col lg:flex-row justify-start
      space-y-8 lg:space-y-0 lg:space-x-10 xl:space-x-20
      divide-secondary divide-y lg:divide-y-0
      ">
      <UserInfoForm title={t('pages:my-account.personal-info')}/>
      <ChangePasswordForm className="pt-8 lg:pt-0" />
    </div>
  </BackOffice>
}