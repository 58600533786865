import NumberFormat from "react-number-format";

export default function Price({children, solde}) {
  return <div className={solde > 0 ? 'text-red-600': 'text-current'}>
    <NumberFormat
      value={children}
      displayType={'text'}
      thousandSeparator={"'"}
      fixedDecimalScale
      decimalScale={2}
      suffix={' CHF'}
    />
    {solde > 0 && <span className="bg-red-600 text-white rounded-full px-1 text-xs ml-1">-{solde}%</span>}
  </div>
}