import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as EventsAPI from "../api/events";
import * as UsersAPI from "../api/user";
import {convertDotDataToJson} from "../utils/formConverter";
import {Form, Formik} from "formik";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import Spinner from "../components/common/Spinner";
import Input from "../components/common/inputs/Input";
import Editor from "../components/common/inputs/Editor";
import TranslatedInputs from "../components/common/inputs/TranslatedInputs";
import {DatePickerField} from "../components/common/inputs/DatePickerField";
import Switch from "../components/common/inputs/Switch";
import FormSelect from "../components/common/inputs/FormSelect";
import {formatDbDate} from "../utils/dates";

const defaultValues = {
  title: {
    fr: "",
    de: "",
    en: ""
  },
  text: {
    fr: "",
    de: "",
    en: ""
  },
  start_date: "",
  end_date: "",
  start_time: "",
  end_time: "",
  visible: 0,
  price: "",
  available_seats: "",
  location: "",
}

export default function EventForm({event, closeModal, isCreating}) {
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [formValues, setFormValues] = useState(defaultValues)
  const [admins, setAdmins] = useState([])
  const {t} = useTranslation(['fields', 'forms', 'pages'])

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
  }

  const create = (data, {resetForm}) => {
    reset()
    return EventsAPI.store({
      ...convertDotDataToJson(data),
      start_date: formatDbDate(data.start_date),
      end_date: formatDbDate(data.end_date)
    })
      .then(() => {
        resetForm(defaultValues)
        closeModal()
      })
      .catch(handleError)
  }

  const update = data => {
    reset()
    let values = convertDotDataToJson(data)
    if ("start_date" in values) values.start_date = formatDbDate(values.start_date)
    if ("end_date" in values) values.end_date = formatDbDate(values.end_date)
    return EventsAPI.update(event.id, values)
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  useEffect(() => {
    setFormValues(event || defaultValues)
    UsersAPI.getUsersWithRights()
      .then(setAdmins)
      .catch(handleError)
  }, [event])

  return <Formik
    initialValues={formValues}
    enableReinitialize
    onSubmit={create}
  >
    {({values, isSubmitting}) => (
      <Form className="w-full">
        {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
        <div className="w-full">
          <TranslatedInputs
            errors={errors}
          >
            <Input
              label={t('title')}
              placeholder={t('title')}
              name="title"
              async={!isCreating ? update : false}
              required
            />
            <Editor
              label={t('text')}
              name="text"
              async={!isCreating ? update : false}
              required
            />
          </TranslatedInputs>
        </div>
        <div className="grid lg:grid-cols-2 gap-y-4 gap-x-2">
          <div className="grid sm:grid-cols-2 gap-2 relative py-2 px-2 border border-secondary rounded-lg">
            <div className="text-lg font-semibold absolute bg-white -top-4 left-4">{t('dates')}</div>
            <DatePickerField
              label={t('start')}
              name="start_date"
              error={errors.start_date}
              async={!isCreating ? update : false}
              required

              dateFormat="P"
              minDate={new Date()}
            />
            <DatePickerField
              label={t('end')}
              name="end_date"
              error={errors.end_date}
              async={!isCreating ? update : false}
              required

              dateFormat="P"
              minDate={new Date()}
            />
          </div>
          <div className="grid sm:grid-cols-2 gap-2 relative py-2 px-2 border border-secondary rounded-lg">
            <div className="text-lg font-semibold absolute bg-white -top-4 left-4">{t('times')}</div>
            <Input
              type="time"
              name="start_time"
              label={t('start')}
              placeholder={t('start')}
              async={!isCreating ? update : false}
              error={errors.start_time}
              required
            />
            <Input
              type="time"
              name="end_time"
              label={t('end')}
              placeholder={t('end')}
              async={!isCreating ? update : false}
              error={errors.end_time}
              required
            />
          </div>
        </div>
        <div className="grid gap-x-2 gap-y-4 sm:grid-cols-2 md:grid-cols-5 mt-4">
          <div>
            <Switch
              name="visible"
              label={t('is_public')}
              async={!isCreating ? update : false}
              error={errors.visible}
              required
            />
          </div>
          <Input
            type="number"
            name="price"
            label={t('price')}
            placeholder={t('price')}
            async={!isCreating ? update : false}
            error={errors.price}
            required
          />
          <Input
            type="number"
            name="available_seats"
            label={t('available-seats')}
            placeholder={t('available-seats')}
            async={!isCreating ? update : false}
            error={errors.available_seats}
            required
          />
          <div className="col-span-2">
            <Input
              name="location"
              label={t('address')}
              placeholder={t('address')}
              async={!isCreating ? update : false}
              error={errors.location}
              required
            />
          </div>
        </div>
        <div>
          <FormSelect
            label={t('organisators')}
            required
            name="admins"
            multiple
            async={!isCreating ? update : false}
          >
            {admins.map((admin, id) => <option key={id} value={admin.id}>{admin.name} {admin.lastname}</option>)}
          </FormSelect>
        </div>
        {isCreating && <div className="mt-4">
          <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : t('forms:save')} </PrimaryBtn>
        </div>}
      </Form>
    )}
  </Formik>
}