import Modal from "../common/modals/Modal";
import {useTranslation} from "react-i18next";
import {momentWithLocales as moment} from "../../utils/dates";
import RowArticleWithPrice from "./RowArticleWithPrice";
import Price from "../common/Price";
import {orderStatus} from "../../utils/orders";

function Section({title, children}) {
  return <div className="py-2">
    <h4 className="text-xl mb-3">{title}</h4>
    {children}
  </div>
}

function Liste({children}) {
  return <div className="flex flex-col space-y-2 divide-y px-3">
    {children}
  </div>
}

function Element({title, children}) {
  return <div className="py-2 flex flex-row justify-between">
    <div>{title}</div>
    <div className="text-sm text-left w-1/3">{children}</div>
  </div>
}

function Address(props) {
  return <>
    <p>{props.firstname} {props.lastname}</p>
    <p>{props.street}</p>
    <p>{props.npa} {props.city}</p>
  </>
}

export default function OrderModal({order, isOpen, closeModal, ...props}) {
  const {t, i18n} = useTranslation('pages')

  return <Modal
    closeModal={closeModal}
    isOpen={isOpen}
    title={`${t('my-orders.order-title')} ${order.id}`}
  >
    <div className="flex flex-col space-y-4 divide-y divide-secondary">
      <Section>
        <div className="text-sm text-primary">{moment(order.date).locale(i18n.language).format('LL')}</div>
        <div className="flex flex-col md:flex-row justify-start md:space-x-8 text-sm">
          <div>{t('orders.status')}: <span className="text-secondary">{order.order_status?.name}</span></div>
          <div>{t('orders.total')}: <b className="text-primary"><Price>{order.total}</Price></b></div>
        </div>
      </Section>
      <Section title={t('orders.articles')}>
        <div className="flex flex-col divide-y">
          {order.articles?.map(article => <RowArticleWithPrice key={article.id} article={article}/>)}
          <div className="self-end flex flex-row justify-end space-x-4">
            <div><b>{t('orders.delivery-cost')}</b></div>
            <div><b><Price>{order.delivery_cost}</Price></b></div>
          </div>
          {order.reduction && <div className="self-end flex flex-row justify-end space-x-4">
            <div><b>{t('orders.reduction')}</b></div>
            <div><b><Price>{order.reduction}</Price></b></div>
          </div>}
          <div className="self-end flex flex-row justify-end space-x-4">
            <div><b>{t('orders.total')}</b></div>
            <div><b><Price>{order.total}</Price></b></div>
          </div>
        </div>
      </Section>
      {[orderStatus.send_back, orderStatus.send_back_received].includes(order.order_status?.id) && <Section title={t('orders.returned-articles')}>
        <div className="flex flex-col divide-y">
          {order.returned_articles?.map(article => <RowArticleWithPrice key={article.id} article={article}/>)}
        </div>
      </Section>}
      <Section title={t('orders.delivery-details')}>
        <Liste>
          <Element title={t('orders.delivery-type.name')}>{order.needs_delivery ? t('orders.delivery-type.send') : t('orders.delivery-type.collect')}</Element>
          <Element title={order.needs_delivery ? t('orders.delivery-type.address') : t('orders.delivery-type.collect-address')}>
            <Address {...order}/>
          </Element>
          {order.poste_code && <Element title={`${t('orders.consignment')} ${order.poste_code}`}>
            <a
              href={`https://www.post.ch/swisspost-tracking?formattedParcelCodes=${order.poste_code}&p_language=${i18n.language}`}
              target="_blank"
              rel="noreferrer"
            >
              {t('orders.follow-consignment')}
            </a>
          </Element>}
        </Liste>
      </Section>
    </div>
  </Modal>
}