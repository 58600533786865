export default function SimpleSelect(props) {
  return <select
    className={`
          block mt-2 p-3
          border
          focus:border-secondary focus:outline-none
          focus:ring-1 focus:ring-secondary 
          w-full shadow-sm sm:text-sm border-gray-300 rounded-md
          ${props.className || ''}
    `}
    {...props}
  />
}