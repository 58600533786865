import moment from "moment";
import "moment/locale/fr"
import "moment/locale/de"

export function formatDbDate(date) {
  return moment(date).format("YYYY-MM-DD")
}

export function formatDbDateTime(date) {
  return moment(date).format("YYYY-MM-DD HH:mm")
}

export function momentWithLocales(date, format) {
  return moment(date, format)
}

export function localizedMoment(date, locale, format) {
  return momentWithLocales(date, format).locale(locale)
}

export function getFormattedTime(date, locale) {
  return localizedMoment(date, locale, 'HH:mm:ss').format("LT")
}

export function getFormattedDate(date, locale) {
  return localizedMoment(date, locale).format("LL")
}

export function getFormattedDateTime(date, locale) {
  return localizedMoment(date, locale).format("LLL")
}