import DashboardCard from "./DashboardCard";
import {useTranslation} from "react-i18next";

export default function Section({title, cards}) {
  const {t} = useTranslation('routes')
  return <div className="py-6 px-2">
    <h3>{title}</h3>
    <div className="grid
      sm:grid-cols-2 xl:grid-cols-3 2xl:gird-cols-4
      gap-y-2 sm:gap-x-2 md:gap-4">
      {cards.map((card, id) => <DashboardCard key={id} title={t(`${card}.name`)} link={t(`${card}.link`)}>
          {t(`${card}.description`)}
        </DashboardCard>)
      }
    </div>
  </div>
}