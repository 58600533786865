import FlagFr from "./FlagFr";
import FlagEn from "./FlagEn";
import FlagDe from "./FlagDe";

export default function Flag(props) {

  switch (props.lang) {
    case "en":
      return <FlagEn className={props.className}/>
    case "fr":
      return <FlagFr className={props.className}/>
    case "de":
      return <FlagDe className={props.className}/>
    default:
      return;
  }
}