import axios from 'axios'

export default function api() {
  const token = JSON.parse(localStorage.getItem('auth'))?.access_token
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 15000,
    headers: {
      'Authorization': 'Bearer '+ token
    }
  })

  api.interceptors.response.use(response => response.data?.data, error => {
    if (error.response?.status === 401) {
      localStorage.removeItem('user')
      localStorage.removeItem('auth')
      // todo: redirect
    }

    return Promise.reject(error)
  })

  return api
}