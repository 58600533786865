import Input from "../components/common/inputs/Input";
import {useTranslation} from "react-i18next";
import * as TasksAPI from "../api/tasks"
import {Form, Formik} from "formik";
import {useEffect, useState} from "react";
import languages from "../languages";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import {convertDotDataToJson} from "../utils/formConverter";
import FormSelect from "../components/common/inputs/FormSelect";
import * as AddressAPI from "../api/addresses";
import * as PractitionerAPI from "../api/practitioners";
import ColorPicker from "../components/common/inputs/ColorPicker";
import Switch from "../components/common/inputs/Switch";
import Spinner from "../components/common/Spinner";

const defaultValues = {
  name: {
    fr: "",
    en:"",
    de:""
  },
  duration: "",
  color: "#000000",
  text_color: "#ffffff",
  is_public: false,
  task_category_id: -1,
  practitioners: [],
  addresses: []
}

export default function TaskForm({task, isCreating, closeModal, categories}) {
  const [formVals, setFormVals] = useState(defaultValues)
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const {t} = useTranslation(['fields', 'forms'])
  const [practitioners, setPractitioners] = useState([])
  const [addresses, setAddresses] = useState([])

  useEffect(() => {
    AddressAPI.index().then(setAddresses)
    PractitionerAPI.index().then(setPractitioners)
  }, [])

  useEffect(() => {
    if (task) {
      setFormVals({
        ...task,
        name: task.name_translations,
        addresses: task.addresses?.map(address => address.id),
        practitioners: task.practitioners?.map(practitioner => practitioner.id),
        is_public: !!task.is_public
      })
    } else {
      setFormVals(defaultValues)
    }
  }, [task])

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
  }

  const create = (data, {resetForm}) => {
    reset()
    return TasksAPI.store({
      ...convertDotDataToJson(data),
      color: data.color || defaultValues.color,
      text_color: data.text_color || defaultValues.text_color
    })
      .then(() => {
        resetForm(defaultValues)
        closeModal()
      })
      .catch(handleError)
  }

  const update = data => {
    reset()
    return TasksAPI.update(task.id, convertDotDataToJson(data))
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  return <Formik
    initialValues={formVals}
    enableReinitialize
    onSubmit={create}
  >
    {({isSubmitting}) => (
    <Form>
      {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
      <div className="flex flex-col mt-2 mx-4 space-y-2">
        <div className="grid md:grid-cols-3 gap-2">
          {languages.map(lang => <div key={lang.id}><Input
            name={`name.${lang.id}`}
            label={t('lang_name', {lang: lang.id})}
            placeholder={t('lang_name', {lang: lang.id})}
            async={!isCreating ? update : false}
            error={errors[`name.${lang.id}`]}
            required
          /></div>)}
        </div>
        {isCreating && <FormSelect
          name="task_category_id"
          label={t('task_category')}
          placeholder={t('task_category')}
          async={!isCreating ? update : false}
          error={errors.task_category_id}
          required
          className="w-full max-w-sm"
        >
          <option>---</option>
          {categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
        </FormSelect>}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
          <Input
            name="duration"
            label={t('duration', {time: 'min'})}
            placeholder={t('duration', {time: 'min'})}
            async={!isCreating ? update : false}
            error={errors.duration}
            required
          />
          <Switch
            name="is_public"
            label={t('is_public')}
            async={!isCreating ? update : false}
            error={errors.is_public}
            required
          />
          <ColorPicker
            name="color"
            label={t('color')}
            placeholder={t('color')}
            async={!isCreating ? update : false}
            error={errors.color}
            required
          />
          <ColorPicker
            name="text_color"
            label={t('text_color')}
            placeholder={t('text_color')}
            async={!isCreating ? update : false}
            error={errors.text_color}
            required
          />
        </div>
        <div className="grid md:grid-cols-2 gap-2">
          <FormSelect
            multiple
            name="practitioners"
            label={t('practitioners')}
            placeholder={t('practitioners')}
            async={!isCreating ? update : false}
            error={errors.practitioners}
            required
          >{practitioners.map(practitioner => <option key={practitioner.id} value={practitioner.id}>{practitioner.name}</option>)}</FormSelect>
          <FormSelect
            multiple
            name="addresses"
            label={t('addresses')}
            placeholder={t('addresses')}
            async={!isCreating ? update : false}
            error={errors.addresses}
            required
          >{addresses.map(address => <option key={address.id} value={address.id}>{address.street}, {address.city}</option>)}</FormSelect>
        </div>
        <div className="self-center">
          {isCreating && <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : t('forms:save')}</PrimaryBtn>}
        </div>
      </div>
    </Form>
    )}
  </Formik>
}