import Delivery from "../components/cart/checkout/Delivery";
import PaymentType from "../components/cart/checkout/PaymentType";
import Overview from "../components/cart/checkout/Overview";
import {useState} from "react";
import CheckOutNav from "../components/cart/checkout/CheckOutNav";
import SecuredPage from "./secured/SecuredPage";
import Error404 from "./errors/Error404";
import {useCart} from "../providers/CartProvider";

export default function Checkout() {
  const [minIndex, setMinIndex] = useState(0)
  const [selected, setSelected] = useState(0)
  const cart = useCart()

  const nextStep = () => {
    let tmp = selected + 1
    setSelected(tmp)
    setMinIndex(prev => tmp > prev ? prev + 1 : prev)
  }

  const previousStep = () => {
    setSelected(prev => prev - 1)
  }

  if (!cart.id)
    return <Error404/>

  return <SecuredPage>
    <div className="mt-32 p-2 sm:p-10">
      <div className="p-5">
        <div className="mx-4 p-4">
          <CheckOutNav selected={selected} minIndex={minIndex}/>
        </div>
        <div className="mt-8 p-4">
          <div className={selected === 0 ? '' : 'hidden'}><Delivery nextStep={nextStep} previousStep={previousStep}/></div>
          <div className={selected === 1 ? '' : 'hidden'}><Overview nextStep={nextStep} previousStep={previousStep}/></div>
          <div className={selected === 2 ? '' : 'hidden'}><PaymentType nextStep={nextStep} previousStep={previousStep}/></div>
        </div>
      </div>
    </div>
  </SecuredPage>
}