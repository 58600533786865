import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

export default function HelmetSEO({page}) {
  const {t, i18n} = useTranslation(['seo'])
  return <Helmet>
    <html lang={i18n.language} />
    <title>{t(`${page}.title`)}</title>
    <meta name="description" content={t(`${page}.description`)}/>
    <meta name="keywords" content={t(`${page}.keywords`)}/>
  </Helmet>
}