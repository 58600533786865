import BasicBtn from "../BasicBtn";

export default function SolidBtn({className, children, ...props}) {
  return <BasicBtn className={`
    hover:bg-opacity-90 text-white
    ${className || ''}
  `}
   {...props}
  >
    {children}
  </BasicBtn>
}