import React, {useCallback, useContext, useState} from "react";
import ToastContainer from "../components/common/toasts/ToastContainer";
import {useTranslation} from "react-i18next";

export const ToastContext = React.createContext(undefined)
export const useToast = () => useContext(ToastContext)
const DEFAULT_INTERVAL = 6000;

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default function ToastProvider({children}) {
  const {t} = useTranslation(['translation', 'errors'])
  const [data, setData] = useState([])

  const Push = useCallback((header, message, color, lifetime) => {
    if (message) {
      const new_item = {
        id: uuidv4(),
        message: message,
        header: header,
        lifetime: lifetime ? lifetime : DEFAULT_INTERVAL,
        color: color,
      };

      setData((prevState) => [...prevState, new_item]);
    }
  }, [])

  const PushError = useCallback((message, lifetime) => {
    Push(t('error'), message, "red-600", lifetime)
  }, [Push, t])
  const PushSuccess = useCallback((message, lifetime) => {
    Push(t('success'), message, "green-600", lifetime)
  }, [Push, t])
  const PushWarning = useCallback((message, lifetime) => {
    Push(t('warning'), message, "secondary", lifetime)
  }, [Push, t])
  const PushInfo = useCallback((message, lifetime) => {
    Push(t('info'), message, "primary", lifetime)
  }, [Push, t])
  const PushUnKnown = useCallback(lifetime => {
    PushError(t('errors:unknown'), lifetime)
  }, [PushError, t])

  const ToastContextd = useCallback(() => {
    return {
      data: data,
      pushError: PushError,
      pushUnKnown: PushUnKnown,
      pushWarning: PushWarning,
      pushSuccess: PushSuccess,
      pushInfo: PushInfo,
      push: Push,
      async remove(id) {
        setData((prevState) => prevState.filter((e) => e.id !== id));
      },
    }
  }, [data, PushError, PushWarning, PushSuccess, PushInfo, Push, PushUnKnown])

  return <ToastContext.Provider value={ToastContextd()}>
    <ToastContainer/>
    {children}
  </ToastContext.Provider>
}