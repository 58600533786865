import TemplateModal from "./TemplateModal";
import {ExclamationIcon} from "@heroicons/react/outline";
import DangerBtn from "../btns/solid/DangerBtn";
import OutlineSecondaryBtn from "../btns/outline/OutlineSecondaryBtn";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import Spinner from "../Spinner";

export default function DeleteDialogModal({closeModal, title, description, action, ...props}) {
  const {t} = useTranslation('forms')
  const [clicked, setClicked] = useState(false)

  const actionBtn = async () => {
    setClicked(true)
    await action()
    setClicked(false)
  }
  return <TemplateModal closeModal={closeModal} className="max-w-md" {...props}>
    <div className="sm:flex sm:items-start">
      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {title}
        </h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            {description}
          </p>
        </div>
      </div>
    </div>
    <div className="mt-10 flex flex-row justify-center space-x-2">
      <OutlineSecondaryBtn onClick={closeModal}>{t('cancel')}</OutlineSecondaryBtn>
      <DangerBtn onClick={actionBtn} className="flex flex-row justify-around" disabled={clicked}>{clicked && <Spinner className="h-5 w-5 mr-3"/>}OK</DangerBtn>
    </div>
  </TemplateModal>
}