export default function Card({children, className, innerRef, ...props}) {
  return <div
    className={`
      p-4
      border rounded-lg shadow-md
      ${className || ''}
    `}
    ref={innerRef}
    {...props}
  >
    {children}
  </div>
}