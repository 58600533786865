import logo from '../../../resources/images/logo.png'
import {useState} from "react";
import LanguageSwitch from "./LanguageSwitch";
import Routes from "./Routes";
import UserBadge from "./UserBadge";
import BurgerMenu from "./BurgerMenu";
import {useAuth} from "react-oauth2-pkce";
import SecondaryBtn from "../btns/solid/SecondaryBtn";
import {useTranslation} from "react-i18next";
import Cart from "../../cart/Cart";

export default function Navbar(props) {
  const [expanded, setExpanded] = useState(false)
  const {authService} = useAuth()
  const {t} = useTranslation('auth')

  const login = () => {
    localStorage.removeItem('user')
    authService.authorize()
  }

  return (
    <header className={props.className + `
      sticky z-30
      top-0
      bg-white
      border-solid border-b-2 border-secondary
      flex flex-col lg:flex-row
      justify-between
      p-8
      `}>
      <div className="flex flex-row justify-between items-center">
        <img src={logo} className="object-contain h-16" alt="swiss veda logo"/>
        <div className="flex space-x-4 items-center">
          <div className="lg:hidden">
            <Cart/>
          </div>
          <BurgerMenu
            onClick={() => setExpanded(!expanded)}
            open={expanded}
          />
        </div>
      </div>
      <div className={`
        ${expanded ? 'flex' : 'hidden'}
        flex-col lg:flex lg:flex-row
        lg:flex-grow
        items-center lg:justify-between
      `}>
        <nav className="self-start lg:self-auto w-full">
          <Routes onClick={() => setExpanded(false)}/>
        </nav>
        <div className="
          flex flex-row
          justify-between
          items-center
          pt-4 lg:pt-0
          w-full lg:w-auto
          space-x-2
        ">
          <div className="hidden lg:block">
            <Cart/>
          </div>
          <LanguageSwitch/>
          {authService.isAuthenticated() ?
            <UserBadge onRedirect={() => setExpanded(false)}/>
            : <SecondaryBtn onClick={login}>{t('login')}</SecondaryBtn>
          }
        </div>
      </div>
    </header>
  )
}