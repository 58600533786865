import TextHeader from "../components/common/headers/TextHeader";
import PageWithPictureHeader from "../components/common/Layout/PageWithPictureHeader";
import {useTranslation} from "react-i18next";
import {useCallback, useEffect, useState} from "react";
import * as EventAPI from "../api/events"
import {useToast} from "../providers/ToastProvider";
import {groupByFnc} from "../utils/utils";
import {momentWithLocales} from "../utils/dates";
import Card from "../components/common/Card";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import UnstyledLink from "../components/common/links/UnstyledLink";
import SubscribeBtn from "../components/Events/SubscribeBtn";
import Price from "../components/common/Price";
import Date from "../components/Events/Date";
import Time from "../components/Events/Time";
import Availability from "../components/Events/Availability";
import {CalendarIcon, CashIcon, ClockIcon, LocationMarkerIcon} from "@heroicons/react/outline";

function Month({title, children}) {
  return <Card>
    <div>
      <h2 className="border-b-2 border-secondary capitalize max-w-max">{title}</h2>
    </div>
    <div className="flex flex-col space-y-4 divide-y pt-2 mt-2">
      {children}
    </div>
  </Card>
}

function MonthEvent({event, update}) {
  const {t} = useTranslation(['pages', 'translation', 'routes'])
  return <div className="pt-2">
    <div className="text-xl font-semibold">{event.title}</div>
    <div className="mt-2 text-lg flex items-center"><LocationMarkerIcon className="w-5 h-5 mr-2"/> {event.location}</div>
    <div className="text-lg text-orange-600 flex flex-col">
      <div className="mr-2 flex items-center"><CalendarIcon className="w-5 h-5 mr-2"/> <Date event={event}/></div>
      <div className="flex items-center"><ClockIcon className="w-5 h-5 mr-2"/> <Time event={event}/></div>
    </div>
    <div className="text-sm font-semibold text-secondary my-2 flex items-center"><CashIcon className="w-5 h-5 mr-2"/> <Price>{event.price}</Price></div>
    <Availability event={event}/>

    <div className="flex space-x-2 mt-3 items-center">
      <UnstyledLink to={t('routes:event.link', {event: event.id})}><PrimaryBtn>{t('translation:see')}</PrimaryBtn></UnstyledLink>
      <SubscribeBtn event={event} update={update}/>
    </div>
  </div>
}

export default function Events() {
  const [events, setEvents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const {t, i18n} = useTranslation(['routes', 'errors'])
  const toast = useToast()

  const update = useCallback(() => {
    EventAPI.index()
      .then(response => groupByFnc(response, 'start_date', date => momentWithLocales(date).locale(i18n.language).format("MMMM YYYY")))
      .then(setEvents)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  // eslint-disable-next-line
  }, [i18n.language])

  useEffect(() => {
    update()
  }, [update])

  return <PageWithPictureHeader
    headerStyle="bg-events-sm md:bg-events"
    headerContent={<TextHeader>{t('events.name')}</TextHeader>}
    isLoading={isLoading}
  >
    <h1>{t('events.name')}</h1>
    <div className="mt-8 grid gap-y-4 md:grid-cols-2 md:gap-x-4 2xl:grid-cols-3">
      {Object.keys(events).map((key, id) => <Month key={id} title={key}>
        {events[key].map((event, idx) => <MonthEvent key={idx} event={event} update={update}/>)}
      </Month>)}
    </div>
  </PageWithPictureHeader>
}