import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as OrderAPI from "../api/orders";
import {Form, Formik} from "formik";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import FormSelect from "../components/common/inputs/FormSelect";
import Input from "../components/common/inputs/Input";
import Spinner from "../components/common/Spinner";

function Article({article, id, errors}) {
  const {t} = useTranslation('pages')
  return <div className="grid grid-cols-5 items-center gap-x-2">
    <div className="w-24 h-24 max-w-full flex justify-center">
      <img className="max-h-full max-w-full rounded-md" src={article?.pictures[0].picture} alt="article"/>
    </div>
    <div key={article.id} className="flex space-x-1 col-span-3">
      <div><b>{article.quantity}x</b></div>
      <div>{article.name}</div>
    </div>
    <div>
      <Input type="hidden" name={`articles.${id}.id`}/>
      {article.can_return ?
        <FormSelect
          name={`articles.${id}.quantity`}
          label="Quantité a retourner"
          error={errors[`articles.${id}.quantity`] || errors[`articles.${id}`]}
        >
          {Array.apply(null, Array(article.quantity + 1)).map((_, i) => <option key={i} value={i}>{i}</option>)}
        </FormSelect>
      :
        t('orders.cannot-return')
      }
    </div>
  </div>
}

export default function OrderBackForm({order, closeModal}) {

  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [otherError, setOtherError] = useState("")
  const {t} = useTranslation(['fields', 'forms'])

  const [formVals, setFormVals] = useState({})

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
    setOtherError("")
  }

  const apply = data => {
    reset()
    return OrderAPI.sendBack(order.id, {
        articles: data.articles.filter(article => article.quantity > 0)
      })
      .then(closeModal)
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      if (error.response.data.errors)
        setErrors(error.response.data.errors)
      else
        setOtherError(error.response.data.error)
    }
  }

  useEffect(() => {
    setFormVals({
      articles: order.articles?.map(article => ({id: article.id, quantity: 0})) || []
    })
  }, [order])
  return <Formik
    initialValues={formVals}
    enableReinitialize
    onSubmit={apply}
  >
    {({isSubmitting}) => (
    <Form>
      <div className="flex flex-col space-y-4">
        {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
        {otherError && <div className="text-red-600 text-sm">{otherError}</div> }
        {order.articles?.map((article, id) => <Article key={article.id} id={id} article={article} errors={errors}/>)}
        <div className="sm:text-center">
          <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : t('forms:save')}</PrimaryBtn>
        </div>
      </div>
    </Form>
    )}
  </Formik>
}