import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import {ArrowLeftIcon, CashIcon, ShoppingCartIcon} from "@heroicons/react/outline";
import {useEffect, useState} from "react";
import {useParams, useHistory} from "react-router-dom";
import * as ArticleAPI from "../api/articles"
import LoadingWrapper from "../components/common/placeholders/LoadingWrapper";
import PromoCodeInfos from "../components/shop/admin/PromoCodeInfos";
import ImgSlider from "../components/shop/ImgSlider";
import {useTranslation} from "react-i18next";
import Error404 from "./errors/Error404";
import Spinner from "../components/common/Spinner";
import {useCart} from "../providers/CartProvider";
import Price from "../components/common/Price";
import OutlineSecondaryBtn from "../components/common/btns/outline/OutlineSecondaryBtn";
import {useToast} from "../providers/ToastProvider";

function ArticleSkus({article_skus}) {
  const [selected, setSelected] = useState({})
  const [isAdding, setIsAdding] = useState(false)

  const {t} = useTranslation('translation')
  const cart = useCart()
  const toast = useToast()

  useEffect(() => {
    if (article_skus)
      setSelected(article_skus[0] || {})
  }, [article_skus])

  const addToCart = () => {
    setIsAdding(true)
    cart.addItem(selected.id, 1)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsAdding(false))
  }

  return <div className="w-full flex flex-col space-y-4">
    <div className="font-semibold text-lg flex items-center"><CashIcon className="w-5 h-5 mr-2"/> <Price solde={selected.solde}>{selected.total_price}</Price></div>
    <div className="flex space-x-2">
      {article_skus?.length > 1 ? article_skus?.map(sku => <OutlineSecondaryBtn key={sku.id} className={selected.id === sku.id ? 'bg-secondary text-white' : ''} onClick={() => setSelected(sku)} disabled={isAdding}>{sku.text}</OutlineSecondaryBtn>)
        : article_skus?.length > 0 && article_skus[0].text && <OutlineSecondaryBtn key={article_skus[0].id} className={selected.id === article_skus[0].id ? 'bg-secondary text-white' : ''} onClick={() => setSelected(article_skus[0])} disabled={isAdding}>{article_skus[0].text}</OutlineSecondaryBtn>}
    </div>
    <div>
      {selected.can_add ?
        <PrimaryBtn onClick={addToCart} disabled={isAdding} className="flex">{isAdding ?
          <Spinner className="w-5 h-5"/> : <><ShoppingCartIcon
            className="w-5 h-5 mr-2"/>{t('add-to-cart')}</>}</PrimaryBtn>
        : <div className="text-red-600 font-semibold">{t('no-stock')}</div>
      }
    </div>
  </div>
}

export default function Article() {
  const [article, setArticle] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const { itemId } = useParams()
  const {t, i18n} = useTranslation(['pages', 'translation'])
  const [is404, setIs404] = useState(false)
  const history = useHistory()
  const cart = useCart()

  const handleError = error => {
    if (error.response.status === 404) {
      setIs404(true)
    }
  }

  useEffect(() => {
    ArticleAPI.show(itemId)
      .then(setArticle)
      .catch(handleError)
      .then(() => setIsLoading(false))
  }, [itemId, cart.articles, i18n.language])

  if (is404) {
    return <Error404/>
  }
  return <div className="mt-32 p-2 sm:p-10">
    <LoadingWrapper isLoading={isLoading}>
      <div>
        <PrimaryBtn className="flex" onClick={() => {history.goBack()}}><ArrowLeftIcon className="w-5 h-5 mr-2"/>{t('translation:back')}</PrimaryBtn>
      </div>
      <div className="grid gap-4 md:grid-cols-2 xl:grid-cols-3 mt-6 px-4 relative">
        <div className="md:max-w-lg">
          <ImgSlider pictures={article.pictures?.map(pic => pic.picture) || []}/>
        </div>
        <div className="flex flex-col space-y-4 md:pl-4 xl:col-span-2">
          <div className="pb-4">
            <div className="mb-4"><h2 className="uppercase font-bold">{article.name}</h2></div>
            <ArticleSkus article_skus={article.articles}/>
          </div>
          <div className="content pl-4" dangerouslySetInnerHTML={{__html: article.description}}/>
          {article.type?.id === 'promo-code' && <div>
            <p className="text-xl font-semibold">{t('promo-code-check.promo-details')}</p>
             <PromoCodeInfos article={article}/>
          </div>}
        </div>
      </div>
    </LoadingWrapper>
  </div>
}