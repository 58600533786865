import OutlineBtn from "./OutlineBtn";

export default function OutlinePrimaryBtn({className, children, ...props}) {
  return <OutlineBtn className={`
    border-primary
    hover:bg-primary
    ${className || ''}
  `}
                   {...props}
  >
    {children}
  </OutlineBtn>
}