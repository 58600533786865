import ReactPlayer from "react-player";
import {useEffect, useState} from "react";

export default function Media ({link}) {
  const [type, setType] = useState("image")

  useEffect(() => {
    const xhttp = new XMLHttpRequest();
    xhttp.open('HEAD', link);
    xhttp.onreadystatechange = function () {
      if (this.readyState === this.DONE) {
        const contentType = this.getResponseHeader("Content-Type")
        if (contentType?.includes("image")) {
          setType("image")
        } else {
          setType("video")
        }
      }
    };
    xhttp.send();
  }, [link])

  return type === "video" ?
      <ReactPlayer width='100%' height='100%' url={link}/>
      : <img className="h-full w-full object-cover" src={link} alt=""/>

}