import {Form, Formik} from "formik";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Input from "../components/common/inputs/Input";
import DangerBtn from "../components/common/btns/solid/DangerBtn";
import {PlusIcon, TrashIcon} from "@heroicons/react/outline";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import * as articleAPI from "../api/articles";
import Spinner from "../components/common/Spinner";

const defaultArticleSku = {
  text: "",
  price: "",
  solde: "",
  quantity: ""
}

export default function ArticleSkuForm({articleId, article_sku, isCreating, remove, store, articleTypeId, can_delete}) {
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [sending, setSending] = useState(false)

  const {t} = useTranslation(['fields', 'errors'])

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  const reset = () => {
    setErrors({})
    setShowGlobalError(false)
  }

  const update = data => {
    reset()
    return articleAPI.updateArticleSku(articleId, article_sku.id, data)
      .catch(handleError)
  }

  const create = (data, {resetForm}) => {
    reset()
    setSending(true)
    articleAPI.addArticleSku(articleId, data)
      .then(response => {
        resetForm(defaultArticleSku)
        store(response)
      })
      .catch(handleError)
      .then(() => setSending(false))
  }

  const handleRemove = async () => {
    setSending(true)
    await remove()
    setSending(false)
  }

  return <Formik
    initialValues={article_sku || defaultArticleSku}
    enableReinitialize
    onSubmit={create}
  >
    <Form>
      {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
      <div className="grid py-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
        {articleTypeId === 'physic' && <Input
          label={t('sku_id')}
          placeholder={t('sku_id')}
          name="text"
          error={errors.text}
          async={!isCreating ? update : null}
          required
        />}
        <Input
          type="number"
          label={t('price')}
          placeholder={t('price')}
          name="price"
          error={errors.price}
          async={!isCreating ? update : null}
          required
        />
        <Input
          type="number"
          label={t('solde')}
          placeholder={t('solde')}
          name="solde"
          async={!isCreating ? update : null}
          error={errors.solde}
        />
        {articleTypeId === 'physic' && <Input
          type="number"
          label={t('quantity')}
          placeholder={t('quantity')}
          name="quantity"
          error={errors.quantity}
          async={!isCreating ? update : null}
          required
        />}
        <div className="col-start-1">
          {isCreating ?
            <PrimaryBtn type="submit" disabled={sending}>
              {sending ? <Spinner className="w-5 h-5"/> : <PlusIcon className="w-4 h-4"/>}
            </PrimaryBtn>
            : can_delete && <DangerBtn type="button" onClick={handleRemove} disabled={sending}>
              {sending ? <Spinner className="w-5 h-5"/> : <TrashIcon className="w-4 h-4"/>}
            </DangerBtn>
          }
        </div>
      </div>
    </Form>
  </Formik>
}