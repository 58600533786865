import BackOffice from "../../../../components/common/Layout/BackOffice";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as ExerciceAPI from "../../../../api/exercices"
import {useToast} from "../../../../providers/ToastProvider";
import PrimaryBtn from "../../../../components/common/btns/solid/PrimaryBtn";
import Modal from "../../../../components/common/modals/Modal";
import ExerciceForm from "../../../../forms/ExerciceForm";
import Exercice from "../../../../components/exercices/Exercice";



export default function Exercices() {
  const [isLoading, setIsLoading] = useState(true)
  const [exercices, setExercices] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const {t, i18n} = useTranslation(['routes', 'pages'])
  const toast = useToast()

  const update = () => {
    ExerciceAPI.adminIndex()
      .then(setExercices)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  }

  const closeFormModal = () => {
    setOpenModal(false)
    update()
  }

  useEffect(() => {
    update()
    // eslint-disable-next-line
  }, [i18n.language])

  return <BackOffice role='calendar' isLoading={isLoading}>
    <h1 className="mb-8">{t('admin-exercices.name')}</h1>
    <div className="mb-6">
      <PrimaryBtn onClick={() => setOpenModal(true)}>{t('pages:exercices.new')}</PrimaryBtn>
      <Modal
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
        title={t('pages:exercices.create')}
        className="w-full max-w-xl">
        <ExerciceForm isCreating closeModal={closeFormModal}/>
      </Modal>
    </div>
    <div className="mb-6 space-y-4">
      {exercices.map((exercice, id) => <Exercice key={id} exercice={exercice} update={update}/>)}
    </div>
  </BackOffice>
}