import {useTranslation} from "react-i18next";
import {useCart} from "../../../providers/CartProvider";
import Articles from "../Articles";
import PrimaryBtn from "../../common/btns/solid/PrimaryBtn";
import OutlineSecondaryBtn from "../../common/btns/outline/OutlineSecondaryBtn";
import Price from "../../common/Price";
import Label from "../../common/inputs/Label";
import {GiftIcon, TrashIcon} from "@heroicons/react/outline";
import {useState} from "react";
import Info from "../../common/inputs/Info";
import Spinner from "../../common/Spinner";
import {useToast} from "../../../providers/ToastProvider";
import TransparentBtn from "../../common/btns/TransparentBtn";
function ArticleRow({article}) {
  return <>
    <p>{article.quantity}x {article.name}</p>
    <div><Price>{article.total_price * article.quantity}</Price></div>
  </>
}

function Recap ({nextStep, previousStep}) {
  const cart = useCart()
  const {t} = useTranslation(['pages', 'translation'])
  return <div className="bg-gray-100 px-4 py-6">
    <h3>{t('checkout.overview.summary')}</h3>
    <hr className="border-secondary"/>
    <div className="grid grid-cols-2">
      {cart.articles?.map(article => <ArticleRow key={article.id} article={article}/> )}
    </div>
    <hr className="border-secondary"/>
    <div className="grid grid-cols-2">
      <p className="font-semibold text-lg">{t('checkout.overview.sub-total')}</p>
      <div className="font-semibold text-lg"><Price>{cart.sub_total}</Price></div>
      <p>{t('checkout.overview.delivery')}</p>
      <div><Price>{cart.delivery}</Price></div>
      <p>{t('checkout.overview.coupon')}</p>
      <p>-{cart.reduction}</p>
    </div>
    <hr className="border-secondary"/>
    <div className="grid grid-cols-2">
      <p className="font-semibold text-lg">{t('checkout.overview.total')}</p>
      <div className="font-semibold text-lg"><Price>{cart.total}</Price></div>
    </div>
    <div className="flex space-x-2 mt-4">
      <OutlineSecondaryBtn onClick={previousStep}>{t('translation:back')}</OutlineSecondaryBtn>
      <PrimaryBtn onClick={nextStep}>{t('checkout.overview.to-payment')}</PrimaryBtn>
    </div>
  </div>
}

function Coupon() {
  const [value, setValue] = useState("")
  const [isValidating, setIsValidating] = useState(false)
  const [errors, setErrors] = useState({})
  const {t} = useTranslation(['fields', 'errors', 'forms'])
  const cart = useCart()
  const toast = useToast()
  const handleError = error => {
    if (error.response.status !== 422) {
      toast.pushError(t('errors:unknown'))
    } else {
      setErrors(error.response.data.errors)
    }
  }

  const validate = () => {
    setErrors({})
    setIsValidating(true)
    cart.addCoupon(value)
      .catch(handleError)
      .then(() => {
        setIsValidating(false)
        setValue("")
      })
  }

  if (cart.promo_id) {
    return <div>
      <Label>{t('promo-code')}</Label>
      <div className="flex space-x-2">
        <p className="ml-2">{cart.promo_id}: -{cart.reduction}</p>
        <TransparentBtn className="text-red-600" onClick={() => cart.removeCoupon()}><TrashIcon className="w-4 h-4"/> </TransparentBtn>
      </div>
    </div>
  }
  return <div>
    <Label>{t('promo-code')}</Label>
    <div className="relative flex w-full flex-wrap items-stretch">
      <span className="z-10 h-full leading-snug absolute text-center text-primary absolute bg-transparent rounded items-center justify-center w-8 pl-2 py-2">
        <GiftIcon/>
      </span>
      <input
        className="px-2 py-2 placeholder-gray-400 text-primary relative bg-white rounded text-sm border border-primary outline-none focus:outline-none focus:ring focus:border-secondary ring-secondary w-full pl-10"
        placeholder={t('promo-code')}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </div>
    <Info error={errors.promo_id}/>
    <div className="mt-2"><PrimaryBtn disabled={isValidating} onClick={validate}>{isValidating ? <Spinner className="w-5 h-5"/> : t('forms:validate')}</PrimaryBtn></div>
  </div>
}

export default function Overview({nextStep, previousStep}) {
  const {t} = useTranslation('pages')
  const cart = useCart()
  return <div>
    <h2 className="text-center">{t('checkout.overview.title')}</h2>
    <div className="mt-8 md:flex md:space-x-4 md:justify-center lg:space-x-10">
      <div>
        <Articles articles={cart.articles}/>
        <div className="my-4">
          <Coupon/>
        </div>
      </div>
      <Recap nextStep={nextStep} previousStep={previousStep}/>
    </div>
  </div>
}