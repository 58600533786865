import BackOffice from "../../../../components/common/Layout/BackOffice";
import {useTranslation} from "react-i18next";
import PrimaryBtn from "../../../../components/common/btns/solid/PrimaryBtn";
import Modal from "../../../../components/common/modals/Modal";
import {useEffect, useState} from "react";
import * as PractitionersAPI from "../../../../api/practitioners";
import Practitioner from "../../../../components/practitioners/Practitioner";
import PractitionerGroupForm from "../../../../forms/PractitionerGroupForm";
import {useToast} from "../../../../providers/ToastProvider";

export default function Practitioners() {
  const {t} = useTranslation(['routes', 'pages'])
  const [formModal, setFormModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [practitioners, setPractitioners] = useState([])
  const toast = useToast()

  const closeModal = () => {
    setFormModal(false)
    update()
  }

  const update = () => {
    PractitionersAPI.index()
      .then(setPractitioners)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  }

  //eslint-disable-next-line
  useEffect(() => update(), [])

  return <BackOffice role="calendar-admin" isLoading={isLoading}>
    <h1>{t('practitioners.name')}</h1>
    <div className="mt-5 flex flex-row justify-start space-x-2">
      <PrimaryBtn onClick={() => setFormModal(true)}>{t('pages:practitioners.create')}</PrimaryBtn>
      <Modal
        title={t('pages:practitioners.create')}
        isOpen={formModal}
        closeModal={closeModal}
        className="w-full max-w-xl"
      >
        <PractitionerGroupForm closeModal={closeModal} isCreating/>
      </Modal>
    </div>
    <div className="flex flex-col divide-y space-y-4 mt-10">
      {practitioners.map(practitioner => <Practitioner key={practitioner.id} practitioner={practitioner} update={update}/>)}
    </div>
  </BackOffice>
}