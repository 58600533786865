import React, {useEffect, useState} from "react";
import {PlusIcon, TrashIcon} from "@heroicons/react/outline";
import DangerBtn from "../common/btns/solid/DangerBtn";
import TabsWrapper from "../common/tabs/TabsWrapper";
import {Tab} from "@headlessui/react";
import SearchField from "../common/inputs/SearchField";
import {search} from "../../api/events";
import * as ExerciceAPI from "../../api/exercices"
import {useToast} from "../../providers/ToastProvider";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import PrimaryBtn from "../common/btns/solid/PrimaryBtn";
import Spinner from "../common/Spinner";
import Input from "../common/inputs/Input";
import {getFormattedDate} from "../../utils/dates";

function EventSearchRow({translated_title, start_date, location, nb_participants, className, onClick}) {
  const {t, i18n} = useTranslation(['pages'])
  return <div className={`py-2 px-4 ${className || ''}`} onClick={onClick}>
    <div className="flex flex-col">
      <p>{translated_title}</p>
      <small className="text-gray-500">{getFormattedDate(start_date, i18n.language)}</small>
      <small className="text-gray-500">{location}</small>
      <small className="text-gray-500">{t('exercices.nb-participants')}: {nb_participants}</small>
    </div>
  </div>
}

export default function Participants({exercice}) {

  const defaultValues = {
    email: ""
  }

  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const {t} = useTranslation(['pages', 'fields'])

  const [emails, setEmails] = useState([])
  const toast = useToast()

  const addUsersFromEvent = ({id}) => {
    ExerciceAPI.addEventUsers(exercice.id, id)
      .then(setEmails)
      .catch(handleError)
  }

  const addUser = ({email}, {resetForm}) => {
    setShowGlobalError(false)
    setErrors({})
    return ExerciceAPI.addUser(exercice.id, email)
      .then(setEmails)
      .then(() => resetForm(defaultValues))
      .catch(error => {
        if (error.response.status !== 422) {
          setShowGlobalError(true)
        } else {
          setErrors(error.response.data.errors)
        }
      })
  }

  const removeUser = (email) => {
    ExerciceAPI.removeUser(exercice.id, email)
      .then(setEmails)
      .catch(handleError)
  }

  const handleError = () => {
    toast.pushUnKnown()
  }

  useEffect(() => {
    setEmails(exercice.emails)
  }, [exercice.emails])

  return <div>
    <div>
      <h4>{t('exercices.add-participants')}</h4>
      <TabsWrapper titles={[t('exercices.add-from-event'), t('exercices.add-from-email')]}>
        <Tab.Panel>
          <SearchField fetchData={search} searchRow={<EventSearchRow/>} update={addUsersFromEvent}/>
        </Tab.Panel>
        <Tab.Panel>
          <Formik
            initialValues={defaultValues}
            onSubmit={addUser}
          >
            {({isSubmitting}) => (
              <Form>
                {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
                <div className="flex space-x-2 items-center">
                  <div className="w-full">
                    <Input type="email" name="email" error={errors.email} label={t('fields:email')} required/>
                  </div>
                  <div className="">
                    <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : <PlusIcon className="w-5 h-5"/>} </PrimaryBtn>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Tab.Panel>
      </TabsWrapper>
    </div>
    <div>
      <h4>{t('exercices.participants')}</h4>
      <ul className="flex flex-col space-y-2 ml-2">
        {emails.length > 0 ? emails.map((email, id) => <li key={id}>
          <div className="flex justify-between">
            <p>{email}</p>
            <div>
              <DangerBtn onClick={() => removeUser(email)}><TrashIcon className="w-5 h-5"/></DangerBtn>
            </div>
          </div>
        </li>) : <li>{t('exercices.no-participant')}</li>}
      </ul>
    </div>
  </div>
}