import OutlineBtn from "./OutlineBtn";

export default function OutlineSecondaryBtn({className, children, ...props}) {
  return <OutlineBtn className={`
    border-secondary
    hover:bg-secondary
    ${className || ''}
  `}
                     {...props}
  >
    {children}
  </OutlineBtn>
}