import Modal from "../common/modals/Modal";
import {useTranslation} from "react-i18next";
import OrderBackForm from "../../forms/OrderBackForm";

export default function SendBackModal({order, isOpen, closeModal}) {
  const {t} = useTranslation('pages')
  return <Modal
    closeModal={closeModal}
    isOpen={isOpen}
    title={`${t('orders.send-back')}`}
  >
   <OrderBackForm order={order} closeModal={closeModal}/>
  </Modal>
}