import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as ContentAPI from "../api/contents";
import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import DangerBtn from "../components/common/btns/solid/DangerBtn";
import TranslatedInputs from "../components/common/inputs/TranslatedInputs";
import Editor from "../components/common/inputs/Editor";
import {convertDotDataToJson} from "../utils/formConverter";
import DeleteDialogModal from "../components/common/modals/DeleteDialogModal";
import Media from "../components/partners/Media";
import Spinner from "../components/common/Spinner";

export default function ContentForm({isCreating, content, resource, withTitle, withMedia, canDelete, closeModal, onChange}) {
  const defaultValues = {
    title: {
      fr: "",
      de: "",
      en: ""
    },
    text: {
      fr: "",
      de: "",
      en: ""
    },
    video_url: withMedia ? "" : undefined
  }
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [formValues, setFormValues] = useState(defaultValues)
  const {t} = useTranslation(['fields', 'forms', 'pages'])

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
  }

  const create = (data, {resetForm}) => {
    reset()
    return ContentAPI.store(resource, convertDotDataToJson(data))
      .then(() => {
        resetForm(defaultValues)
        closeModal()
      })
      .catch(handleError)
  }

  const update = data => {
    reset()
    return ContentAPI.update(content.id, convertDotDataToJson(data))
      .catch(handleError)
  }

  const handleDelete = () => {
    return ContentAPI.destroy(content.id)
      .then(() => {
        setDeleteModal(false)
        onChange()
      })
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  useEffect(() => {
    setFormValues({
      title: {
        fr: content?.title_translations?.fr || "",
        de: content?.title_translations?.de || "",
        en: content?.title_translations?.en || ""
      },
      text: {
        fr: content?.text_translations?.fr || "",
        de: content?.text_translations?.de || "",
        en: content?.text_translations?.en || ""
      },
      video_url: withMedia ? content?.video_url || "" : undefined
    })
  }, [content, withMedia])
  return <Formik
    initialValues={formValues}
    enableReinitialize
    onSubmit={create}
  >
    {({values, isSubmitting}) => (
    <Form>
      {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
      <div className="flex flex-col space-y-4">
        <TranslatedInputs
          errors={errors}
        >
          {withTitle && <Input
            label={t('title')}
            placeholder={t('title')}
            name="title"
            async={!isCreating ? update : false}

          />}
          <Editor
            label={t('text')}
            name="text"
            async={!isCreating ? update : false}
            required
          />
        </TranslatedInputs>
        {withMedia && <div className="md:w-2/5 h-80 mb-8 bg-yellow-300"><Media link={values.video_url}/></div>}
        {withMedia && <Input
          name="video_url"
          placeholder={t('media')}
          label={t('media')}
          async={!isCreating ? update : false}
          error={errors.video_url}
          required
        />}
        <div className="ml-2">
          {isCreating && <PrimaryBtn type="submit" disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/>: t('forms:save')}</PrimaryBtn>}
          {canDelete && <>
            <DangerBtn type="button" onClick={() => setDeleteModal(true)}>{t('forms:delete')}</DangerBtn>
            <DeleteDialogModal
              isOpen={deleteModal}
              closeModal={() => setDeleteModal(false)}
              title={t('pages:contents.delete.title', {name: content.title})}
              description={t('pages:contents.delete.description', {name: content.title})}
              action={handleDelete}
            />
          </>}
        </div>
      </div>
    </Form>
    )}
  </Formik>
}