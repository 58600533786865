import BackOffice from "../../../../components/common/Layout/BackOffice";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as AddressesAPI from "../../../../api/addresses";
import PrimaryBtn from "../../../../components/common/btns/solid/PrimaryBtn";
import Modal from "../../../../components/common/modals/Modal";
import Address from "../../../../components/addresses/Address";
import AddressForm from "../../../../forms/AddressForm";
import {useToast} from "../../../../providers/ToastProvider";

export default function Addresses() {
  const {t} = useTranslation(['routes', 'pages'])
  const [formModal, setFormModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [addresses, setAddresses] = useState([])
  const toast = useToast()

  const closeModal = () => {
    setFormModal(false)
    update()
  }

  const update = () => {
    AddressesAPI.index()
      .then(setAddresses)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  }

  //eslint-disable-next-line
  useEffect(() => update(), [])

  return <BackOffice role="calendar-admin" isLoading={isLoading}>
    <h1>{t('addresses.name')}</h1>
    <div className="mt-5 flex flex-row justify-start space-x-2">
      <PrimaryBtn onClick={() => setFormModal(true)}>{t('pages:addresses.create')}</PrimaryBtn>
      <Modal
        title={t('pages:addresses.create')}
        isOpen={formModal}
        closeModal={closeModal}
        className="w-full max-w-xl"
      >
        <AddressForm closeModal={closeModal} isCreating/>
      </Modal>
    </div>
    <div className="flex flex-col divide-y space-y-4 mt-10">
      {addresses.map(address => <Address key={address.id} address={address} update={update}/>)}
    </div>
  </BackOffice>
}