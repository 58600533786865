import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import {useAppointment} from "../providers/AppointmentProvider";
import {createPublicSchedule} from "../api/appointment";
import Spinner from "../components/common/Spinner";
import {useToast} from "../providers/ToastProvider";
import Alert from "../components/common/Alert";

export default function PublicScheduleForm() {
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [otherError, setOtherError] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)
  const {t} = useTranslation(['fields', 'forms', 'pages'])
  const appointment = useAppointment()
  const toast = useToast()

  const defaultVals = {
    firstname: "",
    lastname: "",
    email: "",
    tel: "",
  }

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
    setOtherError("")
    setShowSuccess(false)
  }

  const create = (data, {resetForm}) => {
    reset()
    return createPublicSchedule({
      ...data,
      ...appointment.getFormData()
    })
      .then(() => {
        toast.pushSuccess(t('pages:appointment.success'))
        setShowSuccess(true)
        resetForm(defaultVals)
      })
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      if (error.response.data.errors)
        setErrors(error.response.data.errors)
      else {
        setOtherError(error.response.data.error)
        toast.pushError(error.response.data.error)
      }
    }
  }

  return <Formik
    initialValues={defaultVals}
    onSubmit={create}
  >
    {({ isSubmitting }) => (
      <Form>
        <div className="flex flex-col space-y-4">
          {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
          {otherError && <div className="text-red-600 text-sm">{otherError}</div> }
          <div className="
            flex flex-col space-y-4
            sm:flex-row sm:space-x-2 sm:space-y-0
            md:flex-col md:space-y-4 md:space-x-0
            lg:flex-row lg:space-x-2 lg:space-y-0">
            <Input
              name="lastname"
              label={t('lastname')}
              placeholder={t('lastname')}
              error={errors.lastname}
              required
            />
            <Input
              name="firstname"
              label={t('firstname')}
              placeholder={t('firstname')}
              error={errors.firstname}
              required
            />
          </div>
          <div className="flex flex-col space-y-4">
            <Input
              name="email"
              label={t('email')}
              placeholder={t('email')}
              error={errors.email}
              required
            />
            <Input
              name="tel"
              label={t('tel')}
              placeholder={t('tel')}
              error={errors.tel}
            />
          </div>
          {showSuccess && <Alert color="green-600">{t('pages:appointment.success')}</Alert>}
          <div dangerouslySetInnerHTML={{__html: t('pages:appointment.warning')}}/>
          <div className="sm:text-center">
            <PrimaryBtn disabled={isSubmitting} className="flex flex-row">{isSubmitting && <Spinner className="h-5 w-5 mr-3"/>}{t('pages:calendar.create')}</PrimaryBtn>
          </div>
        </div>
      </Form>
    )}
  </Formik>
}