import {useTranslation} from "react-i18next";
import {useState} from "react";
import {useToast} from "../../providers/ToastProvider";
import {Tab} from "@headlessui/react"
import PrimaryBtn from "../common/btns/solid/PrimaryBtn";
import Spinner from "../common/Spinner";
import {CreditCardIcon, QrcodeIcon} from "@heroicons/react/outline";
import {Elements} from "@stripe/react-stripe-js";
import EventSubscribeStripe from "../../forms/EventSubscribeStripe";
import TabsWrapper from "../common/tabs/TabsWrapper";
import Alert from "../common/Alert";
import * as EventAPI from "../../api/events"
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function QrFacture({eventId, closeModal}) {
  const [isOrdering, setIsOrdering] = useState(false)
  const {t} = useTranslation(['pages', 'errors'])
  const toast = useToast()

  const handleClick = () => {
    setIsOrdering(true)
    EventAPI.subscribe(eventId,'bank')
      .then(() => {
        toast.pushSuccess(t('events.payment.success'))
        closeModal()
      })
      .catch(() => toast.pushError(t('errors:unknown')))
      .then(() => setIsOrdering(false))
  }

  return <Tab.Panel className="focus:outline-none">
    <Alert color="secondary">{t('events.payment.card-info')}</Alert>
    <PrimaryBtn disabled={isOrdering} onClick={handleClick}>
      {isOrdering ? <Spinner className="w-5 h-5"/> : t('events.payment.order')}
    </PrimaryBtn>
  </Tab.Panel>
}

function CardPayment({price, eventId, closeModal}) {
  const {t, i18n} = useTranslation('pages')
  return <Tab.Panel className="focus:outline-none">
    <div className="w-full max-w-lg mx-auto rounded-lg bg-white shadow-lg p-5 mt-10 focus:ring-secondary">
      <div className="w-full pt-1 pb-5">
        <div
          className="text-white bg-primary overflow-hidden rounded-full w-20 h-20 -mt-16 mx-auto shadow-lg flex justify-center items-center p-4">
          <CreditCardIcon className="w-full h-full"/>
        </div>
      </div>
      <div className="mb-10">
        <h1 className="text-center font-bold text-xl uppercase">{t('checkout.payment.secure-payment')}</h1>
      </div>
      <Elements stripe={stripePromise} options={{locale: i18n.language}}>
        <EventSubscribeStripe price={price} eventId={eventId} closeModal={closeModal}/>
      </Elements>
    </div>
  </Tab.Panel>
}

export default function Payment({price, eventId, closeModal}) {
  const {t} = useTranslation(['pages', 'translation'])
  return <div>
    <h2 className="text-center">{t('checkout.payment.title')}</h2>
    <div className="max-w-xl mx-auto">
      <TabsWrapper titles={[
        <div className="flex space-x-2 items-center"><CreditCardIcon className="w-5 h-5"/><p>{t('checkout.payment.card')}</p></div>,
        <div className="flex space-x-2 items-center"><QrcodeIcon className="w-5 h-5"/> <p>{t('checkout.payment.qr-code')}</p></div>,
      ]}>
        <CardPayment price={price} eventId={eventId} closeModal={closeModal}/>
        <QrFacture eventId={eventId} closeModal={closeModal}/>
      </TabsWrapper>
    </div>
  </div>
}