import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import * as ExerciceAPI from "../api/exercices";
import {Form, Formik} from "formik";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import Editor from "../components/common/inputs/Editor";
import TranslatedInputs from "../components/common/inputs/TranslatedInputs";
import {convertDotDataToJson} from "../utils/formConverter";
import DangerBtn from "../components/common/btns/solid/DangerBtn";
import DeleteDialogModal from "../components/common/modals/DeleteDialogModal";
import Spinner from "../components/common/Spinner";

export default function ExerciceQuestionForm({isCreating, closeModal, exercice, question}) {
  const defaultValues = {
    text: {
      fr: "",
      de: "",
      en: ""
    }
  }

  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const {t} = useTranslation(['fields', 'forms'])

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
  }

  const create = (data, {resetForm}) => {
    reset()
    return ExerciceAPI.addQuestion(exercice.id, convertDotDataToJson(data))
      .then((response) => {
        resetForm(defaultValues)
        closeModal(response)
      })
      .catch(handleError)
  }

  const update = data => {
    reset()
    return ExerciceAPI.updateQuestion(question.id, convertDotDataToJson(data))
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  return <Formik
    initialValues={question || defaultValues}
    enableReinitialize
    onSubmit={create}
  >
    {({isSubmitting}) => (
    <Form>
      {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
      <div className="flex flex-col space-y-4">
        <TranslatedInputs
          errors={errors}
        >
          <Editor
            label={t('text')}
            name="text"
            async={!isCreating ? update : false}
            required
          />
        </TranslatedInputs>
        <div className="sm:col-start-2 sm:text-center">
          {isCreating && <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : t('forms:save')}</PrimaryBtn>}
          {!isCreating && (<>
            <DangerBtn type="button" onClick={() => setDeleteModal(true)}>{t('forms:delete')}</DangerBtn>
            <DeleteDialogModal
              isOpen={deleteModal}
              closeModal={() => setDeleteModal(false)}
              title={t('pages:exercices.delete-question.title')}
              description={t('pages:exercices.delete-question.description')}
              action={() => {
                reset()
                ExerciceAPI.destroyQuestion(question.id)
                  .then(() => {
                    closeModal(question)
                  })
                  .catch(handleError)
                  .then(() => setDeleteModal(false))
              }}/>
          </>)}
        </div>
      </div>
    </Form>
    )}
  </Formik>
}