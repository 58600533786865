import api from "./api";
import {lang} from "../languages";
import resources from "./resources";

export function index() {
  return api().get(`/${lang()}/${resources.tasks}`)
    .then(tasks => tasks.sort((a,b) => a.name.localeCompare(b.name)))
}

export function update(id, data) {
  return api().put(`/${lang()}/${resources.tasks}/${id}`, data)
}

export function store(data) {
  return api().post(`/${lang()}/${resources.tasks}`, data)
}

export function destroy(id) {
  return api().delete(`/${lang()}/${resources.tasks}/${id}`)
}

export function getTasksByCategory() {
  return api().get(`/${lang()}/${resources.tasks}/all`)
}