import BackOffice from "../../../../components/common/Layout/BackOffice";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import OrderStatus from "../../../../components/order/OrderStatus";
import * as OrderAPI from "../../../../api/orders";
import {groupBy} from "../../../../utils/utils";
import Order from "../../../../components/order/Order";

export default function OrderManagement(){
  const [isLoading, setIsLoading] = useState(true)
  const [orderStatus, setOrderStatus] = useState([])
  const [orders, setOrders] = useState([])
  const {t, i18n} = useTranslation(['routes', 'orders'])

  const update = () => {
    return OrderAPI.index()
      .then(orders => groupBy(orders, "order_status", "id"))
      .then(setOrders)
  }

  useEffect(() => {
    OrderAPI.getStatuses()
      .then(setOrderStatus)
    update()
      .then(() => setIsLoading(false))
  }, [i18n.language])

  return <BackOffice role="order" isLoading={isLoading}>
    <h1 className="mb-6">{t('order-management.name')}</h1>
    <div className="flex flex-col space-y-4 lg:max-w-3xl">
      {orderStatus.map((status, id) => <OrderStatus key={id} status={status} nb={orders[status.id] ? orders[status.id].length : 0}>
        {orders[status.id] ?
          orders[status.id].map(order => <Order key={order.id} order={order} update={update}/>) :
          <p>{t('orders:no_delivery')}</p>
        }
      </OrderStatus>)}
    </div>
  </BackOffice>
}