import {
  BookOpenIcon,
  CalendarIcon,
  LocationMarkerIcon,
  TruckIcon,
  UserCircleIcon,
  ViewGridIcon
} from "@heroicons/react/outline";
import NavLink from "../../backoffice/NavLink";
import {useTranslation} from "react-i18next";
import LoadingWrapper from "../placeholders/LoadingWrapper";
import SecuredPage from "../../../pages/secured/SecuredPage";
import {useEffect, useState} from "react";
import {refreshAuthUser} from "../../../api/user";

export default function BackOffice({role, isLoading= false, children}) {
  const {t} = useTranslation(['routes'])
  const [user, setUser] = useState({})

  useEffect(() => {
    refreshAuthUser()
      .then(setUser)
  }, [])
  return <SecuredPage role={role}>
    <div className="min-h-screen -mt-32 pt-32 flex flex-row">
      <aside
        className="border-r border-secondary min-w-max"
      >
        <div className="pt-6 px-4 sticky top-32">
          <ul className="flex flex-col space-y-4">
            <NavLink to={t('my-account.link')} logo={<UserCircleIcon/>}>{t('my-account.name')}</NavLink>
            <NavLink to={t('my-addresses.link')} logo={<LocationMarkerIcon/>}>{t('my-addresses.name')}</NavLink>
            {user.hasOrders && <NavLink to={t('my-orders.link')} logo={<TruckIcon/>}>{t('my-orders.name')}</NavLink>}
            {user.hasEvents && <NavLink to={t('my-events.link')} logo={<CalendarIcon/>}>{t('my-events.name')}</NavLink>}
            {user.hasExercices && <NavLink to={t('my-exercices.link')} logo={<BookOpenIcon/>}>{t('my-exercices.name')}</NavLink>}
            {user.roles?.length > 0 && <NavLink to={t('dashboard.link')} logo={<ViewGridIcon/>}>{t('dashboard.name')}</NavLink>}
          </ul>
        </div>
      </aside>

      <div className="flex-grow pt-8 pb-20 px-5 overflow-auto"
      >
        <LoadingWrapper isLoading={isLoading}>
          {children}
        </LoadingWrapper>
      </div>
    </div>
  </SecuredPage>
}