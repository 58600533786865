import {useField} from "formik";
import {useState} from "react";
import Label from "./Label";
import Info from "./Info";
import {CKEditor} from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import "@ckeditor/ckeditor5-build-classic/build/translations/de"
import "@ckeditor/ckeditor5-build-classic/build/translations/fr"
import {useTranslation} from "react-i18next";
import {CheckIcon} from "@heroicons/react/outline";

const toolbar = ['Heading', 'Bold', 'Italic', 'Link', 'NumberedList', 'BulletedList', 'Undo', 'Redo']

export default function Editor({id, label, type, className, info, required, async, error, ...props}) {
  const [field, meta, {setValue}] = useField(props)
  const [success, setSuccess] = useState(false)

  const {i18n} = useTranslation()

  const changedValue = () => meta.value !== meta.initialValue

  const handleBlur = e => {
    // field.onBlur(e)

    if (async) {
      // The latest touched and errors aren't available until after the
      // formik's onBlur handler has been called. Setting a timeout allows us
      // to access the proper touched and errors after onBlur event has
      // occurred.
      setTimeout(() => {
        if (changedValue()) {
          async({[props.name]: meta.value})
            .then(() => setSuccess(true))
        }
      }, 0);
    }
  }

  const isSuccess = () => !((!async || changedValue()) && error) && success

  return <div className="w-full">
    <Label className={`mb-2 ${isSuccess() ? 'text-green-600' : ''}`} id={id} required={required} error={(!async || changedValue()) && error}>
      {label} {isSuccess() && <CheckIcon className="w-5 h-5 inline"/>}
    </Label>
    <CKEditor
      editor={ClassicEditor}
      config={{
        toolbar: toolbar,
        language: i18n.language
      }}
      data={field.value}
      onChange={(event, editor) =>  setValue(editor.getData())}
      onBlur={(event, editor) => handleBlur(event)}
      onFocus={() => setSuccess(false)}
    />
    <input
      className={`hidden`}
      type={type || 'text'}
      id={id}
      {...field}
      {...props}
    />
    <Info info={info} error={(!async || changedValue()) && error}/>
  </div>
}