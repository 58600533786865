import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import {useTranslation} from "react-i18next";
import {useToast} from "../providers/ToastProvider";
import {useState} from "react";
import Spinner from "../components/common/Spinner";
import Alert from "../components/common/Alert";
import * as EventAPI from "../api/events";

export default function EventSubscribeStripe({eventId, price, closeModal}) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [stripeError, setStripeError] = useState("")
  const {t} = useTranslation(['fields', 'errors', 'pages'])
  const stripe = useStripe()
  const elements = useElements()
  const toast = useToast()

  const handleSubmit = async event => {
    event.preventDefault();
    if (!stripe || !elements) {

      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setIsSubmitting(true)
    setStripeError("")
    EventAPI.subscribe(eventId, 'stripe')
      .then(response => {
        return stripe.confirmCardPayment(response.stripe_token, {
          payment_method: {
            card: elements.getElement(CardNumberElement)
          }
        })
      })
      .then((response) => {
        if (response.error) {
          setStripeError(response.error.message)
        } else {
          toast.pushSuccess(t('pages:events.payment.success'))
          closeModal()
        }
      })
      .catch(() => toast.pushError(t('errors:unknown')))
      .then(() => setIsSubmitting(false))
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        {t('card-number')}
        <div className="bg-white rounded-lg shadow-md p-2 mt-1 mb-4">
          <CardNumberElement/>
        </div>
      </label>
      <label>
        {t('expiration-date')}
        <div className="bg-white rounded-lg shadow-md p-2 mt-1 mb-4">
          <CardExpiryElement/>
        </div>
      </label>
      <label>
        {t('cvc')}
        <div className="bg-white rounded-lg shadow-md p-2 mt-1 mb-4">
          <CardCvcElement/>
        </div>
      </label>
      {stripeError && <Alert color="red-500">{stripeError}</Alert> }
      <div className="flex justify-center mt-8">
        <PrimaryBtn type="submit" disabled={!stripe || isSubmitting}>
          {isSubmitting ? <Spinner className="w-5 h-5"/> : <>{t('pay', {price: price})} CHF</>}
        </PrimaryBtn>
      </div>
    </form>
  )
}