import ExerciceQuestionForm from "../../forms/ExerciceQuestionForm";
import {useEffect, useState} from "react";

export default function Questions({exercice}) {
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    setQuestions(exercice.questions)
  }, [exercice.questions])

  const addQuestion = (question) => {
    setQuestions((prev) => [...prev, question])
  }

  const removeQuestion = (question) => {
    setQuestions(prevState => prevState.filter(q => q.id !== question.id))
  }

  return <div className="flex flex-col space-y-4">
    {questions.map((question, id) => <ExerciceQuestionForm key={id} question={question} closeModal={removeQuestion}/>)}
    <ExerciceQuestionForm isCreating exercice={exercice} closeModal={addQuestion}/>
  </div>
}