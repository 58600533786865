export default function LoadingPlaceholder() {
  return <div className="border-2 border-secondary rounded-md p-4 max-w-sm w-full mx-auto">
    <div className="animate-pulse flex space-x-4">
      <div className="rounded-full bg-primary h-8 w-8"/>
      <div className="flex-1 space-y-4 py-1">
        <div className="h-4 bg-secondary rounded w-3/4 mb-6"/>
        <div className="space-y-2">
          <div className="h-2 bg-primary rounded"/>
          <div className="h-2 bg-secondary rounded w-5/6"/>
        </div>
        <div className="space-y-2">
          <div className="h-2 bg-primary rounded"/>
          <div className="h-2 bg-secondary rounded w-5/6"/>
        </div>
      </div>
    </div>
  </div>
}