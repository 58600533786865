import {useTranslation} from "react-i18next";
import Price from "../../common/Price";
import DangerBtn from "../../common/btns/solid/DangerBtn";
import {CashIcon, TrashIcon} from "@heroicons/react/outline";
import PrimaryBtn from "../../common/btns/solid/PrimaryBtn";
import PromoCodeInfos from "./PromoCodeInfos";
import {useState} from "react";
import DeleteDialogModal from "../../common/modals/DeleteDialogModal";
import Modal from "../../common/modals/Modal";
import * as ArticleAPI from "../../../api/articles"
import ArticleEditForm from "../../../forms/ArticleEditForm";
import {useToast} from "../../../providers/ToastProvider";

export default function Article({article, update}) {
  const [deleteModal, setDeleteModal] = useState(false)
  const [editModal, setEditModal] = useState(false)

  const {t} = useTranslation(['pages', 'translation'])
  const toast = useToast()

  const closeModal = () => {
    setEditModal(false)
    setDeleteModal(false)
  }

  const closeAndUpdate = () => {
    closeModal()
    update()
  }

  const handleDelete = () => {
    return ArticleAPI.destroy(article.id_sku)
      .then(closeAndUpdate)
      .catch(() => toast.pushUnKnown())
  }

  return <div className="py-2">
    <div className="flex space-x-4">
      <div className="w-24">
        {article.pictures[0] && <img className="w-full" src={article.pictures[0].picture} alt=""/>}
      </div>
      <div className="flex flex-col flex-grow space-y-2">
        <div className="text-lg font-semibold">
          {article.name}
        </div>
        <div className="ml-2 flex flex-col space-y-2">
          {article.articles?.map(sku => <div className="flex space-x-4">
            <div>{sku.text}</div>
            <div className="flex text-secondary font-semibold items-center"><CashIcon className="w-5 h-5 mr-2"/><Price solde={sku.solde}>{sku.price}</Price></div>
          </div>)}
          {article.solde && <span className="ml-2 px-1 bg-red-500 text-sm text-white rounded-lg">-{article.solde} %</span>}
        </div>
        {article.type?.id === 'promo-code' && <PromoCodeInfos article={article}/> }
      </div>
    </div>
    <div className="flex space-x-2 mt-2">
      <DangerBtn onClick={() => setDeleteModal(true)}><TrashIcon className="w-4 h-4"/></DangerBtn>
      <PrimaryBtn onClick={() => setEditModal(true)}>{t('translation:edit')}</PrimaryBtn>
    </div>
    <DeleteDialogModal
      isOpen={deleteModal}
      closeModal={closeAndUpdate}
      title={t('articles.delete.title')}
      description={t('articles.delete.description', {article: article.name})}
      action={handleDelete}/>
    <Modal
      isOpen={editModal}
      closeModal={closeAndUpdate}
      title={t('translation:edit')}
    >
      <ArticleEditForm article={{
        ...article,
        id: article.id_sku,
        name: article.name_translations,
        description: article.description_translations
      }} />
    </Modal>
  </div>
}