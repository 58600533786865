export function getActionRoute(action) {
  let value = ""

  switch (action) {
    case 'send-back':
      value = 'send-back'
      break
    case 'ready-pickup':
      value = 'ready-pick-up'
      break
    case 'ready-to-deliver':
      value = 'ready-send'
      break
    case 'delivered':
      value = 'send'
      break
    case 'send-back-received':
      value = 'send-back-received'
      break
    case 'cancel':
      break
    case 'preparing':
      value = 'pay-admin'
      break
    default:
      break
  }
  return value
}

export const orderStatus = {
  send_back: "send-back",
  ready_pickup: "ready-pickup",
  delivered: "delivered",
  send_back_received: "send-back-received",
  cancel: "cancel",
  preparing: "preparing",
  paying: "paying",
  ready_to_deliver: "ready-to-deliver",
  waiting_payment: "waiting-payment",
}