import logo from '../../../resources/images/logo.png'
import video from '../../../resources/videos/feuilles-mouvement.mp4'
import {ChevronDoubleDownIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";

export default function VideoHeader() {
  const {t} = useTranslation('errors')
  return (
    <div className="relative flex items-center justify-center h-screen overflow-hidden">
      <div
        className="
          relative z-10
          p-3 sm:p-4 md:p-5
          bg-white bg-opacity-10
          rounded-md
          w-3/4 sm:w-1/2 md:w-2/5 lg:w-1/3 xl:w-1/5
          "
      >
        <img src={logo} alt="Logo"/>
      </div>
      <div
        className="
          justify-self-center
          absolute z-10
          w-10
          h-10
          bottom-16
          text-white
        "
      >
        <ChevronDoubleDownIcon
          className="animate-bounce"
        />
      </div>
      <video
        autoPlay
        loop
        muted
        className="
        absolute
        w-auto min-w-full min-h-full max-w-none
        top-32
        -right-96 sm:-right-60 md:-right-40 lg:right-0
        "
      >
        <source
          src={video}
          type="video/mp4"
        />
        {t('no-browser-support')}
      </video>
    </div>
  )
}