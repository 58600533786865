import {Suspense} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Navbar from "./components/common/navbar/Navbar";
import Footer from "./components/common/footer/Footer";
import Home from "./pages/Home";
import Error404 from "./pages/errors/Error404";
import routes from "./routes"
import languages from "./languages";
import ScrollToTop from "./utils/ScrollToTop";
import LoadingPlaceholder from "./components/common/placeholders/LoadingPlaceholder";
import ToastProvider from "./providers/ToastProvider";
import CartProvider from "./providers/CartProvider";

function App() {
  return (
    <Suspense fallback={<LoadingPlaceholder/>}>
      <Router>
        <ScrollToTop/>
        <ToastProvider>
        <CartProvider>
          <div className="App">
            <Navbar/>
            <div className="-mt-32 min-h-screen">
              <Switch>
                {routes.map(route =>
                  languages.map(lang =>
                    <Route
                      key={`${route.key}_${lang.id}`}
                      exact
                      path={`/${lang.id}/${route.links[lang.id]}`}
                    >
                      {route.component}
                    </Route>
                  )
                )}
                <Route exact path="/"><Home/></Route>
                <Route path="*"><Error404/></Route>
              </Switch>
            </div>
            <Footer/>
          </div>
        </CartProvider>
        </ToastProvider>
      </Router>
    </Suspense>
  );
}

export default App;
