import Section from "../Section";
import {useTranslation} from "react-i18next";
const cards = [
  'days-off',
  'working-hours',
  'calendar',
  'promo-code-check',
  'edit-events',
  'admin-exercices'
];
export default function CalendarSection() {
  const {t} = useTranslation('auth')
  return <Section title={t('roles.calendar')} cards={cards}/>
}