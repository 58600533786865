import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as scheduleAPI from "../api/schedules";
import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import {DatePickerField} from "../components/common/inputs/DatePickerField";
import FormSelect from "../components/common/inputs/FormSelect";
import * as TaskCategoriesAPI from "../api/taskCategories";
import * as PractitionesAPI from "../api/practitioners";
import languages from "../languages";
import {formatDbDateTime} from "../utils/dates";
import Spinner from "../components/common/Spinner";

export default function ScheduleForm({schedule, closeModal}) {

  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [otherError, setOtherError] = useState("")
  const {t} = useTranslation(['fields', 'forms'])

  const [practitioners, setPractitioners] = useState([])
  const [categories, setCategories] = useState([])
  const [formVals, setFormVals] = useState({
    task_id: "",
    practitioner_id: "",
    address_name: "",
    language: "",
    start: "",
    end: "",
    lastname: "",
    firstname: "",
    email: "",
    tel: "",
    description: "",
  })

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
    setOtherError("")
  }

  const create = (data, {resetForm}) => {
    reset()
    return scheduleAPI.store({
      ...data,
      start: formatDbDateTime(data.start),
      end: formatDbDateTime(data.end)
    })
      .then(() => {
        closeModal()
      })
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      if (error.response.data.errors)
        setErrors(error.response.data.errors)
      else
        setOtherError(error.response.data.error)
    }
  }

  useEffect(() => {
    TaskCategoriesAPI.get()
      .then(setCategories)
    PractitionesAPI.myPratitioners()
      .then(setPractitioners)
  }, [])

  useEffect(() => {
    setFormVals({
      task_id: schedule.task_id || "",
      practitioner_id: schedule.practitioner_id || "",
      address_name: schedule.address_name || "",
      language: schedule.language || "",
      start: schedule.start || "",
      end: schedule.end || "",
      lastname: schedule.lastname || "",
      firstname: schedule.firstname || "",
      email: schedule.email || "",
      tel: schedule.tel || "",
      description: schedule.description || "",
    })
  }, [schedule])
  return <Formik
    initialValues={formVals}
    enableReinitialize
    onSubmit={create}
  >
    {({isSubmitting}) => (
    <Form>
      <div className="flex flex-col space-y-4">
        {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
        {otherError && <div className="text-red-600 text-sm">{otherError}</div> }
        <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-2 sm:space-y-0">
          <FormSelect
            label={t('task')}
            name="task_id"
            error={errors.task_id}
            required
          >
            <option>---</option>
            {categories.map(category => <optgroup key={category.id} label={category.name}>
              {category.tasks.map(task => <option key={task.id} value={task.id}>{task.name}</option>)}
            </optgroup>)}
          </FormSelect>
          <FormSelect
            label={t('practitioner')}
            name="practitioner_id"
            error={errors.practitioner_id}
            required
          >
            <option>---</option>
            {practitioners.map(practitioner => <option key={practitioner.id} value={practitioner.id}>{practitioner.name}</option>)}
          </FormSelect>
        </div>
        <div className="grid gap-y-4 sm:grid-cols-3 sm:gap-y-0 sm:gap-x-2">
          <div className="col-span-2">
            {/*todo: faire avec un combobox*/}
            <Input
              name="address_name"
              label={t('address')}
              placeholder={t('address')}
              error={errors.address_name}
              required
            />
          </div>
          <FormSelect
            label={t('language')}
            name="language"
            error={errors.language}
            required
          >
            <option>---</option>
            {languages.map(lang => <option key={lang.id} value={lang.id}>{lang.name}</option> )}
          </FormSelect>
        </div>
        <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-2 sm:space-y-0">
          <DatePickerField
            label={t('start')}
            name="start"
            error={errors.start}
            required

            showTimeSelect
            timeIntervals={15}
            dateFormat="Pp"
            minDate={new Date()}
          />
          <DatePickerField
            label={t('end')}
            name="end"
            error={errors.end}
            required

            showTimeSelect
            timeIntervals={15}
            dateFormat="Pp"
            minDate={new Date()}
          />
        </div>
        <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-2 sm:space-y-0">
          <Input
            name="lastname"
            label={t('lastname')}
            placeholder={t('lastname')}
            error={errors.lastname}
            required
          />
          <Input
            name="firstname"
            label={t('firstname')}
            placeholder={t('firstname')}
            error={errors.firstname}
            required
          />
        </div>
        <div className="flex flex-col space-y-4 md:flex-row md:space-x-2 md:space-y-0">
          <Input
            name="email"
            label={t('email')}
            placeholder={t('email')}
            error={errors.email}
          />
          <Input
            name="tel"
            label={t('tel')}
            placeholder={t('tel')}
            error={errors.tel}
          />
        </div>
        <Input
          name="description"
          label={t('details')}
          placeholder={t('details')}
          error={errors.description}
        />
        <div className="sm:text-center">
          <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : t('forms:save')}</PrimaryBtn>
        </div>
      </div>
    </Form>
    )}
  </Formik>
}