import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as PractitionerAPI from "../api/practitioners";
import {Form, Formik} from "formik";
import {getPractitioners} from "../api/user";
import Input from "../components/common/inputs/Input";
import FormSelect from "../components/common/inputs/FormSelect";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import Spinner from "../components/common/Spinner";

export default function PractitionerGroupForm({practitioner, isCreating, closeModal}) {
  const defaultValues = {
    name: "",
    users: []
  }
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const {t} = useTranslation(['fields', 'forms'])
  const [practitioners, setPractitioners] = useState([])

  useEffect(() => {
    getPractitioners()
      .then(setPractitioners)
  }, [])

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
  }

  const create = (data, {resetForm}) => {
    reset()
    return PractitionerAPI.store(data)
      .then(() => {
        resetForm(defaultValues)
        closeModal()
      })
      .catch(handleError)
  }

  const update = data => {
    reset()
    return PractitionerAPI.update(practitioner.id, data)
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  return <Formik
    initialValues={practitioner ? {...practitioner, users: practitioner?.users?.map(user => user.id)} : defaultValues}
    enableReinitialize
    onSubmit={create}
  >
    {({isSubmitting}) => (
    <Form>
      {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
      <div className="flex flex-col space-y-4">
        <Input
          name="name"
          label={t('name')}
          placeholder={t('name')}
          async={!isCreating ? update : false}
          error={errors.name}
          required
        />
        <FormSelect
          multiple
          name="users"
          label={t('practitioners')}
          placeholder={t('practitioners')}
          async={!isCreating ? update : false}
          error={errors.practitioners}
          required
        >{practitioners.map(practitioner => <option key={practitioner.id} value={practitioner.id}>{practitioner.name}</option>)}</FormSelect>
        <div className="self-center">
          {isCreating && <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : t('forms:save')}</PrimaryBtn>}
        </div>
      </div>
    </Form>
    )}
  </Formik>
}