import {useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import * as EventsAPI from "../api/events"
import {useToast} from "../providers/ToastProvider";
import Error404 from "./errors/Error404";
import Card from "../components/common/Card";
import Date from "../components/Events/Date";
import Time from "../components/Events/Time";
import Availability from "../components/Events/Availability";
import Price from "../components/common/Price";
import SubscribeBtn from "../components/Events/SubscribeBtn";
import {momentWithLocales} from "../utils/dates";
import LoadingWrapper from "../components/common/placeholders/LoadingWrapper";
import {useTranslation} from "react-i18next";

export default function Event() {
  const [event, setEvent] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [is404, setIs404] = useState(false)
  const {eventId} = useParams()
  const toast = useToast()
  const {i18n} = useTranslation()

  const handleError = useCallback(error => {
    if (error?.response?.status === 404) {
      setIs404(true)
    } else {
      toast.pushUnKnown()
    }
    // eslint-disable-next-line
  }, [])

  const update = useCallback( () => {
    setIsLoading(false)
    EventsAPI.show(eventId)
      .then(setEvent)
      .catch(handleError)
      .then(() => setIsLoading(false))
    // eslint-disable-next-line
  }, [eventId, handleError, i18n.language])

  useEffect(() => {
    update()
  }, [update])

  if (is404) return <Error404/>
  return <div className="mt-32 mx-8 py-8 md:mx-32 2xl:mx-64">
    <LoadingWrapper isLoading={isLoading}>
      <h1 className="mb-20 mt-5 text-center lg:text-left">{event.title}</h1>
      <div className="flex flex-col space-y-8 xl:flex-row xl:space-y-0">
        <div className="relative self-center xl:self-auto xl:order-2">
          <Card className="sticky top-36 w-full border border-secondary xl:border-gray-200 p-6 text-center flex flex-col space-y-2 w-screen max-w-sm xl:shadow-xl">
            <div className="text-lg font-semibold">{event.location}</div>
            <div className="flex flex-col space-y-2">
              <div className="mr-2"><Date event={event}/></div>
              <div className=""><Time event={event}/></div>
            </div>
            <div className="text-secondary font-semibold"><Price>{event.price}</Price></div>
            <div><Availability event={event}/> </div>
            {momentWithLocales(event.start_date) > momentWithLocales() && <div><SubscribeBtn event={event}/></div>}
          </Card>
        </div>
        <div className="flex-grow content xl:mr-8 p-4 xl:p-8 border border-secondary rounded-lg">
          <h3 className="mb-6 xl:hidden">{event.title}</h3>
          <div dangerouslySetInnerHTML={{__html: event.text}}/>
        </div>
      </div>
    </LoadingWrapper>
  </div>
}