import BackOffice from "../../../components/common/Layout/BackOffice";
import {useEffect, useState} from "react";
import * as EventsAPI from "../../../api/events";
import {useTranslation} from "react-i18next";
import {useToast} from "../../../providers/ToastProvider";
import Card from "../../../components/common/Card";
import Date from "../../../components/Events/Date";
import Time from "../../../components/Events/Time";
import {CalendarIcon, CashIcon, ClockIcon, LocationMarkerIcon} from "@heroicons/react/outline";
import Alert from "../../../components/common/Alert";
import PrimaryBtn from "../../../components/common/btns/solid/PrimaryBtn";
import DangerBtn from "../../../components/common/btns/solid/DangerBtn";
import DeleteDialogModal from "../../../components/common/modals/DeleteDialogModal";
import Modal from "../../../components/common/modals/Modal";
import Stripe from "../../../components/common/Stripe";
import {authUser} from "../../../api/user";
import Price from "../../../components/common/Price";

function MyEvent({event, update}) {
  const [deleteModal, setDeleteModal] = useState(false)
  const [paymentModal, setPaymentModal] = useState(false)
  const {t} = useTranslation('pages')
  const toast = useToast()

  const closeModal = () => {
    setDeleteModal(false)
    update()
  }

  const closePaymentModal = () => {
    setPaymentModal(false)
    setTimeout(() => {
      update()
    }, 1000)
  }

  const getStripeToken = () => {
    return authUser()
      .then(user => EventsAPI.getPaymentToken(event.id, user.id))
  }

  const unsubscribe = () => {
    return authUser()
      .then(user => EventsAPI.removeUser(event.id, user.id))
      .then(closeModal)
      .catch(() => toast.pushUnKnown())
  }

  return <Card>
    <div className="text-lg font-semibold mb-4">{event.title}</div>
    <div className="flex flex-col space-y-2 mb-4">
      <div className="flex items-center"><CalendarIcon className="w-5 h-5 mr-2"/><Date event={event}/></div>
      <div className="flex items-center"><ClockIcon className="w-5 h-5 mr-2"/><Time event={event}/></div>
      <div className="flex items-center"><LocationMarkerIcon className="w-5 h-5 mr-2"/>{event.location}</div>
      {event.status_id === 'waiting_payment' && <div className="text-sm font-semibold text-secondary my-2 flex items-center"><CashIcon className="w-5 h-5 mr-2"/> <Price>{event.price}</Price></div>}
    </div>
    <div className="mb-2 max-w-max">
      <Alert color={event.status_id === 'registered' ? "green-600" : "secondary"}>{t(`events.status.${event.status_id.replace(/_/g,'-')}`)}</Alert>
    </div>
    <div className="flex items-center space-x-2">
      {event.status_id !== 'deleted' && <>
        <DangerBtn onClick={() => setDeleteModal(true)}>{t('events.unsubscribe.btn')}</DangerBtn>
        <DeleteDialogModal
          isOpen={deleteModal}
          closeModal={closeModal}
          title={t('events.unsubscribe.title', {event: event.title})}
          description={t('events.unsubscribe.description')}
          action={unsubscribe}
        />
      </>}
      {event.status_id === 'waiting_payment' && <>
        <PrimaryBtn onClick={() => setPaymentModal(true)}>{t('events.pay')}</PrimaryBtn>
        <Modal isOpen={paymentModal} closeModal={() => setPaymentModal(false)} className="w-full max-w-screen-sm">
          <Stripe successMessage={"TODO, bravo payé"} callBack={closePaymentModal} price={event.price} actionPromise={getStripeToken}/>
        </Modal>
      </>}

    </div>
  </Card>
}

export default function MyEvents() {
  const [isLoading, setIsLoading] = useState(true)
  const [events, setEvents] = useState([])
  const {t, i18n} = useTranslation(['routes', 'pages'])
  const toast = useToast()

  const update = () => {
    EventsAPI.myEvents()
      .then(setEvents)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  }

  useEffect(() => {
    update()
    // eslint-disable-next-line
  }, [i18n.language])

  return <BackOffice isLoading={isLoading}>
    <h1>{t('my-events.name')}</h1>
    <div className="mt-10 flex flex-col space-y-6 max-w-screen-sm">
      {events.length > 0 ? events.map(event => <MyEvent key={event.id} event={event} update={update}>{JSON.stringify(event)}</MyEvent>) : <p className="text-lg capitalize font-semibold">{t('pages:events.no-events')}</p>}
    </div>
  </BackOffice>
}