import PictureHeader from "../headers/PictureHeader";
import LoadingWrapper from "../placeholders/LoadingWrapper";

export default function PageWithPictureHeader({headerStyle, headerContent, isLoading, children}) {
  return <>
    <PictureHeader
      className={headerStyle}
    >
      {headerContent}
    </PictureHeader>
    <main className="px-10 py-20 md:px-20 xl:px-60 flex flex-col">
      <LoadingWrapper
        isLoading={isLoading}
      >
        {children}
      </LoadingWrapper>
    </main>
  </>
}