import DangerBtn from "../common/btns/solid/DangerBtn";
import {groupBy} from "../../utils/utils";
import {useTranslation} from "react-i18next";
import SuccessBtn from "../common/btns/solid/SuccessBtn";
import PrimaryBtn from "../common/btns/solid/PrimaryBtn";
import * as EventsAPI from "../../api/events"
import {useToast} from "../../providers/ToastProvider";
import {useState} from "react";
import Spinner from "../common/Spinner";

function ParticipantRow({participant, actions, event, update}) {
  const {t} = useTranslation('pages')
  const toast = useToast()
  const [submitting, setSubmitting] = useState(false)
  const removeUser = () => {
    setSubmitting(true)
    EventsAPI.removeUser(event.id, participant.id)
      .then(update)
      .catch(toast.pushUnKnown)
      .then(() => setSubmitting(false))
  }

  const reimbourseUser = () => {
    setSubmitting(true)
    EventsAPI.reimbourseUser(event.id, participant.id)
      .then(update)
      .catch(toast.pushUnKnown)
      .then(() => setSubmitting(false))
  }

  const updateStatus = newStatus => {
    setSubmitting(true)
    EventsAPI.changeStatus(event.id, participant.id, newStatus)
      .then(update)
      .catch(toast.pushUnKnown)
      .then(() => setSubmitting(false))
  }

  return <div className="p-2 text-center">
    <div className="text-lg font-semibold">{participant.name} {participant.lastname}</div>
    <div><a href={`mailto:${participant.email}`}>{participant.email}</a></div>
    <div className="mt-2 flex space-x-1 justify-center">
      {actions.validate && <PrimaryBtn onClick={() => updateStatus('waiting_payment')} disabled={submitting}>{submitting ? <Spinner className="w-5 h-5"/> : t('events.actions.validate')}</PrimaryBtn>}
      {actions.payed && <SuccessBtn onClick={() => updateStatus('registered')} disabled={submitting}>{submitting ? <Spinner className="w-5 h-5"/> : t('events.actions.payed')}</SuccessBtn>}
      {actions.remove && <DangerBtn onClick={removeUser} disabled={submitting}>{submitting ? <Spinner className="w-5 h-5"/> : t('events.actions.remove')}</DangerBtn>}
      {actions.rollback && <PrimaryBtn onClick={() => updateStatus('registered')} disabled={submitting}>{submitting ? <Spinner className="w-5 h-5"/> : t('events.actions.rollback')}</PrimaryBtn>}
      {actions.cashBack && <SuccessBtn onClick={reimbourseUser} disabled={submitting}>{submitting ? <Spinner className="w-5 h-5"/> : t('events.actions.cashBack')}</SuccessBtn>}
    </div>
  </div>
}

function Status({statusName, participants, actions, event, update}) {
  const {t} = useTranslation('pages')
  return <div className="rounded-lg overflow-hidden w-full max-w-xs m-2 border border-primary">
    <div className="bg-primary py-4 text-white text-lg text-center">{statusName}</div>
    <div className="flex flex-col divide-y">
      {participants ? participants.map((participant, id) => <ParticipantRow event={event} update={update} key={id} participant={participant} actions={actions}/> ) : <p className="text-center py-4 font-semibold text-lg">{t('events.no-participants')}</p>}
    </div>
  </div>
}

export default function Participants({event, update}) {
  const {waiting_list, registered, waiting_payment, deleted} = groupBy(event.participants, 'pivot', 'event_status_id')
  const {t} = useTranslation('pages')
  return <div className="flex flex-wrap w-full items-center justify-center">
    <Status statusName={t('events.status.waiting-list')} event={event} update={update} participants={waiting_list} actions={{validate: !event.deleted_at, remove: !event.deleted_at}}/>
    <Status statusName={t('events.status.waiting-payment')} event={event} update={update} participants={waiting_payment} actions={{payed: true, remove: !event.deleted_at}}/>
    <Status statusName={t('events.status.registered')} event={event} update={update} participants={registered} actions={{remove: !event.deleted_at, cashBack: !!event.deleted_at}} />
    <Status statusName={t('events.status.deleted')} event={event} update={update} participants={deleted} actions={{rollback: !event.deleted_at, cashBack: true}}/>
  </div>
}