function Element({icon, children, isLast, selected, validated}) {
  return <>
    <div className={`flex items-center relative ${selected ? 'text-white' : validated ? 'text-primary' : 'text-gray-300'}`}>
      <div
        className={`rounded-full transition duration-500 ease-in-out h-12 w-12 
        py-3 border-2 flex items-center justify-center 
        ${selected ? 'bg-secondary border-secondary' : validated ? 'border-secondary' : 'border-gray-300'}
        `}
      >
        {icon}
      </div>
      <div
        className={`${!selected ? 'invisible' : ''} md:visible absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-primary`}
      >
        {children}
      </div>
    </div>
    {!isLast && <div
      className={`flex-auto border-t-2 transition duration-500 ease-in-out ${validated ? 'border-secondary' : 'border-gray-300'}`}
    />}
  </>
}

export default function CheckoutTabsNav({elements = [{name: '', icon: null}], selected, minIndex}) {
  return <div className="flex items-center w-full">
    {elements.map((element, id) => <Element key={id} icon={element.icon} isLast={id + 1 === elements.length} selected={id === selected} validated={minIndex > id}>{element.name}</Element>)}
  </div>
}