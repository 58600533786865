import SecondaryBtn from "../common/btns/solid/SecondaryBtn";
import Spinner from "../common/Spinner";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import * as OrderAPI from "../../api/orders"
import DeleteDialogModal from "../common/modals/DeleteDialogModal";
import SendBackModal from "./sendBackModal";
import {useToast} from "../../providers/ToastProvider";

export default function ActionBtn({action, order, update}) {
  const [clicked, setClicked] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [sendBackModal, setSendBackModal] = useState(false)
  const {t} = useTranslation(['orders', 'pages'])
  const toast = useToast()

  const handleClick = () => {
    setClicked(true)
    switch (action) {
      case 'send-back':
        setSendBackModal(true)
        break
      case 'cancel':
        setDeleteModal(true)
        break
      default:
        applyAction()
        break
    }
  }

  const deleteOrder = () => {
    OrderAPI.destroy(order.id)
      .then(update)
      .catch(() => toast.pushUnKnown())
      .then(() => {
        setClicked(false)
        setDeleteModal(false)
      })
  }

  const applyAction = (data) => {
    OrderAPI.orderAction(order.id, action, data)
      .then(update)
      .catch(() => toast.pushUnKnown())
      .then(() => setClicked(false))
  }

  return <SecondaryBtn onClick={handleClick} disabled={clicked} className="flex justify-center space-x-2">
    {clicked && <Spinner className="h-5 w-5"/>} <p>{t(`actions.${action}`)}</p>
    {action === 'cancel' && <DeleteDialogModal
      isOpen={deleteModal}
      closeModal={() => {
        setDeleteModal(false)
        setClicked(false)
      }}
      title={t('pages:orders.delete.title', {nb: order.id})}
      description={t('pages:orders.delete.description')}
      action={deleteOrder}
    />}
    {action === 'send-back' && <SendBackModal
      closeModal={() => {
        setSendBackModal(false)
        setClicked(false)
        update()
      }}
      isOpen={sendBackModal}
      order={order}
    />}
  </SecondaryBtn>
}