import LoadingWrapper from "../common/placeholders/LoadingWrapper";
import {useAppointment} from "../../providers/AppointmentProvider";
import Recap from "../schedules/Recap";
import PublicScheduleForm from "../../forms/PublicScheduleForm";

export default function FillDetails() {
  const appointment = useAppointment()
  return <LoadingWrapper isLoading={appointment.isLoading}>
    <div className="grid md:grid-cols-2 gap-4">
      <div>
        <Recap address={appointment.location?.name} task={appointment.task?.name} users={appointment.practitioner?.public_name} start={appointment.selectedDateTime}/>
      </div>
      <PublicScheduleForm/>
    </div>
  </LoadingWrapper>
}