import {useState} from "react";
import {useCart} from "../../providers/CartProvider";
import {useToast} from "../../providers/ToastProvider";
import Price from "../common/Price";
import PrimaryBtn from "../common/btns/solid/PrimaryBtn";
import Spinner from "../common/Spinner";
import {MinusIcon, PlusIcon, TrashIcon} from "@heroicons/react/outline";
import SecondaryBtn from "../common/btns/solid/SecondaryBtn";
import DangerBtn from "../common/btns/solid/DangerBtn";

export default function CartArticle({article}) {
  const [isAdding, setIsAdding] = useState(false)
  const [isSubbing, setIsSubbing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const cart = useCart()
  const toast = useToast()

  const handleError = error => {
    toast.pushError(error.response.errors)
  }

  const add = () => {
    setIsAdding(true)
    cart.addItem(article.id, 1)
      .catch(handleError)
      .then(() => setIsAdding(false))
  }
  const sub = () => {
    setIsSubbing(true)
    cart.removeItem(article.id, 1)
      .catch(handleError)
      .then(() => setIsSubbing(false))
  }
  const remove = () => {
    setIsDeleting(true)
    cart.removeItem(article.id, article.quantity)
      .catch(handleError)
      .then(() => setIsSubbing(false))
  }

  return <div className="flex">
    <div className="max-h-28 w-32 mr-4 flex-shrink-0">
      <img className="h-full w-full object-contain" src={article.pictures[0]?.picture} alt=""/>
    </div>
    <div className="max-w-max">
      <div className="uppercase font-semibold">{article.formatted_name}</div>
      <div className="ml-2 text-sm font-semibold text-secondary-dark flex space-x-1"><span className="text-primary">{article.quantity}x</span> <Price solde={article.solde}>{article.total_price}</Price></div>
      <div className="flex space-x-2 mt-2">
        {article.can_add && <PrimaryBtn disabled={isAdding} onClick={add}>{isAdding ? <Spinner className="w-3 h-3"/> : <PlusIcon className="w-3 h-3"/>}</PrimaryBtn>}
        <SecondaryBtn disabled={isSubbing} onClick={sub}>{isSubbing ? <Spinner className="w-3 h-3"/> : <MinusIcon className="w-3 h-3"/>}</SecondaryBtn>
        <DangerBtn disabled={isDeleting} onClick={remove}>{isDeleting ? <Spinner className="w-3 h-3"/> : <TrashIcon className="w-3 h-3"/>}</DangerBtn>
      </div>
    </div>
  </div>
}