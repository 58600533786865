import BackOffice from "../../../components/common/Layout/BackOffice";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import * as UserAddresses from "../../../api/userAddresses";
import Address from "../../../components/user/Address";
import TransparentBtn from "../../../components/common/btns/TransparentBtn";
import UserAddressForm from "../../../forms/UserAddressForm";
import Modal from "../../../components/common/modals/Modal";
import {useToast} from "../../../providers/ToastProvider";

export default function MyAdresses() {
  const {t} = useTranslation(['routes', 'pages'])
  const [addresses, setAddresses] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const toast = useToast()

  useEffect(() => {
    updateAdresses()
    //eslint-disable-next-line
  }, [])

  const updateAdresses = () => {
    UserAddresses.get()
      .then(setAddresses)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  }

  return <BackOffice isLoading={isLoading}>
    <h1>{t('my-addresses.name')}</h1>
    <div className="mt-10 max-w-sm flex flex-col space-y-4">
      {addresses.map(address => <Address key={address.id} address={address} updateAddresses={updateAdresses}/>)}
      <TransparentBtn className="self-start text-secondary" onClick={() => setOpenModal(true)}>{t('pages:my-addresses.create-modal.title')} +</TransparentBtn>
      <Modal
        title={t('pages:my-addresses.create-modal.title')}
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
        className="w-full max-w-xl"
      >
        <UserAddressForm isCreating closeModal={() => {setOpenModal(false); updateAdresses()}}/>
      </Modal>
    </div>
  </BackOffice>
}