import NavLink from "../navbar/NavLink";
import Routes from "./Routes";
import {useTranslation} from "react-i18next";

export default function Footer(props) {
  const {t} = useTranslation(['routes', 'translation'])

  return (
    <footer className="
      border-t-2 border-secondary
      p-5 z-30
    ">
      <nav className="
        flex flex-col sm:flex-row lg:grid lg:grid-cols-4
        sm:justify-around
        ">
        <Routes
          title={t('translation:footer.need-help')}
          className="lg:col-start-2"
        >
          <NavLink href={t('faq.link')}>{t('faq.name')}</NavLink>
          <NavLink href={t('terms.link')}>{t('terms.name')}</NavLink>
          <NavLink href={t('contact.link')}>{t('contact.name')}</NavLink>
        </Routes>
        <Routes
          title={t('translation:footer.about')}
          className="pt-4 sm:pt-0"
        >
          <NavLink href={t('power-healing.link')}>{t('power-healing.name')}</NavLink>
          <NavLink href={t('geobiologie.link')}>{t('geobiologie.name')}</NavLink>
          <NavLink href={t('partners.link')}>{t('partners.name')}</NavLink>
        </Routes>
      </nav>
    </footer>
  )
}