import Label from "./Label";
import {useField} from "formik";
import {QrcodeIcon} from "@heroicons/react/outline";
import {useState} from "react";
import Modal from "../modals/Modal";
import QrReader from 'react-qr-reader'
import {useToast} from "../../../providers/ToastProvider";

export default function QrScanInput({id, label, className, required, error, ...props}) {
  // eslint-disable-next-line
  const [field, meta, {setValue}] = useField(props)
  const [openModal, setOpenModal] = useState(false)
  const toast = useToast()

  return <div className="w-full">
    <Label id={id} required={required} error={error}>{label}</Label>
    <div className="relative flex w-full flex-wrap items-stretch">
      <span
        className="
          z-10 h-full leading-snug
          absolute flex items-center
          text-primary
          bg-transparent rounded justify-center
          w-8 pl-1 pt-1
          cursor-pointer"
        onClick={() => setOpenModal(true)}
      >
        <QrcodeIcon/>
      </span>
      <Modal isOpen={openModal} closeModal={() => setOpenModal(false)} className="w-full max-w-sm">
        <QrReader
          onError={() => {
            setOpenModal(false)
            toast.pushUnKnown()
          }}
          onScan={data => {
            if (data) {
              setValue(data)
              setOpenModal(false)
            }
          }}
          style={{ width: '100%' }}
        />
      </Modal>
      <input
        className={`
          block mt-2 p-3 pl-10
          border
          focus:border-secondary focus:outline-none
          focus:ring-1 focus:ring-secondary 
          w-full shadow-sm sm:text-sm border-gray-300 rounded-md
          ${className || ''}
          ${error ? 'ring-1 ring-red-600 border-red-600':
              ''
          }
        `}
        type='text'
        id={id}
        required={required}
        {...field}
        {...props}
      />
    </div>
  </div>
}