import BackOffice from "../../../../components/common/Layout/BackOffice";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as ArticleCategoriesAPI from "../../../../api/articleCategories"
import * as ArticlesAPI from "../../../../api/articles"
import PrimaryBtn from "../../../../components/common/btns/solid/PrimaryBtn";
import SecondaryBtn from "../../../../components/common/btns/solid/SecondaryBtn";
import ArticleCategory from "../../../../components/shop/admin/ArticleCategory";
import Article from "../../../../components/shop/admin/Article";
import Modal from "../../../../components/common/modals/Modal";
import ArticleCategoryForm from "../../../../forms/ArticleCategoryForm";
import ArticleForm from "../../../../forms/ArticleForm";
import {useToast} from "../../../../providers/ToastProvider";

export default function EditArticles() {
  const [isLoading, setIsLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [types, setTypes] = useState([])
  const [newCategory, setNewCategory] = useState(false)
  const [newArticle, setNewArticle] = useState(false)
  const {t, i18n} = useTranslation(['routes', 'pages'])
  const toast = useToast()

  const update = () => {
    ArticleCategoriesAPI.index()
      .then(setCategories)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  }

  const closeModal = () => {
    setNewCategory(false)
    setNewArticle(false)
    update()
  }

  useEffect(() => {
    ArticlesAPI.getTypes()
      .then(setTypes)
      .catch(() => toast.pushUnKnown())
    update()
    //eslint-disable-next-line
  }, [i18n.language])

  return <BackOffice role="editor" isLoading={isLoading}>
    <h1>{t('edit-articles.name')}</h1>
    <div className="mt-6 space-x-2">
      <PrimaryBtn onClick={() => setNewCategory(true)}>{t('pages:articles.new-category')} +</PrimaryBtn>
      <SecondaryBtn onClick={() => setNewArticle(true)}>{t('pages:articles.new-article')} +</SecondaryBtn>
    </div>
    <div className="mt-4 space-y-2">
      {categories.map(category => <ArticleCategory key={category.id} {...category} update={update}>
        {category.articles?.map(article => <Article key={article.id_sku} article={article} update={update}/>)}
      </ArticleCategory>)}
    </div>
    <Modal isOpen={newCategory} title={t('pages:articles.new-category')} closeModal={closeModal}>
      <ArticleCategoryForm isCreating closeModal={closeModal}/>
    </Modal>
    <Modal isOpen={newArticle} title={t('pages:articles.new-article')} closeModal={closeModal} className="w-full max-w-screen-xl">
      <ArticleForm closeModal={closeModal} categories={categories} types={types}/>
    </Modal>
  </BackOffice>
}