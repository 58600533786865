import {formatDbDate, momentWithLocales} from "../../utils/dates";
import OutlineSecondaryBtn from "../common/btns/outline/OutlineSecondaryBtn";
import {useEffect, useState} from "react";
import TransparentBtn from "../common/btns/TransparentBtn";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/outline";
import LoadingWrapper from "../common/placeholders/LoadingWrapper";
import Card from "../common/Card";
import {useTranslation} from "react-i18next";
import {useToast} from "../../providers/ToastProvider";

function Time({time, locale, onClick, selectedTime}) {
  return <div className="ml-2 mt-2">
    <OutlineSecondaryBtn className={selectedTime === time ? 'bg-primary text-white' : ''} onClick={() => onClick(time)}>
      {momentWithLocales(time).locale(locale).format("HH:mm")}
    </OutlineSecondaryBtn>
  </div>
}

function DispoRow({day, times, locale, onChange, selectedTime}) {
  return <div className="w-full pt-2 pb-4">
    <div className="font-semibold text-lg sm:text-center capitalize">{momentWithLocales(day).locale(locale).format("dddd LL")}</div>
    <div className="w-full flex flex-wrap sm:justify-center">
      {times.map((time, id) => <Time time={time} key={id} locale={locale} onClick={onChange} selectedTime={selectedTime}/> )}
    </div>
  </div>
}

export default function ModulCalendar({getDisponibilities, selected, onSelect, className}) {
  const [curMonth, setCurMonth] = useState(momentWithLocales().month())
  const [minMonth] = useState(momentWithLocales().month())
  const [disponibilities, setDiponibilities] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const {t, i18n} = useTranslation("pages")
  const toast = useToast()

  const canGoBack = () => momentWithLocales().month(curMonth) > momentWithLocales().month(minMonth)

  const handleDispoClick = dispo => {
    onSelect(dispo)
  }

  useEffect(() => {
    setIsLoading(true)
    const current = momentWithLocales().month(curMonth)
    const start = current.startOf('month') <= momentWithLocales() ? momentWithLocales().add(1, 'd') : current.startOf('month')
    const end = momentWithLocales().month(curMonth).endOf('month').add(1, 'd')

    return getDisponibilities(formatDbDate(start), formatDbDate(end))
      .then(dispos => {
        setDiponibilities(dispos)
        return dispos
      })
      .catch(() => {
        toast.pushUnKnown()
        setDiponibilities([])
      })
      .then(() => setIsLoading(false))
    // eslint-disable-next-line
  }, [curMonth, getDisponibilities])

  return <Card className={`w-full h-full ${className || ''}`}>
    <div className="flex justify-around border-b border-secondary pt-2 pb-5">
      <TransparentBtn className={`${canGoBack() ? '' : 'invisible'}`} onClick={() => setCurMonth(prevState => prevState - 1)}><ChevronLeftIcon className="w-4 h-4"/> </TransparentBtn>
      <div className="font-semibold text-2xl capitalize">{momentWithLocales().locale(i18n.language).month(curMonth).format("MMMM YYYY")}</div>
      <TransparentBtn onClick={() => setCurMonth(prevState => prevState + 1)}><ChevronRightIcon className="w-4 h-4"/></TransparentBtn>
    </div>
    <div className="h-80 px-2 py-4 w-full">
      <div className="overflow-y-auto w-full h-full flex flex-col divide-y">
        <LoadingWrapper isLoading={isLoading}>
          {Object.keys(disponibilities).length > 0 ? Object.keys(disponibilities).map((day, id) => <DispoRow
            key={id}
            day={day}
            times={disponibilities[day]}
            locale={i18n.language}
            onChange={handleDispoClick}
            selectedTime={selected}
          />) : <p className="text-lg text-center">{t('appointment.no-dates')}</p>}
        </LoadingWrapper>
      </div>
    </div>
  </Card>
}