import api from "./api";
import {authUser} from "./user";
import {lang} from "../languages";
import resources from './resources'

export function get() {
  return authUser()
    .then(user => api().get(`/${lang()}/${resources.user}/${user.id}/${resources.address}`))
}

export function store(data) {
  return authUser()
    .then(user => api().post(`/${lang()}/${resources.user}/${user.id}/${resources.address}`, data))
}

export function update(id, data) {
  return authUser()
    .then(user => api().put(`/${lang()}/${resources.user}/${user.id}/${resources.address}/${id}`, data))
}

export function destroy(id) {
  return authUser()
    .then(user => api().delete(`/${lang()}/${resources.user}/${user.id}/${resources.address}/${id}`))
}