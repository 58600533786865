import Home from "./pages/Home";
import PowerHealing from "./pages/PowerHealing";
import GeobiologieMemoires from "./pages/GeobiologieMemoires";
import Faq from "./pages/Faq";
import Terms from "./pages/Terms";
import Contact from "./pages/Contact";
import Partners from "./pages/Partners";
import Shop from "./pages/Shop";
import MyAccount from "./pages/secured/personal/MyAccount";
import MyAdresses from "./pages/secured/personal/MyAdresses";
import MyOrders from "./pages/secured/personal/MyOrders";
import Dashboard from "./pages/secured/admin/Dashboard";
import TaskCategories from "./pages/secured/admin/calendarAdmin/TaskCategories";
import Practitioners from "./pages/secured/admin/calendarAdmin/Practitioners";
import Addresses from "./pages/secured/admin/calendarAdmin/Addresses";
import DaysOff from "./pages/secured/admin/calendar/DaysOff";
import Calendar from "./pages/secured/admin/calendar/Calendar";
import WorkingHours from "./pages/secured/admin/calendar/WorkingHours";
import EditHome from "./pages/secured/admin/editor/EditHome";
import EditGeobio from "./pages/secured/admin/editor/EditGeobio";
import EditPowerHealing from "./pages/secured/admin/editor/EditPowerHealing";
import EditTerms from "./pages/secured/admin/editor/EditTerms";
import EditFaq from "./pages/secured/admin/editor/EditFaq";
import EditPartners from "./pages/secured/admin/editor/EditPartners";
import OrderManagement from "./pages/secured/admin/order/OrderManagement";
import UserRights from "./pages/secured/admin/admin/UserRights";
import PromoCheck from "./pages/secured/admin/calendar/PromoCheck";
import PromoCodes from "./pages/secured/admin/admin/PromoCodes";
import EditArticles from "./pages/secured/admin/editor/EditArticles";
import Article from "./pages/Article";
import EditSchedule from "./pages/EditSchedule";
import Checkout from "./pages/Checkout";
import Events from "./pages/Events";
import Event from "./pages/Event";
import EditEvents from "./pages/secured/admin/calendar/EditEvents";
import MyEvents from "./pages/secured/personal/MyEvents";
import Exercices from "./pages/secured/admin/calendar/Exercices";
import MyExercices from "./pages/secured/personal/MyExercices";
import Appointment from "./pages/Appointment";

let routes = [
  {
    key: "home",
    links: {
      fr: "accueil",
      de: "willkommen",
      en: "home"
    },
    component: <Home/>
  },
  {
    key: "power-healing",
    links: {
      fr: "power-healing",
      de: "power-healing",
      en: "power-healing"
    },
    component: <PowerHealing/>
  },
  {
    key: "geobiologie",
    links: {
      fr: "geobiologie-memoires",
      de: "geobiologie-memoires",
      en: "geobiologie-memoires"
    },
    component: <GeobiologieMemoires/>
  },
  {
    key: "shop",
    links: {
      fr: "boutique",
      de: "shop",
      en: "shop"
    },
    component: <Shop/>
  },
  {
    key: "shop-category",
    links: {
      fr: "boutique/categorie/:categoryId",
      de: "shop/kategorie/:categoryId",
      en: "shop/category/:categoryId"
    },
    component: <Shop/>
  },
  {
    key: "shop-article",
    links: {
      fr: "article/:itemId",
      de: "artikel/:itemId",
      en: "item/:itemId"
    },
    component: <Article/>
  },
  {
    key: "faq",
    links: {
      fr: "faq",
      de: "faq",
      en: "faq"
    },
    component: <Faq/>
  },
  {
    key: "terms",
    links: {
      fr: "conditions-generales",
      de: "agb",
      en: "terms"
    },
    component: <Terms/>
  },
  {
    key: "contact",
    links: {
      fr: "contact",
      de: "kontakt",
      en: "contact"
    },
    component: <Contact/>
  },
  {
    key: "partners",
    links: {
      fr: "partenaires",
      de: "partner",
      en: "partners"
    },
    component: <Partners/>
  },
  {
    key: "my-account",
    links: {
      fr: "mon-compte",
      de: "mein-konto",
      en: "my-account"
    },
    component: <MyAccount/>
  },
  {
    key: "my-addresses",
    links: {
      fr: "mes-adresses",
      de: "meine-adressen",
      en: "my-addresses"
    },
    component: <MyAdresses/>
  },
  {
    key: "my-orders",
    links: {
      fr: "mes-commandes",
      de: "meine-bestellungen",
      en: "my-orders"
    },
    component: <MyOrders/>
  },
  {
    key: "dashboard",
    links: {
      fr: "tableau-de-bord",
      de: "instrumententafel",
      en: "dashboard"
    },
    component: <Dashboard/>
  },
  {
    key: "events",
    links: {
      fr: "evenements",
      de: "events",
      en: "events"
    },
    component: <Events/>,
  },
  {
    key: "edit-events",
    links: {
      fr: "gestion-evenements",
      de: "edit-events",
      en: "ereignisse-bearbeiten"
    },
    component: <EditEvents/>,
  },
  {
    key: "event",
    links: {
      fr: "evenement/:eventId",
      de: "event/:eventId",
      en: "event/:eventId"
    },
    component: <Event/>,
  },
  {
    key: "order-management",
    links: {
      fr: "commandes",
      de: "bestellverwaltung",
      en: "order-management"
    },
    component: <OrderManagement/>
  },
  {
    key: "days-off",
    links: {
      fr: "absences",
      de: "meine-abwesenheiten",
      en: "my-absences"
    },
    component: <DaysOff/>
  },
  {
    key: "working-hours",
    links: {
      fr: "horaires",
      de: "arbeitszeit",
      en: "working-hours"
    },
    component: <WorkingHours/>
  },
  {
    key: "calendar",
    links: {
      fr: "calendrier",
      de: "kalender",
      en: "calendar"
    },
    component: <Calendar/>
  },
  {
    key: "promo-code-check",
    links: {
      fr: "promo-code-verification",
      de: "promo-code-check",
      en: "promo-code-check"
    },
    component: <PromoCheck/>
  },
  {
    key: "task-categories",
    links: {
      fr: "types-prestations",
      de: "task-categories",
      en: "task-categories"
    },
    component: <TaskCategories/>
  },
  {
    key: "practitioners",
    links: {
      fr: "praticiens",
      de: "practitioners",
      en: "practitioners"
    },
    component: <Practitioners/>
  },
  {
    key: "addresses",
    links: {
      fr: "adresses",
      de: "addresses",
      en: "addresses"
    },
    component: <Addresses/>
  },
  {
    key: "promotion-codes",
    links: {
      fr: "codes-promo",
      de: "promotion-codes",
      en: "promotion-codes"
    },
    component: <PromoCodes/>
  },
  {
    key: "user-rights",
    links: {
      fr: "droits-utilisateur",
      de: "user-rights",
      en: "user-rights"
    },
    component: <UserRights/>
  },
  {
    key: "edit-articles",
    links: {
      fr: "modifier-articles",
      de: "edit-articles",
      en: "edit-articles"
    },
    component: <EditArticles/>
  },
  {
    key: "edit-home",
    links: {
      fr: "modifier-accueil",
      de: "edit-home",
      en: "edit-home"
    },
    component: <EditHome/>
  },
  {
    key: "edit-geobiologie",
    links: {
      fr: "modifier-geobiologie-memoires",
      de: "edit-geobiologie",
      en: "edit-geobiologie"
    },
    component: <EditGeobio/>
  },
  {
    key: "edit-power-healing",
    links: {
      fr: "modifier-power-healing",
      de: "edit-power-healing",
      en: "edit-power-healing"
    },
    component: <EditPowerHealing/>
  },
  {
    key: "edit-partners",
    links: {
      fr: "modifier-partenaires",
      de: "edit-partners",
      en: "edit-partners"
    },
    component: <EditPartners/>
  },
  {
    key: "edit-faq",
    links: {
      fr: "modifier-faq",
      de: "edit-faq",
      en: "edit-faq"
    },
    component: <EditFaq/>
  },
  {
    key: "edit-terms",
    links: {
      fr: "modifier-conditions",
      de: "edit-terms",
      en: "edit-terms"
    },
    component: <EditTerms/>
  },
  {
    key: "edit-appointment",
    links: {
      fr: "modifier-rdv/:appointmentId",
      de: "termin-bearbeiten/:appointmentId",
      en: "edit-appointment/:appointmentId"
    },
    component: <EditSchedule/>
  },
  {
    key: "checkout",
    links: {
      fr: "caisse",
      de: "kasse",
      en: "checkout"
    },
    component: <Checkout/>
  },
  {
    key: "my-events",
    links: {
      fr: "mes-evenements",
      de: "meine-veranstaltungen",
      en: "my-events"
    },
    component: <MyEvents/>
  },
  {
    key: "admin-exercices",
    links: {
      fr: "admin-exercices",
      de: "admin-exercices",
      en: "admin-exercices"
    },
    component: <Exercices/>
  },
  {
    key: "my-exercices",
    links: {
      fr: "mes-exercices",
      de: "meine-ubungen",
      en: "my-exercices"
    },
    component: <MyExercices/>
  },
  {
    key: "appointment",
    links: {
      fr: "rendez-vous",
      de: "termin",
      en: "appointment"
    },
    component: <Appointment/>
  },
]

export default routes