import BackOffice from "../../../../components/common/Layout/BackOffice";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {index} from "../../../../api/daysOff";
import Modal from "../../../../components/common/modals/Modal";
import SecondaryBtn from "../../../../components/common/btns/solid/SecondaryBtn";
import DayOff from "../../../../components/daysOff/DayOff";
import DayOffForm from "../../../../forms/DayOffForm";
import {useToast} from "../../../../providers/ToastProvider";

export default function DaysOff() {
  const {t} = useTranslation(['routes', 'pages'])
  const [dates, setDates] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const toast = useToast()

  const update = () => {
    index()
      .then(setDates)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  }

  const closeModal = () => {
    setOpenModal(false)
    update()
  }

  //eslint-disable-next-line
  useEffect(() => update(), [])
  return <BackOffice role="calendar" isLoading={isLoading}>
    <h1 className="mb-4">{t('days-off.name')}</h1>
    <SecondaryBtn className="self-start" onClick={() => setOpenModal(true)}>{t('pages:days-off.create')} +</SecondaryBtn>
    <div className="mt-10 flex flex-col space-y-4">
      {dates.map(date => <DayOff key={date.id} date={date} onChange={update}/>)}
    </div>
    <Modal
      title={t('pages:days-off.create')}
      isOpen={openModal}
      closeModal={() => setOpenModal(false)}
      className="w-full max-w-xl"
    >
      <DayOffForm isCreating closeModal={closeModal}/>
    </Modal>
  </BackOffice>
}