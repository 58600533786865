import Label from "./Label";
import Info from "./Info";
import {useField} from "formik";
import {useState} from "react";

export default function FormSelect({id, label, className, info, required, async, error, ...props}) {
  const [field, meta] = useField(props)
  const [success, setSuccess] = useState(false)

  const changedValue = () => meta.value !== meta.initialValue

  const handleBlur = e => {
    field.onBlur(e)

    if (async) {
      // The latest touched and errors aren't available until after the
      // formik's onBlur handler has been called. Setting a timeout allows us
      // to access the proper touched and errors after onBlur event has
      // occurred.
      setTimeout(() => {
        if (changedValue()) {
          async({[props.name]: meta.value})
            .then(() => setSuccess(true))
        }
      }, 0);
    }
  };

  return (
    <div className="w-full">
      <Label id={id} required={required} error={(!async || changedValue()) && error}>{label}</Label>
      <select
        className={`
          block mt-2 p-3
          border
          focus:border-secondary focus:outline-none
          focus:ring-1 focus:ring-secondary 
          w-full shadow-sm sm:text-sm border-gray-300 rounded-md
          ${className || ''}
          ${(!async || changedValue()) && error ? 'ring-1 ring-red-600 border-red-600':
            success ? 'ring-1 ring-green-600 border-green-600':
              ''
          }
        `}
        id={id}
        {...field}
        {...props}
        onBlur={handleBlur}
        onFocus={() => setSuccess(false)}
        required={required}
      />
      <Info info={info} error={(!async || changedValue()) && error}/>
    </div>
  )
}
