import api from "./api";
import {lang} from "../languages";
import resources from "./resources";
import {formatDbDate} from "../utils/dates";

export function index() {
  return api().get(`/${lang()}/${resources.days_off}`)
}

export function indexBetween(start, end) {
  return api().get(`/${lang()}/${resources.days_off}/fc?start=${formatDbDate(start)}&end=${formatDbDate(end)}`)
}

export function store(data) {
  return api().post(`/${lang()}/${resources.days_off}`, data)
}

export function update(id, data) {
  return api().put(`/${lang()}/${resources.days_off}/${id}`, data)
}

export function destroy(id) {
  return api().delete(`/${lang()}/${resources.days_off}/${id}`)
}