import logo from "../resources/images/Logo_Power_Healing.png"
import {useEffect, useState} from "react";
import {getContents} from "../api/contents";
import {useTranslation} from "react-i18next";
import PageWithPictureHeader from "../components/common/Layout/PageWithPictureHeader";
import {useToast} from "../providers/ToastProvider";
import HelmetSEO from "../utils/HelmetSEO";

export default function PowerHealing() {
  const [content, setContent] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const {i18n} = useTranslation()
  const toast = useToast()

  useEffect(() => {
    getContents('contents', 'power-healing')
      .then(setContent)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
    //eslint-disable-next-line
  }, [])

  return <>
    <HelmetSEO page="power-healing"/>
    <PageWithPictureHeader
      headerStyle="bg-power-healing-sm md:bg-power-healing"
      headerContent={<div className="
          p-3 sm:p-4 md:p-5
          bg-gray-400 bg-opacity-40
          md:bg-opacity-0
          rounded-md
        ">
          <img src={logo} alt="Logo Power Healing"/>
        </div>
      }
      isLoading={isLoading}
    >
      <div className="h-32 self-center mb-10">
        <img className="h-full" src={logo} alt="Logo Power Healing"/>
      </div>
      <article dangerouslySetInnerHTML={{
        __html: content.text_translations && content.text_translations[i18n.language],
      }}/>
    </PageWithPictureHeader>
  </>
}