import api from "./api";
import {lang} from "../languages";
import resources from "./resources";

export function get() {
  return api().get(`/${lang()}/${resources.task_categories}`)
}

export function put(id, data) {
  return api().put(`/${lang()}/${resources.task_categories}/${id}`, data)
}

export function post(data) {
  return api().post(`/${lang()}/${resources.task_categories}`, data)
}

export function destroy(id) {
  return api().delete(`/${lang()}/${resources.task_categories}/${id}`)
}