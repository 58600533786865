import Input from "../components/common/inputs/Input";
import FormSelect from "../components/common/inputs/FormSelect";
import languages from "../languages";
import {Form, Formik} from "formik";
import * as UserAPI from "../api/user";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useToast} from "../providers/ToastProvider";

export default function UserInfoForm({isCreating, title, ...props}) {
  const [user, setUser] = useState({lastname: '', name: '', email: '', language: ''})
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const {t} = useTranslation(['fields', 'errors'])
  const toast = useToast()

  useEffect(() => {
    if (!isCreating) {
      UserAPI.authUser()
        .then(setUser)
        .catch(() => toast.pushUnKnown())
    }
    // eslint-disable-next-line
  }, [isCreating])

  const create = data => {
    setShowGlobalError(false)
    setErrors({})
    return UserAPI.store(data)
      .catch(handleError)
  }
  const update = data => {
    setShowGlobalError(false)
    setErrors({})
    return UserAPI.update(data)
      .then(setUser)
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }


  return <Formik
      initialValues={user}
      enableReinitialize
      onSubmit={create}
    >
      <Form {...props}>
        {title && <h2 className="mb-6">{title}</h2>}
        {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
        <div className="flex flex-col space-y-2 mt-2 mx-4">
          <Input name="lastname" type="text" label={t('lastname')} placeholder={t('lastname')} async={!isCreating ? update : false} error={errors.lastname} required/>
          <Input name="name" type="text" label={t('firstname')} placeholder={t('firstname')} async={!isCreating ? update : false} error={errors.name} required/>
          <Input name="email" type="email" label={t('email')} placeholder={t('email')} async={!isCreating ? update : false} error={errors.email} required/>
          <FormSelect label={t('language')} name="language" async={!isCreating ? update : false} error={errors.language} required>
            {languages.map(lang => <option key={lang.id} value={lang.id}>{lang.name}</option> )}
          </FormSelect>
        </div>
      </Form>
    </Formik>
}