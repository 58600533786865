import api from "./api";
import resources from "./resources";
import {lang} from "../languages";
import {getActionRoute} from "../utils/orders";

export function myOrders() {
  return api().get(`/${lang()}/${resources.my_order}`)
}

export function getStatuses() {
  return api().get(`/${lang()}/${resources.order_status}`)
}

export function index() {
  return api().get(`/${lang()}/${resources.order}`)
}

export function orderAction(id, action, data) {
  return api().post(`/${lang()}/${resources.order}/${id}/${getActionRoute(action)}`, data)
}

export function destroy(id) {
  return api().delete(`${lang()}/${resources.order}/${id}`)
}

export function sendBack(id, data) {
  return orderAction(id, 'send-back', data)
}