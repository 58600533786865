import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import * as AddressAPI from "../api/addresses";
import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import Spinner from "../components/common/Spinner";

export default function AddressForm({address, isCreating, closeModal}) {
  const defaultValues = {
    name: "",
    users: []
  }
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const {t} = useTranslation(['fields', 'forms'])

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
  }

  const create = (data, {resetForm}) => {
    reset()
    return AddressAPI.store(data)
      .then(() => {
        resetForm(defaultValues)
        closeModal()
      })
      .catch(handleError)
  }

  const update = data => {
    reset()
    return AddressAPI.update(address.id, data)
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  return <Formik
    initialValues={address || defaultValues}
    enableReinitialize
    onSubmit={create}
  >
    {({isSubmitting}) => (
      <Form>
        {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
        <div className="grid sm:grid-cols-3 gap-y-4 gap-x-2">
          <div className="sm:col-span-3">
            <Input
              name="street"
              label={t('street')}
              placeholder={t('street')}
              async={!isCreating ? update : false}
              error={errors.street}
              required
            />
          </div>
          <div>
            <Input
              name="npa"
              label={t('npa')}
              placeholder={t('npa')}
              async={!isCreating ? update : false}
              error={errors.npa}
              required
            />
          </div>
          <div className="sm:col-span-2">
            <Input
              name="city"
              label={t('city')}
              placeholder={t('city')}
              async={!isCreating ? update : false}
              error={errors.npa}
              required
            />
          </div>
          <div className="sm:col-start-2 sm:text-center">
            {isCreating && <PrimaryBtn disabled={isSubmitting}>{isSubmitting ? <Spinner className="w-5 h-5"/> : t('forms:save')}</PrimaryBtn>}
          </div>
        </div>
      </Form>
    )}
  </Formik>
}