import BackOffice from "../../../../components/common/Layout/BackOffice";
import {useTranslation} from "react-i18next";
import * as TaskCategoriesAPI from "../../../../api/taskCategories"
import * as TasksAPI from "../../../../api/tasks"
import {useEffect, useState} from "react";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import Category from "../../../../components/tasks/Category";
import Task from "../../../../components/tasks/Task";
import PrimaryBtn from "../../../../components/common/btns/solid/PrimaryBtn";
import SecondaryBtn from "../../../../components/common/btns/solid/SecondaryBtn";
import TaskForm from "../../../../forms/TaskForm";
import Modal from "../../../../components/common/modals/Modal";
import TaskCategoryForm from "../../../../forms/TaskCategoryForm";
import {useToast} from "../../../../providers/ToastProvider";

export default function TaskCategories() {
  const {t} = useTranslation(['routes', 'pages'])
  const [categories, setCategories] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const toast = useToast()

  useEffect(() => {
    update()
    //eslint-disable-next-line
  }, [])

  const update = () => {
    TaskCategoriesAPI.get()
      .then(setCategories)
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  }

  const onDragEnd = ({destination, source, type}) => {
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    if (type === 'column') {
      const newColumnOrder = Array.from(categories)
      const columnVal = newColumnOrder[source.index]
      newColumnOrder.splice(source.index, 1)
      newColumnOrder.splice(destination.index, 0, columnVal)

      setCategories(newColumnOrder)
      TaskCategoriesAPI.put(columnVal.id, {rank: destination.index})
        .catch(() => update())
      return;
    }

    const home = categories.find((category) => `cat-${category.id}` === source.droppableId)
    const foreign = categories.find((category) => `cat-${category.id}` === destination.droppableId)

    if (home === foreign) {
      const newTasks = Array.from(home.tasks)
      const task = newTasks[source.index]
      newTasks.splice(source.index, 1)
      newTasks.splice(destination.index, 0, task)

      const newHome = {
        ...home,
        tasks: newTasks
      }

      setCategories(categories.map(category => category.id !== home.id ? category : newHome))
      TasksAPI.update(task.id, {rank: destination.index})
        .catch(() => update())
      return;
    }

    const homeTasks = Array.from(home.tasks)
    const task = homeTasks[source.index]
    homeTasks.splice(source.index, 1)
    const newHome = {
      ...home,
      tasks: homeTasks
    }

    const foreignTasks = Array.from(foreign.tasks)
    foreignTasks.splice(destination.index, 0, task)
    const newForeign = {
      ...foreign,
      tasks: foreignTasks
    }

    setCategories(categories.map(category => category.id !== home.id ? (category.id !== foreign.id ? category : newForeign) : newHome))
    TasksAPI.update(task.id, {rank: destination.index, task_category_id: foreign.id})
  }
  const [categoryModal, setCategoryModal] = useState(false)
  const [taskModal, setTaskModal] = useState(false)

  const closeModal = () => {
    setCategoryModal(false)
    setTaskModal(false)
    update()
  }

  return <BackOffice role="calendar-admin" isLoading={isLoading}>
    <h1>{t('task-categories.name')}</h1>
    <div className="mt-5 flex flex-row justify-start space-x-2">
      <SecondaryBtn onClick={() => setCategoryModal(true)}>{t('pages:task-categories.create-category')}</SecondaryBtn>
      <Modal
        title={t('pages:task-categories.create-category')}
        isOpen={categoryModal}
        closeModal={closeModal}
        className="w-full max-w-4xl"
      >
        <TaskCategoryForm isCreating closeModal={closeModal}/>
      </Modal>
      <PrimaryBtn onClick={() => setTaskModal(true)}>{t('pages:task-categories.create-task')}</PrimaryBtn>
      <Modal
        title={t('pages:task-categories.create-task')}
        isOpen={taskModal}
        closeModal={closeModal}
        className="w-full max-w-4xl"
      >
        <TaskForm isCreating task={{}} categories={categories} closeModal={closeModal}/>
      </Modal>
    </div>
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all-columns" type="column">
        {provided => <div
            className="flex flex-col divide-y space-y-4 mt-10"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {categories.map((category, id) => <Category key={category.id} category={category} index={id} refresh={update}>
                {category.tasks.map((task, id) => <Task categories={categories} key={task.id} task={task} index={id} refresh={update}/>)}
              </Category>
            )}
            {provided.placeholder}
          </div>
        }
      </Droppable>
    </DragDropContext>
  </BackOffice>
}