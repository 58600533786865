import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as articleAPI from "../api/articles";
import * as promosAPI from "../api/promos";
import {FieldArray, Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import Spinner from "../components/common/Spinner";
import FormSelect from "../components/common/inputs/FormSelect";
import TranslatedInputs from "../components/common/inputs/TranslatedInputs";
import Editor from "../components/common/inputs/Editor";
import Switch from "../components/common/inputs/Switch";
import Card from "../components/common/Card";
import TransparentBtn from "../components/common/btns/TransparentBtn";
import {PromoUse} from "./PromoForm";
import {PlusIcon, TrashIcon} from "@heroicons/react/outline";
import DangerBtn from "../components/common/btns/solid/DangerBtn";
import {useToast} from "../providers/ToastProvider";

const defaultArticleSku = {
  text: "",
  price: "",
  solde: "",
  quantity: "",
}

const defaultValues = {
  promo_nominative: 0,
  promo_uses: "",
  promo_duration: "",
  promo_actions: [],
  article_category_id: "",
  name: {
    fr: "",
    de: "",
    en: ""
  },
  description: {
    fr: "",
    de: "",
    en: ""
  },
  pictures: [],
  article_type_id: "",
  articles: [defaultArticleSku]
}

const defaultPromoAction = {
  amount: "",
  uses: "",
  promo_type_id: "",
  promo_context_id: ""
}

function PromoSection({values, errors}) {
  const [promoContexts, setPromoContexts] = useState([])
  const [promoTypes, setPromoTypes] = useState([])
  const {t} = useTranslation('fields')
  const toast = useToast()

  useEffect(() => {
    promosAPI.getTypes()
      .then(setPromoTypes)
      .catch(() => toast.pushUnKnown())
    promosAPI.getContextes()
      .then(setPromoContexts)
      .catch(() => toast.pushUnKnown())
    //eslint-disable-next-line
  }, [])

  return <>
    <div className="col-start-1">
      <Switch
        label={t('nominative')}
        error={errors.promo_nominative}
        name="promo_nominative"
      />
    </div>
    <Input
      label={t('uses')}
      placeholder={t('uses')}
      name="promo_uses"
      error={errors.promo_uses}
    />
    <Input
      label={t('duration', {time: t('translation:months')})}
      placeholder={t('duration', {time: t('translation:months')})}
      name="promo_duration"
      error={errors.promo_duration}
    />
    <FieldArray name="promo_actions">
      {arrayHelpers => <Card className="col-span-full">
        <b>{t('pages:promo-code-check.promo-uses')}</b>
        {errors.promo_actions && <p className="text-red-600">{errors.promo_actions}</p>}
        <div className="flex flex-col space-y-2 divide-y divide-secondary">
          {values.promo_actions?.map((promo_use, id) => <PromoUse
            key={id}
            promoUse={promo_use}
            errors={errors}
            remove={() => arrayHelpers.remove(id)}
            id={id}
            name="promo_actions"
            promoContexts={promoContexts}
            promoTypes={promoTypes}
            values={values}
          />)}
        </div>
        {values.promo_actions.length < 4 && <TransparentBtn type="button" onClick={() => arrayHelpers.push(defaultPromoAction)} className="text-secondary">
          {t('add-promo-uses')} +
        </TransparentBtn>}
      </Card>}
    </FieldArray>
  </>
}

function Picture({picture, remove}) {
  return <div className="max-w-xs max-h-44 relative">
    <div className="h-full">
      <img className="max-w-full max-h-full rounded-xl" src={picture} alt=""/>
    </div>
    <div className="absolute top-2 left-2 rounded-full bg-white bg-opacity-40 px-1">
      <TransparentBtn type="button" className="text-primary" onClick={remove}>
        <TrashIcon className="w-3 h-3"/>
      </TransparentBtn>
    </div>
  </div>
}

export default function ArticleForm({closeModal, categories, types}) {
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [creating, setCreating] = useState(false)
  const {t} = useTranslation(['fields', 'errors'])

  const create = data => {
    setShowGlobalError(false)
    setErrors({})
    setCreating(true)

    let formData = new FormData();
    formData.append('name[fr]', data.name.fr)
    formData.append('name[en]', data.name.en)
    formData.append('name[de]', data.name.de)
    formData.append('description[fr]', data.description.fr)
    formData.append('description[en]', data.description.en)
    formData.append('description[de]', data.description.de)

    data.pictures.map((pic, id) => formData.append(`pictures[${id}]`, pic))
    formData.append('article_type_id', data.article_type_id)

    data.articles.forEach((article, id) => {
      formData.append(`articles[${id}][text]`, article.text)
      formData.append(`articles[${id}][price]`, article.price)
      formData.append(`articles[${id}][solde]`, article.solde)
      formData.append(`articles[${id}][quantity]`, article.quantity)
    })

    if (data.article_type_id !== 'physic') {
      formData.append('promo_nominative', data.promo_nominative)
      formData.append('promo_uses', data.promo_uses)
      formData.append('promo_duration', data.promo_duration)
      formData.append('promo_actions', data.promo_actions)
      data.promo_actions.forEach((promo_action, id) => {
        formData.append(`promo_actions[${id}][amount]`, promo_action.amount)
        formData.append(`promo_actions[${id}][uses]`, promo_action.uses)
        formData.append(`promo_actions[${id}][promo_type_id]`, promo_action.promo_type_id)
        formData.append(`promo_actions[${id}][promo_context_id]`, promo_action.promo_context_id)
      })
    }

    return articleAPI.store(data.article_category_id, formData)
      .then(() => closeModal())
      .catch(handleError)
      .then(() => setCreating(false))
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  return <Formik
    initialValues={defaultValues}
    enableReinitialize
    onSubmit={create}
  >
    {({values, setFieldValue}) => <Form className="w-full">
      {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
      <div className="w-full grid gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <div className="w-full">
          <FormSelect
            label={t('category')}
            name="article_category_id"
            error={errors.article_category_id}
            required
          >
            <option>---</option>
            {categories.map((category, id) => <option key={id} value={category.id}>{category.name}</option>)}
          </FormSelect>
        </div>
        {/*todo: bug d'affichage sur smartphone*/}
        <div className="col-span-full">
          <TranslatedInputs
            errors={errors}
          >
            <Input
              label={t('title')}
              placeholder={t('title')}
              name="name"
              required
            />
            <Editor
              label={t('description')}
              name="description"
              required
            />
          </TranslatedInputs>
        </div>
        <Card className="col-span-full">
          <Input
            label={t('pictures')}
            name="file"
            type="file"
            error={errors.pictures}
            multiple
            accept="image/png, image/jpeg, image/jpg, image/gif"
            required
            onChange={event => {
              setFieldValue('pictures', [
                ...values.pictures,
                ...Array.from(event.target.files)
              ])
            }}
          />
          <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-auto gap-2">
            {values.pictures.map((pic, id) => <Picture
              key={id}
              picture={URL.createObjectURL(pic)}
              remove={() => setFieldValue('pictures', values.pictures.filter((pic, idx) => id !== idx))}
            /> )}
          </div>
        </Card>
        <FormSelect
          label={t('type')}
          name="article_type_id"
          error={errors.article_type_id}
          required
        >
          <option>---</option>
          {types.map((type, id) => <option key={id} value={type.id}>{type.name}</option>)}
        </FormSelect>
        {values.article_type_id === 'promo-code' && <PromoSection errors={errors} values={values}/>}
        <FieldArray name="articles">
          {arrayHelpers => <Card className="col-span-full">
            {values.article_type_id === 'physic' && <b>{t('pages:articles.skus')}</b>}
            {errors.articles && <p className="text-red-600">{errors.articles}</p>}
            <div className="flex flex-col divide-y divide-secondary">
              {values.articles?.map((sku, id) => <div className="grid py-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
                {values.article_type_id === 'physic' && <Input
                  label={t('sku_id')}
                  placeholder={t('sku_id')}
                  name={`articles.${id}.text`}
                  error={errors[`articles.${id}.text`]}
                  required={values.articles?.length > 1}
                />}
                <Input
                  type="number"
                  label={t('price')}
                  placeholder={t('price')}
                  name={`articles.${id}.price`}
                  error={errors[`articles.${id}.price`]}
                  required
                />
                <Input
                  type="number"
                  label={t('solde')}
                  placeholder={t('solde')}
                  name={`articles.${id}.solde`}
                  error={errors[`articles.${id}.solde`]}
                />
                {values.article_type_id === 'physic' && <Input
                  type="number"
                  label={t('quantity')}
                  placeholder={t('quantity')}
                  name={`articles.${id}.quantity`}
                  error={errors[`articles.${id}.quantity`]}
                  required
                />}
                <div className="col-start-1">
                  {values.articles?.length > 1 && <DangerBtn onClick={() => arrayHelpers.remove(id)} role="button"><TrashIcon className="w-5 h-5"/></DangerBtn>}
                </div>
              </div>)}
            </div>
            {values.article_type_id === 'physic' && <PrimaryBtn type="button" onClick={() => arrayHelpers.push(defaultArticleSku)}>
              <PlusIcon className="w-4 h-4"/>
            </PrimaryBtn>}
          </Card>}
        </FieldArray>
      </div>
      <div className="mt-4 flex justify-center">
        <PrimaryBtn
          type="submit"
          className="flex flex-row"
          disabled={creating}
        >
          {creating && <Spinner className="w-5 h-5 mr-2"/>} {t('forms:save')}
        </PrimaryBtn>
      </div>
    </Form>}
  </Formik>
}