import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import { stripHtml } from "string-strip-html";

export default function Card ({
  link,
  article,
}) {
  const [brief, setBrief] = useState("")

  useEffect(() => {
    setBrief(stripHtml(article.description || "").result)
  }, [article.description])

  return (
    <div className="w-full h-full flex flex-col justify-center py-10 px-8 bg-white rounded-lg shadow-2xl">
      <Link to={link} as="button" className="text-primary hover:no-underline hover:text-primary flex-grow">
        <div className="">
          <p className="text-2xl uppercase font-bold">{article.name}</p>
          <p className="uppercase text-sm truncate">{brief}</p>
        </div>
        <div className="py-4 rounded-xl">
          <img src={article.pictures && article.pictures[0] && article.pictures[0].picture} alt="" className="w-full h-60 object-scale-down object-center"/>
        </div>
      </Link>
    </div>
  )
}