import Card from "../common/Card";
import TransparentBtn from "../common/btns/TransparentBtn";
import {PencilIcon, XCircleIcon} from "@heroicons/react/outline";
import DeleteDialogModal from "../common/modals/DeleteDialogModal";
import Modal from "../common/modals/Modal";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {destroy} from "../../api/workinHours";
import WorkingHourForm from "../../forms/WorkingHourForm";

export default function WorkingHour({workingHour, onChange}) {
  const {t} = useTranslation(['pages', 'fields'])

  const [dialogModal, setDialogModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const closeModal = () => {
    setDialogModal(false)
    setOpenModal(false)
    onChange()
  }

  const deleteWorkingHour = () => {
    return destroy(workingHour.id)
      .then(closeModal)
  }
  return <Card className="max-w-3xl flex flex-row space-x-2">
    <div className="flex flex-col flex-grow">
      <div className="flex flex-col sm:flex-row sm:space-x-2 font-bold mb-2">
        <p>{t('fields:start')} {workingHour.start}</p>
        <p>{t('fields:end')} {workingHour.end}</p>
      </div>
      <p>{workingHour.address?.street}, {workingHour.address?.city}</p>
    </div>
    <div className="flex flex-row space-x-2 justify-between">
      <TransparentBtn className="flex flex-row justify-end" onClick={() => setOpenModal(true)}><PencilIcon className="h-5 w-5"/></TransparentBtn>
      <TransparentBtn className="flex flex-row justify-end text-red-600" onClick={() => setDialogModal(true)}><XCircleIcon className="h-5 w-5"/></TransparentBtn>
    </div>
    <DeleteDialogModal
      isOpen={dialogModal}
      closeModal={() => setDialogModal(false)}
      title={t('working-hours.delete.title')}
      description={t('working-hours.delete.description')}
      action={deleteWorkingHour}
    />
    <Modal
      title={t('working-hours.edit')}
      isOpen={openModal}
      closeModal={closeModal}
      className="w-full max-w-xl"
    >
      <WorkingHourForm workingHour={workingHour} closeModal={closeModal}/>
    </Modal>
  </Card>
}