import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import PrimaryBtn from "../components/common/btns/solid/PrimaryBtn";
import Spinner from "../components/common/Spinner";
import * as UserAddressAPI from "../api/userAddresses"

export default function UserAddressForm({values, isCreating, closeModal, ...props}) {
  const defaultValues = {
    firstname: '',
    lastname: '',
    street: '',
    npa: '',
    city: ''
  }
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const {t} = useTranslation(['fields', 'errors', 'forms'])

  const reset = () => {
    setShowGlobalError(false)
    setErrors({})
  }
  const create = data => {
    reset()
    return UserAddressAPI.store(data)
      .then(() => closeModal())
      .catch(handleError)
  }
  const update = data => {
    reset()
    return UserAddressAPI.update(values.id, data)
      .catch(handleError)
  }

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  return <Formik
    initialValues={values || defaultValues}
    onSubmit={create}
    enableReinitialize
  >
    {({ isSubmitting }) => (
      <Form {...props}>
        {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
        <div className="flex flex-col md:grid md:grid-cols-4 md:gap-2 md:space-y-0 space-y-2 mt-2 mx-4">
          <div className="col-span-2">
            <Input
              name="firstname"
              type="text"
              label={t('firstname')}
              placeholder={t('firstname')}
              error={errors.firstname}
              async={!isCreating ? update : false}
              required
              />
          </div>
          <div className="col-span-2">
            <Input
              name="lastname"
              type="text"
              label={t('lastname')}
              placeholder={t('lastname')}
              error={errors.lastname}
              async={!isCreating ? update : false}
              required/>
          </div>
          <div className="col-span-4">
            <Input
              name="street"
              type="text"
              label={t('street')}
              placeholder={t('street')}
              error={errors.street}
              async={!isCreating ? update : false}
              required/>
          </div>
          <div className="">
            <Input
              name="npa"
              type="text"
              label={t('npa')}
              placeholder={t('npa')}
              error={errors.npa}
              async={!isCreating ? update : false}
              required/>
          </div>
          <div className="col-span-3">
            <Input
              name="city"
              type="text"
              label={t('city')}
              placeholder={t('city')}
              error={errors.city}
              async={!isCreating ? update : false}
              required/>
          </div>
          {isCreating && <PrimaryBtn className="mt-4 self-start flex flex-row justify-around" disabled={isSubmitting}>{isSubmitting && <Spinner className="h-5 w-5 mr-3"/>}{t('forms:save')}</PrimaryBtn>}
        </div>
      </Form>
    )}
  </Formik>
}