import React, {useEffect, useState} from "react";
import { useSpring, animated } from "react-spring";

function Burger (props) {
  const [isOpen, toggle] = useState(props.open);

  const first = useSpring({
    transform: isOpen
      ? "translate(3px, 32px) rotate(-45deg)"
      : "translate(2px, 7px) rotate(0deg)"
  });
  const second = useSpring({
    transform: isOpen
      ? "translate(18px, 18px) scaleX(0)"
      : "translate(5px, 20px) scaleX(1)"
  });
  const third = useSpring({
    transform: isOpen
      ? "translate(5px, 4px) rotate(45deg)"
      : "translate(2px, 33px) rotate(0deg)"
  });

  useEffect(() => {
    toggle(props.open)
  }, [props.open])

  return (
    <div className="w-14 cursor-pointer lg:hidden">
      <svg
        onClick={() => {
          toggle(!isOpen)
          props.onClick()
        }}
        viewBox="0 0 44 44"
        xmlns="http://www.w3.org/2000/svg"
      >
        <animated.rect className={"fill-current text-secondary"} width="40" height="3" rx="2" style={first}/>
        <animated.rect className={"fill-current text-primary"} width="34" height="3" rx="2" style={second}/>
        <animated.rect className={"fill-current text-secondary"} width="40" height="3" rx="2" style={third}/>
      </svg>
    </div>
  );
};

export default Burger;
