import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as articleAPI from "../api/articles";
import * as promosAPI from "../api/promos";
import {Form, Formik} from "formik";
import Input from "../components/common/inputs/Input";
import TranslatedInputs from "../components/common/inputs/TranslatedInputs";
import Editor from "../components/common/inputs/Editor";
import Switch from "../components/common/inputs/Switch";
import Card from "../components/common/Card";
import TransparentBtn from "../components/common/btns/TransparentBtn";
import {TrashIcon} from "@heroicons/react/outline";
import {convertDotDataToJson} from "../utils/formConverter";
import ArticlePromoActionForm from "./ArticlePromoActionForm";
import ArticleSkuForm from "./ArticleSkuForm";
import {useToast} from "../providers/ToastProvider";

function ArticleSkuSection({article_skus, article}) {
  const [errors, setErrors] = useState({})
  const [skus, setSkus] = useState([])

  const {t} = useTranslation(['pages'])
  const handleRemove = sku_id => {
    articleAPI.removeArticleSku(article.id, sku_id)
      .then(() => setSkus(prevState => prevState.filter(action => action.id !== sku_id)))
      .catch(error => setErrors(error?.response?.data?.errors || {}))
  }

  const handleStore = data => {
    setSkus(prev => [...prev, data])
  }

  useEffect(() => {
    setSkus(article_skus)
  }, [article_skus])

  return <Card className="mt-2">
    {article.type?.id === 'physic' && <b>{t('pages:articles.skus')}</b>}
    {errors.articles && <p className="text-red-600">{errors.articles}</p>}
    <div className="flex flex-col space-y-2 divide-y divide-secondary">
      {skus?.map((sku, id) => <ArticleSkuForm
        key={id}
        remove={() => handleRemove(sku.id)}
        article_sku={sku}
        articleId={article.id}
        articleTypeId={article?.type?.id}
        can_delete={skus.length > 1}
      />)}
    </div>
    {article.type?.id === 'physic' && <ArticleSkuForm
      store={handleStore}
      isCreating
      articleId={article.id}
      articleTypeId={article?.type?.id}
    />}
  </Card>
}

function PromoActionsSection({articlePromoActions, articleId}) {
  const {t} = useTranslation('fields')
  const [errors, setErrors] = useState({})
  const [promoTypes, setPromoTypes] = useState([])
  const [promoContexts, setPromoContexts] = useState([])
  const [promoActions, setPromoActions] = useState([])
  const toast = useToast()

  const handleRemove = id => {
    articleAPI.removePromoAction(id)
      .then(() => setPromoActions(prevState => prevState.filter(action => action.id !== id)))
      .catch(error => setErrors(error.response.data.errors))
  }

  const handleStore = data => {
    setPromoActions([...promoActions, data])
  }

  useEffect(() => {
    promosAPI.getTypes()
      .then(setPromoTypes)
      .catch(() => toast.pushUnKnown())
    promosAPI.getContextes()
      .then(setPromoContexts)
      .catch(() => toast.pushUnKnown())
    //eslint-disable-next-line
  }, [])

  useEffect(() => setPromoActions(articlePromoActions), [articlePromoActions])

  return <Card className="mt-2">
    <b>{t('pages:promo-code-check.promo-uses')}</b>
    {errors.promo_actions && <p className="text-red-600">{errors.promo_actions}</p>}
    <div className="flex flex-col space-y-2 divide-y divide-secondary">
      {promoActions.map((promoAction, id) => <ArticlePromoActionForm
        key={id}
        remove={() => handleRemove(promoAction.id)}
        promoTypes={promoTypes}
        promoContexts={promoContexts}
        promoAction={promoAction}
      />)}
    </div>
    {promoActions.length < 4 && <ArticlePromoActionForm
      promoTypes={promoTypes}
      promoContexts={promoContexts}
      store={handleStore}
      isCreating
      articleId={articleId}
    /> }
  </Card>
}

function PromoSection({errors, update}) {
  const {t} = useTranslation('fields')

  return <>
    <Switch
      label={t('nominative')}
      error={errors.promo_nominative}
      name="promo_nominative"
      async={update}
    />
    <Input
      label={t('uses')}
      placeholder={t('uses')}
      name="promo_uses"
      error={errors.promo_uses}
      async={update}
    />
    <Input
      label={t('duration', {time: t('translation:months')})}
      placeholder={t('duration', {time: t('translation:months')})}
      name="promo_duration"
      error={errors.promo_duration}
      async={update}
    />
  </>
}

function Picture({picture, canRemove, remove}) {
  return <div className="max-w-xs max-h-44 relative">
    <div className="h-full">
      <img className="max-w-full max-h-full rounded-xl" src={picture} alt=""/>
    </div>
    {canRemove &&<div className="absolute top-2 left-2 rounded-full bg-white bg-opacity-40 px-1">
      <TransparentBtn type="button" className="text-primary" onClick={remove}>
        <TrashIcon className="w-3 h-3"/>
      </TransparentBtn>
    </div>}
  </div>
}

export default function ArticleEditForm({article}) {
  const [errors, setErrors] = useState({})
  const [showGlobalError, setShowGlobalError] = useState(false)
  const [pictures, setPictures] = useState([])
  const {t, i18n} = useTranslation(['fields', 'errors'])

  const handleError = error => {
    if (error.response.status !== 422) {
      setShowGlobalError(true)
    } else {
      setErrors(error.response.data.errors)
    }
  }

  const update = data => {
    return articleAPI.update(article.id, convertDotDataToJson(data))
      .catch(handleError)
  }

  useEffect(() => setPictures(article.pictures), [article.pictures])
  return <>
    <Formik
      initialValues={{...article, file: ""}}
      enableReinitialize
      onSubmit={() => {}}
    >
      <Form>
        {showGlobalError && <div className="text-red-600 text-sm">{t('errors:unknown')}</div>}
        <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <dl>
            <dt>{t('category')}</dt>
            <dd>{article.category?.name[i18n.language]}</dd>
            <dt>{t('type')}</dt>
            <dd>{article.type?.name}</dd>
          </dl>
          <div className="col-span-full">
            <TranslatedInputs
              errors={errors}
            >
              <Input
                label={t('title')}
                placeholder={t('title')}
                name="name"
                required
                async={update}
              />
              <Editor
                label={t('description')}
                name="description"
                required
                async={update}
              />
            </TranslatedInputs>
          </div>
          <Card className="col-span-full">
            <Input
              label={t('pictures')}
              name="file"
              type="file"
              error={errors.pictures}
              accept="image/*"
              multiple
              required
              onChange={event => {
                setErrors({...errors, pictures: null})
                let formData = new FormData()
                Array.from(event.target.files).forEach((file, id) => {
                  formData.append(`pictures[${id}]`, file)
                })
                articleAPI.addPictures(article.id, formData)
                  .then(setPictures)
                  .catch(handleError)
              }}
            />
            <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-auto gap-2">
              {pictures.map((pic, id) => <Picture
                key={id}
                picture={pic.picture}
                canRemove={pictures.length > 1}
                remove={() => {
                  articleAPI.removePicture(pic.id)
                    .then(() => setPictures(oldPics => oldPics.filter((pic, idx) => id !== idx)))
                    .catch(handleError)
                }}
              /> )}
            </div>
          </Card>
          {article.type?.id === 'promo-code' && <PromoSection errors={errors} update={update}/>}
        </div>
      </Form>
    </Formik>
    {article.type?.id === 'promo-code' && <PromoActionsSection articlePromoActions={article.promo_actions || []} articleId={article.id} />}
    <ArticleSkuSection article_skus={article.articles || []} article={article}/>
  </>
}