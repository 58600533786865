export default function Error({error, description}) {
  return <div className="
      min-h-screen
      bg-cover bg-center
      bg-404
      flex flex-col justify-center
    ">
      <div className="p-4 bg-white bg-opacity-40">
        <p className="text-center text-9xl font-bold">{error}</p>
        <p className="text-center font-bold text-4xl text-primary-dark">{description}</p>
      </div>
    </div>
}