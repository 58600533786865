import React from "react";
import languages from "../../../languages";
import TabsWrapper from "../tabs/TabsWrapper";
import {Tab} from "@headlessui/react";

function TranslateInput({input, lang, errors}) {
  if (input)
    return <div className="mb-4">{React.cloneElement(input, {
      ...input.props,
      name: `${input.props.name}.${lang}`,
      label: `${input.props.label} ${lang}`,
      error: errors[`${input.props.name}.${lang}`]
    })}</div>
  return null
}

function Errors({errors}) {
  return <ul className="text-red-600 text-sm">
    {Object.keys(errors).map(error => <li>
      {errors[error]}
    </li>)}
  </ul>
}

export default function TranslatedInputs({errors, children}) {
  return <div className="w-full">
    {errors && <Errors errors={errors}/>}
    <TabsWrapper titles={languages.map(lang => lang.name)}>
      {languages.map((lang, id) => <Tab.Panel className="focus:outline-none" key={id}>
        {Array.isArray(children) ?
          children.map((child, idx) => <TranslateInput key={idx} input={child} lang={lang.id} errors={errors}/>)
          :
          <TranslateInput input={children} lang={lang.id} errors={errors}/>
        }
      </Tab.Panel>)}
    </TabsWrapper>
  </div>
}