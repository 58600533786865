import {
  CalendarIcon,
  CollectionIcon,
  LocationMarkerIcon,
  PencilIcon,
  PuzzleIcon,
  UserGroupIcon
} from "@heroicons/react/outline";
import CheckoutTabs from "../components/common/tabs/CheckoutTabs";
import ChooseCategory from "../components/appointment/ChooseCategory";
import ChooseTask from "../components/appointment/ChooseTask";
import AppointmentProvider from "../providers/AppointmentProvider";
import ChoosePractitioner from "../components/appointment/ChoosePractitioner";
import ChooseLocation from "../components/appointment/ChooseLocation";
import ChooseDate from "../components/appointment/ChooseDate";
import FillDetails from "../components/appointment/FillDetails";
import SimplePage from "../components/common/Layout/SimplePage";
import HelmetSEO from "../utils/HelmetSEO";

export default function Appointment() {
  return <SimplePage>
    <HelmetSEO page="appointment"/>
    <AppointmentProvider>
      <CheckoutTabs elements={[
        {
          name: 'Catégories',
          icon: <CollectionIcon className="w-8 h-8"/>
        },
        {
          name: 'Prestations',
          icon: <PuzzleIcon className="w-8 h-8"/>
        },
        {
          name: 'Praticiens',
          icon: <UserGroupIcon className="w-8 h-8"/>
        },
        {
          name: 'Lieu',
          icon: <LocationMarkerIcon className="w-8 h-8"/>
        },
        {
          name: 'Date',
          icon: <CalendarIcon className="w-8 h-8"/>
        },
        {
          name: 'Informations Personnelles',
          icon: <PencilIcon className="w-8 h-8"/>
        }
      ]}>
        <ChooseCategory/>
        <ChooseTask/>
        <ChoosePractitioner/>
        <ChooseLocation/>
        <ChooseDate/>
        <FillDetails/>
      </CheckoutTabs>
    </AppointmentProvider>
  </SimplePage>
}