import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";

i18n
  .use(LanguageDetector)
  .use(resourcesToBackend(((language, namespace, callback) => {
    import(`../public/locales/${language}/${namespace}.json`)
      .then((resources) => {
        callback(null, resources)
      })
      .catch((error) => {
        callback(error, null)
      })
  })))
  .use(initReactI18next)
  .init({
    detection: {
      order: ['path', 'cookie', 'localStorage', 'sessionStorage', 'navigator']
    },
    supportedLngs: ['fr', 'de', 'en'],
    debug: false,

    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    }
  })

export default i18n;