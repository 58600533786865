import {XIcon} from "@heroicons/react/outline";
import {useEffect} from "react";

export default function ToastMessage({id, message, color, header, onRemove, lifetime}) {
  useEffect(() => {
    if (lifetime && onRemove) {
      setTimeout(() => {
        onRemove(id);
      }, lifetime);
    }
  }, [id, lifetime, onRemove]);

  return <div
    className={`
      flex w-full visible flex-row shadow-lg border-l-4 rounded-md
      duration-100 cursor-pointer transform transition-all hover:scale-102
      bg-white border-${color}`}
  >
    <div className="flex flex-row p-2 flex-no-wrap w-full">
      <div className="flex flex-col flex-no-wrap px-1 w-full">
        <div className={`flex my-auto font-bold select-none text-${color}`}>{header}</div>
        <p className="-mt-0.5 my-auto flex text-gray-600 text-sm">
          {message}
        </p>
      </div>
      <div
        onClick={() => onRemove && onRemove(id)}
        className="w-10 h-12 mr-2 items-center mx-auto text-center leading-none text-lg"
      >
        <XIcon className="mx-auto my-auto h-5 text-center text-gray-600 cursor-pointer hover:scale-105 transform"/>
      </div>
    </div>
  </div>
}