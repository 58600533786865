import BackOffice from "../../../../components/common/Layout/BackOffice";
import {useTranslation} from "react-i18next";
import {useCallback, useEffect, useState} from "react";
import * as EventsAPI from "../../../../api/events"
import PrimaryBtn from "../../../../components/common/btns/solid/PrimaryBtn";
import Card from "../../../../components/common/Card";
import Date from "../../../../components/Events/Date";
import Time from "../../../../components/Events/Time";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DuplicateIcon,
  EyeIcon,
  EyeOffIcon
} from "@heroicons/react/outline";
import {getFormattedTime, momentWithLocales} from "../../../../utils/dates";
import OutlineSecondaryBtn from "../../../../components/common/btns/outline/OutlineSecondaryBtn";
import DangerBtn from "../../../../components/common/btns/solid/DangerBtn";
import Modal from "../../../../components/common/modals/Modal";
import EventForm from "../../../../forms/EventForm";
import LoadingWrapper from "../../../../components/common/placeholders/LoadingWrapper";
import DeleteDialogModal from "../../../../components/common/modals/DeleteDialogModal";
import {useToast} from "../../../../providers/ToastProvider";
import SecondaryBtn from "../../../../components/common/btns/solid/SecondaryBtn";
import Participants from "../../../../components/Events/Participants";

function AdminEventRow({event, update}) {
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [participantsModal, setParticipantsModal] = useState(false)
  const [copyModal, setCopyModal] = useState(false)
  const {t, i18n} = useTranslation('pages')
  const toast = useToast()

  const closeModal = () => {
    setEditModal(false)
    setDeleteModal(false)
    setParticipantsModal(false)
    setCopyModal(false)
  }

  const closeAndUpdate = () => {
    closeModal()
    update()
  }

  const deleteEvent = () => {
    return EventsAPI.destroy(event.id)
      .then(closeAndUpdate)
      .catch(handleError)
  }

  const handleError = error => {
    toast.pushUnKnown()
  }

  return <Card className={event.deleted_at ? 'text-red-600' : ''}>
    <div className="flex items-center text-xl font-semibold mb-2"><div className="w-5 h-5 mr-2">{event.visible ? <EyeIcon/> : <EyeOffIcon/>}</div> {event.translated_title}</div>
    <div className="flex flex-wrap">
      <div className="mr-2"><Date event={event}/></div>
      <div><Time event={event}/></div>
    </div>
    <div>{event.location}</div>
    <div>{t('events.participants')}: {event.nb_participants}/{event.available_seats}</div>
    <div className="mt-4 flex space-x-2">
      <SecondaryBtn onClick={() => setParticipantsModal(true)}>{t('events.participants')}</SecondaryBtn>
      <Modal isOpen={participantsModal} closeModal={closeModal} className="w-full max-w-screen-2xl">
        <Participants event={event} update={update}/>
      </Modal>
      {!event.deleted_at && (
        <>
          <PrimaryBtn onClick={() => setEditModal(true)}>{t('events.manage')}</PrimaryBtn>
          <Modal
            isOpen={editModal}
            closeModal={closeAndUpdate}
            className="w-full max-w-screen-xl"
          >
            <EventForm closeModal={closeAndUpdate} event={{
              ...event,
              start_date: momentWithLocales(event.start_date),
              end_date: momentWithLocales(event.end_date),
              start_time: getFormattedTime(event.start_time, i18n.language),
              end_time: getFormattedTime(event.end_time, i18n.language),
            }}/>
          </Modal>
          <DangerBtn onClick={() => setDeleteModal(true)}>{t('events.delete.btn')}</DangerBtn>
          <DeleteDialogModal
            isOpen={deleteModal}
            closeModal={closeModal}
            action={deleteEvent}
            description={t('events.delete.description')}
            title={t('events.delete.title')}
          />
        </>
      )}
      <OutlineSecondaryBtn className="flex items-center" onClick={() => setCopyModal(true)}><DuplicateIcon className="w-5 h-5 mr-2"/>{t('events.copy.btn')}</OutlineSecondaryBtn>
      <Modal isOpen={copyModal} closeModal={closeModal} title={t('events.copy.title')}>
        <EventForm isCreating closeModal={closeAndUpdate} event={{
          ...event,
          start_date: "",
          end_date: "",
          start_time: "",
          end_time: "",
        }}/>
      </Modal>
    </div>
  </Card>
}

export default function EditEvents() {
  const {t} = useTranslation(['routes', 'pages'])
  const [isLoading, setIsLoading] = useState(true)
  const [events, setEvents] = useState([])
  const [year, setYear] = useState(momentWithLocales().year())
  const [createEvent, setCreateEvent] = useState(false)
  const toast = useToast()

  const handleError = () => {
    toast.pushUnKnown()
  }

  const closeModal = () => {
    setCreateEvent(false)
    update()
  }

  const switchYear = (nb) => {
    setIsLoading(true)
    setYear(prev => prev + nb)
  }

  const update = useCallback(() => {
    EventsAPI.adminIndex(year)
      .then(setEvents)
      .catch(handleError)
      .then(() => setIsLoading(false))
  // eslint-disable-next-line
  }, [year])

  useEffect(() => {
    update()
  }, [update])

  return <BackOffice role="calendar">
    <h1 className="mb-8">{t('edit-events.name')}</h1>
    <div className="mb-6">
      <PrimaryBtn onClick={() => setCreateEvent(true)}>{t('pages:events.new')} +</PrimaryBtn>
      <Modal isOpen={createEvent} closeModal={closeModal} className="w-full max-w-screen-xl">
        <EventForm closeModal={closeModal} isCreating/>
      </Modal>
    </div>
    <div className="mb-6 flex space-x-4 items-center">
      <div><OutlineSecondaryBtn onClick={() => switchYear(-1)}><ChevronLeftIcon className="w-5 h-5"/></OutlineSecondaryBtn></div>
      <div className="text-lg font-semibold">{year}</div>
      <div><OutlineSecondaryBtn onClick={() => switchYear(1)}><ChevronRightIcon className="w-5 h-5"/></OutlineSecondaryBtn></div>
    </div>
    <div className="flex flex-col space-y-4">
      <LoadingWrapper isLoading={isLoading}>
        {events.length > 0 ? events.map((event, id) => <AdminEventRow key={id} event={event} update={update}/> ) : <p className="text-lg font-semibold uppercase">{t('pages:events.no-events')}</p>}
      </LoadingWrapper>
    </div>
  </BackOffice>
}