import Section from "../Section";
import {useTranslation} from "react-i18next";
const cards = [
  'edit-articles',
  'edit-home',
  'edit-geobiologie',
  'edit-power-healing',
  'edit-partners',
  'edit-faq',
  'edit-terms',
];
export default function EditorSection() {
  const {t} = useTranslation('auth')
  return <Section title={t('roles.editor')} cards={cards}/>
}