import {useState, Fragment, useEffect} from "react";
import {Menu, Transition} from '@headlessui/react'
import {ChevronDownIcon} from "@heroicons/react/solid";
import {GlobeIcon} from "@heroicons/react/outline";
import Flag from "./flags/Flag";
import {useTranslation} from "react-i18next";
import PrimaryBtn from "../btns/solid/PrimaryBtn";

const locales = [
  {id: 'fr', name: 'Français'},
  {id: 'de', name: 'Deutsch'},
  {id: 'en', name: 'English'}
]

export default function LanguageSwitch(props) {
  const {i18n} = useTranslation()
  const [selected, setSelected] = useState('fr')

  useEffect(() => {
    setSelected(i18n.language || 'fr')
  }, [i18n.language])

  const switchLanguage = lang => {
    i18n.changeLanguage(lang)
      .then(() => setSelected(lang))
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button as="div">
          <PrimaryBtn className="
            uppercase flex flex-row items-center
          ">
            <GlobeIcon
              className="w-5 h-5 mr-1"
              aria-hidden="true"
            />
            {selected}
            <ChevronDownIcon
              className="w-5 h-5 ml-2 -mr-1"
              aria-hidden="true"
            />
          </PrimaryBtn>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="
            absolute left-0
            w-36 mt-1 origin-top-right
            bg-white
            divide-y divide-primary
            rounded-md border-2 border-primary
            shadow-lg
            focus:outline-none overflow-hidden"
        >
            {locales.map((lang) =>
              <Menu.Item key={lang.id}>
                {({ active }) => (
                  <button
                    className={`
                      ${active || selected === lang.id ? 'bg-primary text-white' : 'text-primary'} 
                      group flex items-center 
                      w-full px-2 py-2 
                      text-sm
                    `}
                    onClick={() => switchLanguage(lang.id)}
                  >
                    <Flag className="mr-2" lang={lang.id}/>
                    {lang.name}
                  </button>
                )}
              </Menu.Item>
            )}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}