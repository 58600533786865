import BasicBtn from "../BasicBtn";

export default function OutlineBtn({className, children, ...props}) {
  return <BasicBtn className={`
    border
    bg-white
    hover:text-white
    ${className || ''}
  `}
   {...props}
  >
    {children}
  </BasicBtn>
}