import {useAppointment} from "../../providers/AppointmentProvider";
import LoadingWrapper from "../common/placeholders/LoadingWrapper";
import Card from "../common/Card";

export default function ListWithClick ({nextStep, items, selected, onClick}) {
  const appointment = useAppointment()

  const clicked = item => {
    onClick(item)
    if (nextStep) nextStep()
  }

  return <LoadingWrapper isLoading={appointment.isLoading}>
    <div className="flex flex-col items-center space-y-4">
      <Card className="w-full max-w-md">
        <ul className="select-list w-full">
          {items?.map(item => <li
            key={item.id}
            onClick={() => clicked(item)}
            className={selected?.id === item.id ? 'selected' : ''}
          >
            {item.name}
          </li>)}
        </ul>
      </Card>
    </div>
  </LoadingWrapper>
}