export default function BasicBtn({className, children, ...props}) {
  return <button
    className={`
      rounded-md
      py-2 px-4
      text-sm
      focus:outline-none
      ${className || ''}
    `}
    {...props}
  >
    {children}
  </button>
}