import {useAppointment} from "../../providers/AppointmentProvider";
import ListWithClick from "./ListWithClick";

export default function ChooseTask ({nextStep}) {
  const appointment = useAppointment()

  const chooseTask = task => {
    appointment.setTask(task)
  }

  return <ListWithClick
    onClick={chooseTask}
    selected={appointment.task}
    items={appointment.tasks}
    nextStep={nextStep}
  />
}