export default function TransparentBtn({className, children, ...props}) {
  return <button
    className={`
      text-sm
      inset-0
      hover:text-opacity-70
      focus:outline-none
      ${className || ''}
    `}
    {...props}
  >
    {children}
  </button>
}