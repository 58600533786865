import {useState} from "react";
import axios from "axios"
import {useToast} from "../../../providers/ToastProvider";

const axiosConfig = {
  responseType: 'blob',
  timeout: 15000,
  headers: {
    'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('auth'))?.access_token
  }
}
export default function AuthenticatedLink({url, filename, children}) {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const handleAction = async () => {
    let link = document.createElement('a')

    setIsLoading(true)
    axios.get(url, axiosConfig)
      .then(response => response.data)
      .then(pdf => {
        link.href = window.URL.createObjectURL(pdf)
        link.download = filename
        link.click()
      })
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  }
  
  return <div onClick={handleAction}>
    {children instanceof Function ? children({isLoading}) : children}
  </div>
}