import {ShoppingCartIcon} from "@heroicons/react/outline";
import {useCart} from "../../providers/CartProvider";
import Modal from "../common/modals/Modal";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import PrimaryBtn from "../common/btns/solid/PrimaryBtn";
import UnstyledLink from "../common/links/UnstyledLink";
import Articles from "./Articles";

export default function Cart() {
  const [openModal, setOpenModal] = useState(false)
  const cart = useCart()
  const {t} = useTranslation(['translation', 'routes'])

  return <div className={`mr-3 ${cart.nbArticles === 0 ? 'hidden' : ''}`}>
    <div className="relative w-8 h-8 cursor-pointer" onClick={() => setOpenModal(true)}>
      <ShoppingCartIcon className="w-full h-full"/>
      <div className="absolute h-3 -top-3 -right-3 text-sm flex justify-center items-center py-3 px-1 bg-secondary text-white rounded-full">
        {cart.nbArticles}
      </div>
    </div>
    <Modal
      isOpen={openModal}
      closeModal={() => setOpenModal(false)}
      className="w-full max-w-xl"
    >
      <Articles articles={cart.articles} />
      <div className="mt-2 px-4 border-b-2 pb-2">
        {t('total')}: {cart.total}
      </div>
      <div className="mt-4">
        <UnstyledLink to={t('routes:checkout.link')}>
          <PrimaryBtn onClick={() => setOpenModal(false)}>{t('routes:checkout.name')}</PrimaryBtn>
        </UnstyledLink>
      </div>
    </Modal>
  </div>
}