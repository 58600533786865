import React, {useCallback, useContext, useEffect, useState} from "react";
import {getTasksByCategory} from "../api/tasks";
import {useToast} from "./ToastProvider";
import {getAvailableAddresses} from "../api/appointment";
import {useTranslation} from "react-i18next";

export const AppointmentContext = React.createContext(undefined)
export const useAppointment = () => useContext(AppointmentContext)

export default function AppointmentProvider({children}) {
  const {i18n} = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState(null)
  const [tasks, setTasks] = useState([])
  const [task, setTask] = useState(null)
  const [practitioners, setPractitioners] = useState([])
  const [practitioner, setPractitioner] = useState(null)
  const [locations, setLocations] = useState([])
  const [location, setLocation] = useState(null)
  const [selectedDateTime, setSelectedDateTime] = useState(null)
  const toast = useToast()

  const getFormData = () => ({
    task_id: task?.id,
    practitioner_id: practitioner?.id,
    address_id: location?.id,
    start: selectedDateTime
  })

  const AppointmentContextd = useCallback(() => {
    return {
      isLoading,
      categories,
      category,
      setCategory,
      tasks,
      task,
      setTask,
      practitioners,
      practitioner,
      setPractitioner,
      locations,
      location,
      setLocation,
      setSelectedDateTime,
      selectedDateTime,
      getFormData
    }
  // eslint-disable-next-line
  }, [
    isLoading,
    categories,
    category,
    setCategory,
    tasks,
    task,
    setTask,
    practitioners,
    practitioner,
    setPractitioner,
    locations,
    location,
    setLocation,
    setSelectedDateTime,
    selectedDateTime
  ])

  useEffect(() => {
    if (category) {
      setIsLoading(true)
      setTasks(category.tasks || [])
      setIsLoading(false)
    }
  }, [category])

  useEffect(() => {
    if (task) {
      setIsLoading(true)
      setPractitioners(task.practitioners.map(practitioner => {
        return {
          ...practitioner,
          name: practitioner.public_name
        }
      }) || [])
      setIsLoading(false)
    }
  }, [task])

  useEffect(() => {
    if (practitioner) {
      setIsLoading(true)
      getAvailableAddresses(task.id, practitioner.id)
        .then(response => setLocations(response || []))
        .catch(() => toast.pushUnKnown())
        .then(() => setIsLoading(false))
    }
  // eslint-disable-next-line
  }, [practitioner])

  useEffect(() => {
    setIsLoading(true)
    getTasksByCategory()
      .then(response => setCategories(response || []))
      .catch(() => toast.pushUnKnown())
      .then(() => setIsLoading(false))
  // eslint-disable-next-line
  }, [i18n.language])

  return <AppointmentContext.Provider value={AppointmentContextd()}>
    {children}
  </AppointmentContext.Provider>
}