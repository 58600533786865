import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

const example = {id: -1, name: ''}

export default function Select({defaultValue , items = [example], ...props}) {
  const [selected, setSelected] = useState(defaultValue || items[0])

  const handleSelect = item => {
    setSelected(item)
    if ("onChange" in props) {
      props.onChange(item)
    }
  }

  return (
    <Listbox value={selected} onChange={handleSelect} className="w-full sm:w-1/2 mb-4">
      <div className="mt-1">
        <Listbox.Button className="
          relative w-full py-2 pl-3 pr-10
          text-left
          rounded-lg shadow-md
          cursor-pointer
          focus:outline-none
          text-sm
        ">
          <span className="block truncate">{selected.name}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="
            absolute w-5/6 sm:w-1/2 py-1 mt-1
            overflow-auto
            text-sm
            bg-white
            rounded-md shadow-lg max-h-60 z-30">
            {items.map((item) => (
              <Listbox.Option
                key={item.id}
                className={({ active }) =>
                  `${active ? 'bg-blue-100' : ''}
                        cursor-pointer select-none relative py-2 pl-10 pr-4`
                }
                value={item}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`${
                        selected ? 'font-bold' : ''
                      } block truncate`}
                    >
                      {item.name}
                    </span>
                    {selected ? (
                      <span
                        className={`absolute inset-y-0 left-0 flex items-center pl-3`}
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}
