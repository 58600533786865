import {Draggable} from "react-beautiful-dnd";
import {PencilIcon, SelectorIcon, XCircleIcon} from "@heroicons/react/outline";
import TransparentBtn from "../common/btns/TransparentBtn";
import Modal from "../common/modals/Modal";
import DeleteDialogModal from "../common/modals/DeleteDialogModal";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import * as TaskAPI from "../../api/tasks";
import TaskForm from "../../forms/TaskForm";

export default function Task({categories, task, index, refresh}) {
  const [deleteModal, setDeleteModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const {t} = useTranslation('pages')

  const deleteTask = () => {
    TaskAPI.destroy(task.id)
      .then(refresh)
  }

  const closeModal = () => {
    setEditModal(false)
    refresh()
  }

  return <Draggable draggableId={`task-${task.id}`} index={index}>
    {provided => (
      <div
        className="flex flex-row items-center justify-start space-x-2 pt-2"
        {...provided.draggableProps}
        ref={provided.innerRef}
      >
        <div {...provided.dragHandleProps}><SelectorIcon className="w-5 h-5 text-primary"/></div>
        <div className="px-1 text-sm rounded-md text-white text-center" style={{backgroundColor: task.color, color: task.text_color}}>abc</div>
        <div>{task.name}</div>
        <div className="flex-grow flex flex-row justify-end sm:justify-start">
          <TransparentBtn onClick={() => setEditModal(true)}><PencilIcon className="w-4 h-4"/></TransparentBtn>
          <Modal
            title={t('task-categories.edit-task')}
            isOpen={editModal}
            closeModal={closeModal}
            className="w-full max-w-4xl"
          >
            <TaskForm categories={categories} task={task} closeModal={closeModal}/>
          </Modal>
          <TransparentBtn className="flex flex-row justify-end text-red-600" onClick={() => setDeleteModal(true)}><XCircleIcon className="h-4 w-4"/></TransparentBtn>
          <DeleteDialogModal
            isOpen={deleteModal}
            closeModal={() => setDeleteModal(false)}
            title={t('task-categories.delete-task.title', {name: task.name})}
            description={t('task-categories.delete-task.description')}
            action={deleteTask}
          />
        </div>
      </div>
    )}
  </Draggable>
}