import {CreditCardIcon} from "@heroicons/react/outline";
import {Elements} from "@stripe/react-stripe-js";
import {useTranslation} from "react-i18next";
import {loadStripe} from "@stripe/stripe-js";
import StripeDefaultForm from "../../forms/StripeDefaultForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function Stripe({price, actionPromise, callBack, successMessage}) {
  const {i18n, t} = useTranslation('pages')
  return <div className="w-full max-w-lg mx-auto rounded-lg bg-white shadow-lg p-5 mt-10 focus:ring-secondary">
    <div className="w-full pt-1 pb-5">
      <div
        className="text-white bg-primary overflow-hidden rounded-full w-20 h-20 -mt-16 mx-auto shadow-lg flex justify-center items-center p-4">
        <CreditCardIcon className="w-full h-full"/>
      </div>
    </div>
    <div className="mb-10">
      <h1 className="text-center font-bold text-xl uppercase">{t('checkout.payment.secure-payment')}</h1>
    </div>
    <Elements stripe={stripePromise} options={{locale: i18n.language}}>
      <StripeDefaultForm price={price} actionPromise={actionPromise} callBack={callBack} successMessage={successMessage} />
    </Elements>
  </div>
}