import api from "./api";
import {lang} from "../languages";
import resources from "./resources";

export function show(id) {
  return api().get(`/${lang()}/${resources.carts}/${id}`)
}

export function store(data) {
  return api().post(`/${lang()}/${resources.carts}`, data)
}

export function update(id, data) {
  return api().put(`/${lang()}/${resources.carts}/${id}`, data)
}

export function destroy(id) {
  return api().delete(`/${lang()}/${resources.carts}/${id}`)
}

export function addItem(cartId, itemId, data) {
  return api().post(`/${lang()}/${resources.carts}/${cartId}/${resources.articles}/${itemId}`, data)
}

export function removeItem(cartId, itemId, data) {
  return api().delete(`/${lang()}/${resources.carts}/${cartId}/${resources.articles}/${itemId}`, {
    data: data
  })
}

export function save(data) {
  return api().post(`/${lang()}/${resources.order}`, data)
}